import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      marginTop: 25
    },
    marginTop: 50,
  },
  subtitle: {
    // marginBottom: 10
  },
  subtitleSecondary: {
    color: "#fff",
  },
  blurbAvatarContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    marginTop: 10,
  },
  contextWrapper: {
    position: "relative",
  },
  contextContainer: {
    width: "100%",
    borderRadius: 15,
    backgroundColor: "#283028",
    display: "grid",
    gridColumnGap: 5,
    gridRowGap: 10,
    padding: "15px",
    "@media (max-width:960px)": {
      display: "block",
    },
  },
  grid1: {
    gridArea: "1 / 1 / 2 / 2",
    marginBottom: 10,
    "@media (max-width:960px)": {
      minHeight: "auto",
      marginBottom: 20,
    },
  },
  grid2: {
    gridArea: "1 / 2 / 2 / 3",
    marginBottom: 10,
    "@media (max-width:960px)": {
      marginBottom: 20,
    },
  },
  grid3: {
    position: "relative",
    gridArea: "1 / 3 / 2 / 4",
    marginBottom: 10,
    "@media (max-width:960px)": {
      marginBottom: 20,
    },
  },
  grid4: {
    gridArea: " 2 / 1 / 3 / 2",
    "@media (max-width:960px)": {
      marginBottom: 20,
    },
  },
  grid5: {
    gridArea: "2 / 2 / 3 / 4",
  },
  mobileFlex: {
    "@media (max-width:960px)": {
      display: "flex",
    },
  },
  contextTitle: {
    fontSize: 17,
    fontWeight: "bold",
    letterSpacing: "-0.15px",
    lineHeight: 1,
    marginBottom: 7,
  },
  deliverablesTagsContainer: {
    marginTop: 20,
  },
  expansion: {
    marginTop: 40,
  },
  profileContainer: {
    marginTop: 20,
  },
  profileHeader: {},
  skills: {
    marginTop: 20,
  },
  seniorityContainer: {
    marginTop: 20,
  },
  seniority: {
    alignItems: "center",
    backgroundColor: "#283028",
    borderRadius: 15,
    padding: 20,
  },
  tagsList: {
    width: "100%",
    maxWidth: "100%",
    display: "inline-flex",
    flexWrap: "wrap",
  },
  tag: {
    alignItems: "center",
    backgroundColor: "red",
    borderRadius: 30,
    height: 37,
  },
  typoColorMain: {
    color: theme.palette.primary.main,
  },
  underTypo: {
    marginBottom: 30,
  },
}));
