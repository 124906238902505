import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 56,
    height: 56,
    border: "solid 1px #565e56",
    display: "flex",
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    "input:hover ~ &": {
      border: "solid 1px #fff",
      borderColor: theme.palette.secondary.light,
    },
    borderRadius: 50,
  },
  checkedIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:before": {
      border: "transparent",
      display: "block",
      content: '""',
      backgroundColor: theme.palette.primary.main,
      width: 30,
      height: 30,
      borderRadius: "50%",
    },
  },
  small: {},
  rounded: {
    borderRadius: 50,
    width: 28,
    height: 28,
    display: "flex",
    "input:hover ~ &": {
      borderColor: theme.palette.secondary.black,
    },
  },
}));
