import makeStyles from "@material-ui/core/styles/makeStyles";

import smallIcon from "../../../assets/icons/checkbox-checked-black.svg";
import icon from "../../../assets/icons/checkbox-checked.svg";

export default makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 8,
    width: 56,
    height: 56,
    border: `solid 1px ${theme.palette.secondary.medium}`,
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    "input:hover ~ &": {
      border: "solid 1px #fff",
    },
  },
  checkedIcon: {
    overflow: "hidden",
    "&:before": {
      border: "transparent",
      display: "block",
      width: 56,
      height: 56,
      backgroundImage: `url(${icon})`,
      content: '""',
    },
  },
  small: {
    borderRadius: 4,
    width: 28,
    height: 28,
    "&:before": {
      backgroundImage: `url(${icon})`,
      backgroundSize: "60%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "-15% -15%",
    },
    "input:hover ~ &": {
      borderColor: theme.palette.secondary.black,
    },
  },
  rounded: {
    borderRadius: 50,
    width: 28,
    height: 28,
    "&:before": {
      backgroundImage: `url(${smallIcon})`,
      backgroundSize: "60%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "-15% -15%",
    },
    "input:hover ~ &": {
      borderColor: theme.palette.secondary.black,
    },
    "@media (max-width:960px)": {
      width: 25,
      height: 25,
      left: 50,
    },
  },
  error: {
    border: `1px solid ${theme.palette.primary.danger}`,
  },
}));
