import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.secondary.black,
    boxShadow: "none",
    "@media (max-width:960px)": {
      width: "100%",
    },
  },
  shadow: {
    boxShadow:
      "0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)",
  },
  toolbar: {
    height: theme.navbar.height,
    "@media (max-width:960px)": {
      width: "100%",
      paddingLeft: 20,
    },
  },
  hideTab: {
    "@media (max-width:960px)": {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  div: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignContent: "center",
    alignItems: "center",
  },
  login: {
    width: "auto",
    position: "absolute",
    right: 0,
  },
  signup: {},
  home: {
    flexWrap: "nowrap",
    overflow: "auto",
  },
  tabs: {
    paddingLeft: 30,
    height: theme.navbar.height,
  },
  tabRoot: {
    height: theme.navbar.height,
    color: theme.palette.secondary.main,
  },
  brief: {
    marginLeft: 20,
    textDecoration: "none",
    cursor: "pointer",
  },
  briefButtonGroup: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    "&:hover p": {
      color: "#e4e4e4",
      textDecoration: "none",
    },
  },
  briefToDashboardImage: {
    transform: "rotate(180deg)",
  },
  briefToDashboardText: {
    fontSize: 14,
    fontFamily: "Basier Medium",
    margin: "0 1rem",
    textDecoration: "none",
  },
  password: {
    width: 525,
  },
  root: {
    top: 0,
    width: "100%",
  },
  snackbar: {
    display: "flex",
    marginTop: 0,
    paddingRight: 30,
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: 70,
  },
  typo: {
    margin: "auto",
    color: "black",
  },
  logo: {
    display: "flex",
    outlineStyle: "none",
    boxSizing: "content-box",
  },
  logoImage: {
    "@media (max-width:960px)": {
      width: 90,
      paddingRight: 20,
    },
  },
  iconButton: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  navLink: {
    textTransform: "none",
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.secondary.black,
    paddingLeft: 25,
    paddingRight: 25,
    textDecoration: "none",
  },
  newbrief: {
    position: "absolute",
    left: "35%",
  },
  name: {
    padding: "10px 0 10px 5px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    alignContent: "center",
  },
}));
