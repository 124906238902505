import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: "15px",
    padding: 20,
  },
  container: {
    marginBottom: 20,
  },
  columnContainer: {},
  cardTitle: {
    marginBottom: 20,
  },
  textfield: {
    maxWidth: "100%",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  textFieldBIC: {
    maxWidth: 140,
  },
  switch: {
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 20,
  },
  tva: {},
  cgu: {
    marginTop: 10,
  },
  saveButton: {},
  city: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: "4%",
  },
  zipCode: {
    marginTop: 15,
    marginBottom: 15,
  },
  phoneMargin: {
    marginTop: 10,
  },
  charteContainer: {
    marginTop: 25,
  },
  chart: {
    width: 83,
    height: 64,
    margin: 30,
    "&:hover": {
      cursor: "pointer",
    },
    "&:active": {
      border: "1px solid transparent",
    },
  },
  link: {
    fontsize: theme.typography.h4.fontSize,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.bright,
    },
    "&:visited": {
      color: theme.palette.primary.bright,
    },
  },
  alert: {
    backgroundColor: "#ecf805",
    color: "#162217",
    padding: 10,
    marginBottom: 20,
    borderRadius: 10,
  },
  alertTypography: {
    color: "#162217",
  },
}));
