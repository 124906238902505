import { fade } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { theme } from "../../utils/configureMaterialTheme";

export default makeStyles((theme) => ({
  searchbar: {
    "& .react-select__option--is-focused": {
      background: theme.palette.primary.main,
    },
  },
  optionValue: {
    fontFamily: "Basier Medium",
  },
  img: {
    height: 40,
  },
  searchImg: {
    height: 25,
    margin: "0 .5rem 0 10px",
  },
  value: {
    color: theme.palette.secondary.medium,
  },
  highlight: {
    background: "none",
    fontWeight: 600,
    color: theme.palette.secondary.black,
  },
  createOption: {
    color: theme.palette.secondary.medium,
    fontFamily: "Basier Regular",
  },
}));

const reactSelectStyles = {
  container: (styles) => ({
    ...styles,
    height: 64,
    marginTop: 5,
  }),
  control: (base, state) => ({
    ...base,
    borderRadius: 15,
    height: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "text",
    boxShadow: "none",
    border: "none",
    color: theme.palette.secondary.medium,
    fontSize: 17,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: 0,
    marginRight: 22,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: fade(theme.palette.secondary.medium, 0.4),
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    cursor: "pointer",
  }),
  groupHeading: (styles) => ({
    ...styles,
    fontSize: 17,
    color: theme.palette.secondary.black,
    textTransform: "capitalize",
    marginLeft: 0,
    paddingLeft: 28,
    fontFamily: "Basier Medium",
    position: "sticky",
    top: 0,
    background: "#fff",
    borderBottom: "1px solid rgba(197,202,179,0.2)",
  }),
  valueContainer: (styles) => ({
    ...styles,
    borderRadius: 15,
    fontFamily: "Basier Medium",
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: 15,
    width: "100%",
    overflowStyle: "none", // IE scrollbar
    scrollbarWidth: "none", // Firefox scrollbar
    scrollbarColor: "transparent transparent", // Firefox scrollbar
    "& ::-webkit-scrollbar": {
      width: 0,
    },
  }),
  menuList: (styles) => ({
    ...styles,
    borderRadius: 15,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  option: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    background: "transparent",
    paddingLeft: 40,
    fontSize: 14,
    minHeight: 50,
    cursor: "pointer",
    transition: ".1s",
    fontFamily: "Basier Regular",
    color: theme.palette.secondary.medium,
    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${fade(theme.palette.secondary.light, 0.2)}`,
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: 14,
    paddingLeft: 40,
    textAlign: "left",
  }),
};

export { reactSelectStyles };
