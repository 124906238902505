
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

import styles from "./styles";

const CircleImage = ({ theme, src, alt, icon, ...props }) => {
  const classes = styles();

  return (
    <Avatar
      alt={alt}
      src={src}
      className={`${classes.icon} ${classes[theme]}`}
      {...props}
    >
      <Typography variant="h1">{icon}</Typography>
    </Avatar>
  );
};

CircleImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  /**
   * Defines the image size
   */
  theme: PropTypes.oneOf(["avatarSmall", "avatarLarge", "mission"]),
};

CircleImage.defaultProps = {
  src: null,
  theme: "avatarSmall",
};
export default CircleImage;
