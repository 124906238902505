import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    border: `1px solid ${theme.palette.secondary.medium}`,
    color: theme.palette.secondary.main,
    "&::before, &::after": {
      display: "none",
    },
    marginTop: 5,
    "&:focus-within": {
      borderColor: theme.palette.secondary.main,
    },
    "& input": {
      height: 64,
      padding: "0 10px",
    },
  },
  containerLandscape: {
    flexDirection: "column",
  },
  paper: {
    "&.MuiPaper-rounded": {
      borderRadius: 16,
      margin: ".8rem 30px",
      "&.MuiPickersDay-day": {
        color: "blue",
      },
    },
    "& .MuiPickersYear-yearSelected": {
      fontSize: "2rem",
    },
    "& .MuiPickersMonth-monthSelected": {
      fontSize: "2rem",
    },
    "& .MuiPickersYear-root": {
      color: theme.palette.secondary.dark,
    },
    "& .MuiPickersMonth-root": {
      color: theme.palette.secondary.dark,
    },
  },
  error: {
    borderColor: theme.palette.primary.danger,
  },
}));
