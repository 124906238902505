import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const Overlay = withStyles(({ palette }) => ({
  paper: {
    border: "1px solid #d3d4d5",
    background: palette.secondary.dark,
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
));

export default Overlay;
