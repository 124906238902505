import { fade } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

import LoginPhoto from "../assets/pictures/login_freelance.jpg";

import { basierRegular } from "./configureMaterialTheme";

export default makeStyles((theme) => ({
  root: {
    "& > *": {
      padding: theme.spacing(1),
      width: "25ch",
    },
  },
  fullHeight: {
    height: `calc(100vh - ${theme.navbar.height}px)`,
  },
  loginWrapper: {
    minHeight: "100vh",
  },
  connectionDiv: {
    backgroundImage: `url(${LoginPhoto})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    "@media (max-width:960px)": {
      backgroundImage: "",
      textAlign: "center",
    },
  },
  firstLoginDiv: {
    backgroundColor: theme.palette.secondary.black,
    paddingTop: theme.navbar.height,
  },
  Connection: {
    borderRadius: 5,
    width: 450,
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  titleConnection: {
    color: theme.palette.secondary.main,
    fontStyle: "italic",
    fontSize: 60,
    marginBottom: 20,
  },
  titleFormConnection: {
    fontSize: 30,
    color: theme.palette.secondary.main,
    margin: 30,
    fontWeight: "bold",
  },
  sidebarDiv: {
    backgroundColor: theme.palette.secondary.sidebarGreen,
    "@media (min-width:960px)": {
      minHeight: `calc(100vh - ${theme.navbar.height}px)`,
    },
  },
  sidebarWrapper: {},
  navLink: {
    color: `${theme.palette.primary.main}`,
    fontFamily: basierRegular,
  },
  typo: {
    fontSize: 17,
    height: 30,
  },
  titleSignIn: {
    paddingBottom: 20,
  },
  divider: {
    marginTop: 40,
    marginBottom: 40,
    backgroundColor: `${theme.palette.secondary.medium}`,
    "@media (max-width:960px)": {
      marginTop: 40,
      marginBottom: 50,
      backgroundColor: `${theme.palette.secondary.medium}`,
    },
  },
  container: {
    backgroundColor: "transparent",
    paddingTop: theme.navbar.height,
  },
  pannel: {
    paddingTop: 50,
    paddingBottom: 50,
    position: "sticky",
    top: theme.navbar.height,
  },
  pannelLayout: {
    paddingTop: "10rem",
    // paddingBottom: '10rem'
  },
  midWidth: {
    width: "50%",
  },
  email: {
    color: theme.palette.primary.main,
  },
  sloganTitle: {
    color: theme.palette.primary.main,
    fontFamily: "Basier Regular",
    fontSize: "24px",
  },
  slogan: {
    marginTop: 20,
    color: theme.palette.secondary.main,
    fontFamily: "Basier Regular",
    fontSize: "60px",
    lineHeight: 1,
    "@media (max-width:960px)": {
      fontSize: "40px",
      display: "inline-block",
    },
  },
  subSlogan: {
    marginTop: 30,
    fontSize: 17,
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  sloganGrid: {},
  imgLinkedIn: {
    width: 268,
    height: 50,
    cursor: "pointer",
  },
  appbar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    position: "absolute",
    right: 0,
    left: 0,
  },
  toolbar: {
    height: theme.navbar.height,
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    outlineStyle: "none",
  },
  loginPageTitle: {
    fontSize: 17,
  },
  loginGrid: {},
  signupNavLink: {
    "@media (max-width:960px)": {
      display: "block",
    },
  },
  acracy: {
    fontSize: 24,
    fontFamily: "Basier Regular",
  },
  briefExemple: {
    marginTop: 20,
    height: 370,
    width: 320,
    borderRadius: "15px",
    backgroundColor: "#ffffff",
  },
  briefExempleTopContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: "15px 15px 0 0",
    height: 202,
    backgroundColor: theme.palette.primary.main,
  },
  briefExempleStatus: {
    color: theme.palette.secondary.black,
    fontSize: 10,
    fontFamily: "Basier Medium",
  },
  briefExempleTitle: {
    fontSize: 22,
    fontFamily: "Basier Medium",
    color: "#000",
  },
  briefExempleSubTitle: {
    fontSize: 14,
    fontFamily: "Basier Medium",
    color: "#000",
  },
  briefExempleLittleContainer: {
    paddingLeft: 15,
    paddingTop: 15,
  },
  briefExempleText1: {
    color: "#575e57",
    width: 130,
    fontFamily: "Basier Regular",
    fontSize: 15,
  },
  briefExempleText2: {
    color: "#575e57",
    width: 130,
    height: 34,
    fontFamily: "Basier Medium",
    fontSize: 15,
  },
  help: {
    borderRadius: "15px",
    backgroundColor: "#151d15",
    padding: 20,
  },
  closeIcon: {
    cursor: "pointer",
  },
  dialogText: {
    marginTop: 20,
  },
  invoicesRoot: {
    paddingTop: theme.navbar.height + 40,
  },
  invoicesContainer: {
    marginBottom: 40,
    "@media (max-width:960px)": {
      overflowX: "auto",
      flexWrap: "unset !important",
      maxWidth: "90vw",
      overflowStyle: "none", // IE scrollbar
      scrollbarWidth: "thin", // Firefox scrollbar
      scrollbarColor: `${theme.palette.primary.main} ${fade(
        theme.palette.secondary.dark,
        0.6
      )}`, // Firefox scrollbar
    },
  },
  noInvoiceContainer: {
    margin: "164px 13vw 0",
    "@media (max-width:960px)": {
      margin: "164px 2rem 0",
    },
  },
  noInvoicesTitle: {
    color: theme.palette.secondary.main,
    fontFamily: "Basier Regular",
    fontSize: 34,
    letterSpacing: -1.26,
    marginBottom: 20,
    lineHeight: "2.3rem",
  },
  noInvoicesBody: {
    fontSize: 17,
    fontFamily: "Basier Regular",
    lineHeight: 1.41,
    letterSpacing: -0.45,
    marginBottom: 10,
    "@media (min-width:500px)": {
      width: 460,
    },
  },
  noInvoicesLink: {
    display: "inline-block",
    fontSize: 17,
    lineHeight: 1.41,
    letterSpacing: -0.45,
    fontFamily: "Basier Regular",
    textDecoration: "underline",
    marginBottom: 24,
  },
  noInvoicesNavLink: {
    display: "inline-block",
    textDecoration: "none",
  },
}));
