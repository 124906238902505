


import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import styles from "./styles";

const CustomExpansionPanel = ({
  children,
  isTag,
  panelTitle,
  id,
  expansionPanelOpen = false,
  isInvoice,
  noBorder,
  handlePanelOpen,
  ...props
}) => {
  const classes = styles();

  // Handle panels expansion individually

  const [open, setOpen] = useState(expansionPanelOpen);

  const handleChange = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(expansionPanelOpen);
  }, [expansionPanelOpen]);

  const OpenIcon = isTag ? (
    <AddIcon className={classes.addIcon} />
  ) : (
    <ExpandMoreIcon className={classes.arrowIcon} />
  );
  const CloseIcon = isTag ? (
    <RemoveIcon className={classes.addIcon} />
  ) : (
    <ExpandLess className={classes.arrowIcon} />
  );
  return (
    <div className={clsx(isInvoice && classes.invoicesRoot, classes.root)}>
      <ExpansionPanel
        expanded={open}
        onChange={handlePanelOpen || handleChange}
        TransitionProps={{ unmountOnExit: true }}
        className={!noBorder ? classes.panel : classes.panelWithoutBorder}
        {...props}
      >
        <ExpansionPanelSummary
          className={isInvoice ? classes.invoiceSummary : classes.header}
          expandIcon={open ? CloseIcon : OpenIcon}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            className={isInvoice ? classes.invoiceTitle : null}
            variant="h4"
          >
            {panelTitle}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          className={isTag ? classes.detailsContainer : classes.revealText}
        >
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

CustomExpansionPanel.propTypes = {
  isTag: PropTypes.bool,
  panelTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

CustomExpansionPanel.defaultProps = {
  isTag: false,
  panelTitle: null,
};

export default CustomExpansionPanel;
