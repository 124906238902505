import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.secondary.medium}`,
    height: 64,
    overflow: "hidden",
    borderRadius: "10px !important",
    backgroundColor: "rgba(86, 94, 86, 0.1)",
    transition: theme.transitions.create(["border-color"]),
    caretColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: 14,
    boxSizing: "border-box",
    "&:focused": {
      backgroundColor: "inherit",
      borderColor: theme.palette.secondary.main,
    },
    "& .MuiFilledInput-input": {
      padding: "0 15px",
      color: theme.palette.secondary.main,
    },
  },
  input: {
    alignItems: "center",
    fontFamily: "Basier Medium",
    "& option": {
      color: theme.palette.secondary.dark,
    },
  },
}));
