import { useGetServiceProviderQuery } from "../../generates";
import { client } from "../../libs/graphqlRequest";

export default function useServiceProvider(id) {
  const options = {
    staleTime: 60 * 1000,
    enabled: !!id,
  };

  return useGetServiceProviderQuery(
    client,
    {},
    {
      ...options,
      select: (data) => data?.getServiceProvider,
    }
  );
}
