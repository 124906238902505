import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  reasonInfo: {
    fontSize: 17,
    letterSpacing: "-0.45px",
    lineHeight: 1.41,
    margin: "-20px 0 20px",
    opacity: 0.9,
  },
}));
