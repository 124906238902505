import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import React from "react";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider, setLogger } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";

import { SessionProvider } from "../hooks/useSession";

import { theme } from "./configureMaterialTheme";

const queryClient = new QueryClient();

// Sentry logger
setLogger({
  log: (message) => {
    console.log(message);
    Sentry.captureMessage(message);
  },
  warn: (message) => {
    console.warn(message);
    Sentry.captureMessage(message);
  },
  error: (error) => {
    console.error(error);
    Sentry.captureException(error);
  },
});

const ProviderWrapper = (children) => (
  <QueryClientProvider client={queryClient}>
    <SessionProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Helmet
            defaultTitle="acracy freelance"
            titleTemplate="%s | acracy freelance"
          />
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 6000,
              style: {
                margin: "6px",
              },
            }}
          />
          <CssBaseline />
          {children}
        </BrowserRouter>
        <ReactQueryDevtools />
      </ThemeProvider>
    </SessionProvider>
  </QueryClientProvider>
);

export default ProviderWrapper;
