import makeStyles from "@material-ui/core/styles/makeStyles";

import icon from "../../../../assets/icons/checkbox-checked.svg";

export default makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  tonMetier: {},
  nextButton: {},
  labelMobile: {},
  howDidYouKnowLabelMobile: {},
  seniorityLabel: {},
  myExpertiseSignUp: {},
  signupAccountCreation: {},
  signupPersonnalInfos: {},
  firstNameForm1: {},
  lastNameForm1: {},
  emailForm1: {},
  phoneCodeForm1: {},
  phoneNumberForm1: {},
  sidebarDiv: {
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.sidebarGreen,
    minHeight: `calc(100vh - ${theme.navbar.height}px)`,
  },
  formGridItem: {
    paddingTop: 50,
  },
  checked: {
    borderRadius: 8,
    width: 56,
    height: 56,
    border: "solid 1px #565e56",
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    "input:hover ~ &": {
      border: "solid 1px #fff",
    },
  },
  checkedIcon: {
    "&:before": {
      border: "transparent",
      display: "block",
      width: 56,
      height: 56,
      backgroundImage: `url(${icon})`,
      content: '""',
    },
  },
  marginTop: {
    marginTop: 15,
  },
  phoneNumberLabel: {},
  stepper: {
    backgroundColor: theme.palette.secondary.black,
  },
  step: {
    width: 200,
    "&:not(:first-child)": {
      right: 138,
      marginLeft: "2rem",
    },
    "@media (max-width:960px)": {},
  },
  stepButton: {
    padding: "0 8px",
  },
  stepContent: {
    paddingTop: "40px",
  },
  signupRows: {},
  icon: {
    background: theme.palette.secondary.medium,
    width: 49,
    height: 49,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary.black,
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "transparent",
    backgroundClip: "content-box",
    fontFamily: "Basier Bold",
    "@media (max-width:960px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  active: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.medium,
    borderColor: theme.palette.secondary.main,
    backgroundClip: "content-box",
    padding: ".3rem",
  },
  inactiveLabel: {
    color: theme.palette.secondary.medium,
  },
  checkBoxText: {
    marginLeft: 10,
    fontSize: 15,
    lineHeight: "1.5rem",
  },
  tjmText: {
    fontSize: 14,
    fontFamily: "Basier Regular",
    color: theme.palette.primary.main,
  },
  navLink: {
    cursor: "pointer",
    fontsize: 17,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.bright,
    },
  },
  fieldRows: {
    paddingTop: theme.spacing(3),
  },
}));
