import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router";

import { client, defaultHeaders } from "../../libs/graphqlRequest";
import { ROUTE_FREELANCE_CREATE_ACCOUNT } from "../../utils/routeConstants";
import useOauthCallbackMutation from "../useOauthCallbackMutation";
import useServiceProvider from "../useServiceProvider";
import useServiceProviderMutation from "../useServiceProviderMutation";
import useSessionLinkedinMutation from "../useSessionLinkedinMutation";

export const SessionContext = createContext();

const useSession = (props) => {
  const { redirectTo = "", privateRoute = true } = props || {};

  const context = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();

  if (typeof window !== "undefined" && !context?.isLoading) {
    if (!context?.userDynamo && !context?.user && redirectTo && privateRoute) {
      const searchParams = new URLSearchParams({
        redirectTo: window.location.pathname,
      });

      history.push(`${redirectTo}?${searchParams}`, null, { shallow: true });
    }

    if (context?.userDynamo && context?.user && redirectTo && !privateRoute) {
      const params = new URLSearchParams(location.search);

      const newRedirectTo = params.get("redirectTo") || redirectTo;

      history.push(newRedirectTo, null, { shallow: true });
    }

    if (
      context?.userDynamo &&
      context?.user &&
      !context?.user?.status &&
      location.pathname !== ROUTE_FREELANCE_CREATE_ACCOUNT
    ) {
      history.push(ROUTE_FREELANCE_CREATE_ACCOUNT, null, { shallow: true });
    }
  }

  return context;
};

const SessionReducer = (state, action) => {
  const { type, payload } = action;
  const { userDynamo, user } = state;
  switch (type) {
    case "FETCH_USER_DATA_INIT":
      return {
        ...state,
        user: null,
        userDynamo: null,
        isLoading: true,
        isError: false,
      };
    case "FETCH_USER_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        userDynamo: { ...userDynamo, ...payload },
      };
    case "FETCH_CURRENT_USER_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        user: { ...userDynamo, ...user, ...payload },
      };
    case "FETCH_USER_DATA_FAILURE":
      return { ...state, isLoading: false, isError: true };
    case "RESET_USER_DATA":
      return { ...state, user: null, userDynamo: null };
    default:
      throw new Error();
  }
};

const initialState = {
  isLoading: true,
  isError: false,
  user: null,
  userDynamo: null,
  dispatch: () => undefined,
};

export const SessionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SessionReducer, initialState);
  const oauthCallbackMutation = useOauthCallbackMutation();
  const queryClient = useQueryClient();
  const sessionLinkedinQuery = useSessionLinkedinMutation({
    enabled: false,
    onError: () => {
      removeSession();
      setError();
    },
    retry: false,
  });
  const updateServiceProviderProfileMutation = useServiceProviderMutation();

  const serviceProviderQuery = useServiceProvider(
    state?.userDynamo?.serviceProviderId
  );

  const isFirstLoading = useRef(true);

  useEffect(() => {
    const accessToken = Cookies.get("linkedInId") || null;

    if (accessToken) {
      client.setHeader("Authorization", `Bearer ${accessToken}`);
      sessionLinkedinQuery.refetch();
    } else {
      setError();
    }
  }, []);

  useEffect(() => {
    if (
      isFirstLoading.current &&
      sessionLinkedinQuery?.data &&
      (sessionLinkedinQuery?.data?.isConnected ||
        typeof sessionLinkedinQuery?.data?.isConnected === "undefined")
    ) {
      setSession(sessionLinkedinQuery?.data);
      isFirstLoading.current = false;
    }

    if (
      typeof sessionLinkedinQuery?.data?.isConnected !== "undefined" &&
      !sessionLinkedinQuery?.data?.isConnected
    ) {
      setError();
      removeSession();
    }
  }, [sessionLinkedinQuery, isFirstLoading]);

  const setCurrentUser = useCallback(
    (user) => {
      if (window?.$crisp) {
        window.$crisp.push([
          "set",
          "user:nickname",
          [`${user?.firstName} ${user?.lastName}`],
        ]);

        window.$crisp.push(["set", "user:email", user?.email]);
        window.$crisp.push(["set", "session:segments", [["help_freelance"]]]);

        const id = user?.id || user?.serviceProviderId;
        window.$crisp.push([
          "set",
          "session:data",
          [[["serviceProviderId", id]]],
        ]);
      }

      return dispatch({
        type: "FETCH_CURRENT_USER_DATA_SUCCESS",
        payload: user,
      });
    },
    [dispatch]
  );

  const setSession = useCallback(
    (user) => {
      dispatch({ type: "FETCH_USER_DATA_SUCCESS", payload: user });
      // serviceProviderQuery.refetch(); // Normally you don't need this
    },
    [dispatch]
  );

  const setError = useCallback(
    () => dispatch({ type: "FETCH_USER_DATA_FAILURE" }),
    [dispatch]
  );

  const removeSession = useCallback(() => {
    Cookies.remove("linkedInId");
    queryClient.clear();
    dispatch({ type: "RESET_USER_DATA" });
    isFirstLoading.current = true;
    client.setHeaders(defaultHeaders);
  }, [dispatch]);

  const setToken = useCallback(
    async (token, inviteUri = null, serviceProviderId = null) => {
      await oauthCallbackMutation.mutateAsync(
        {
          code: token,
          redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
          ...(inviteUri &&
            serviceProviderId && {
              inviteUri,
              serviceProviderId,
            }),
        },
        {
          onSuccess: async (resultCallback) => {
            const accessToken =
              resultCallback?.callbackLinkedin?.accessToken ||
              resultCallback?.linkedinId;
            Cookies.set("linkedInId", accessToken, { expires: 365 }); // Expires 365 days
            client.setHeader("Authorization", `Bearer ${accessToken}`);
            inviteUri &&
              serviceProviderId &&
              (await updateServiceProviderProfileMutation.mutateAsync({
                heardAboutUs: { who: serviceProviderId, how: "Invitation" },
              }));
            await sessionLinkedinQuery.refetch();
          },
          onError: () => {
            removeSession();
          },
        }
      );
    },
    [
      dispatch,
      oauthCallbackMutation,
      // sessionLinkedinMutation,
      setError,
      setSession,
    ]
  );

  const session = {
    ...state,
    dispatch,
    removeSession,
    setToken,
    setCurrentUser,
    setError,
  };

  useEffect(() => {
    if (oauthCallbackMutation?.status === "loading") {
      dispatch({ type: "FETCH_USER_DATA_INIT" });
    }
  }, [oauthCallbackMutation?.status]);

  useEffect(() => {
    if (serviceProviderQuery?.data) {
      session.setCurrentUser(serviceProviderQuery?.data);
    }
  }, [serviceProviderQuery?.data]);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};

export default useSession;
