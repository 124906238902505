import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    paddingTop: 206,
    width: "100%",
    maxWidth: 490,
    backgroundColor: theme.palette.secondary.sidebarDiv,
  },
  rootMobile: {},
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      color: "#fff",
      fontFamily: "Basier Regular",
      fontWeight: "300",
      lineHeight: "1.167",
      letterSpacing: "-1.26px",
      marginBottom: 10
    },
    fontSize: "26px",
    marginBottom: 25,
  },
  list: {
    width: "100%",
  },
  listMobile: {
    width: "100%",
  },
  listItem: {
    height: 75,
    marginTop: 5,
    marginBottom: 5,
  },
  avatar: {
    width: 64,
    marginRight: 30,
  },
  littleIcon: {
    margin: 6,
  },
  textItem: {
    width: 280,
  },
  text: {
    fontFamily: "Basier Regular",
    fontSize: 22,
  },
  secondaryText: {
    color: "#fff",
    fontFamily: "Basier Regular",
    fontSize: 17,
  },
  textDone: {
    color: `${theme.palette.primary.main}`,
  },
  textDanger: {
    color: theme.palette.primary.danger,
  },
}));
