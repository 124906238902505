import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./styles";

export const CustomNavLink = ({ to, text, theme, active, ...props }) => {
  const classes = styles();
  return (
    <NavLink
      className={classes[theme]}
      to={to}
      activeClassName={active ? classes.active : null}
      {...props}
    >
      {text}
    </NavLink>
  );
};

CustomNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  /**
   * Defines the navlink colors
   */
  theme: PropTypes.oneOf(["navLink", "yellowLink"]),
};

CustomNavLink.defaultProps = {
  theme: "navLink",
};

export default CustomNavLink;
