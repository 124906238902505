import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "../locales/en/serviceProvider.json";
import translationFR from "../locales/fr/serviceProvider.json";

// the translations
const resources = {
  en: {
    serviceProvider: translationEN,
  },
  fr: {
    serviceProvider: translationFR,
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
};

i18n
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    returnEmptyString: false,
    fallbackLng: "fr",
    // lng: "fr",
    debug: false,
    resources,
  });

export default i18n;
