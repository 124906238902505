import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import * as moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { ReactComponent as MatchingIcon } from "../../../../assets/pictures/matching.svg";
import target from "../../../../assets/pictures/target.svg";
import TjmTooltip from "../../../../components/TjmTooltip";
import useSession from "../../../../hooks/useSession";
import {
  WAITING_FOR_CUSTOMER,
  WAITING_FOR_SERVICEPROVIDER,
  BRIEF_WAITING_FOR_CUSTOMER_SELECTION,
  BRIEF_WAITING_FOR_QUOTES,
} from "../../../../utils/constants";
import { ROUTE_FREELANCE_BRIEF } from "../../../../utils/routeConstants";
import { formatDate } from "../../../../utils/services/format";

import style from "./style";

moment.locale("fr");

const TileBrief = ({ brief }) => {
  const classes = style();
  const { t } = useTranslation("serviceProvider");

  const { user } = useSession();
  const quote = useMemo(
    () => (brief?.quotes || [])?.find((elem) => elem.status !== "EXPIRED"),
    [brief]
  );

  const startDate = brief?.missionContext?.startDate;
  const date = moment(startDate).format("DD/MM/YY");

  const getBriefStatus = () => {
    switch (quote?.status) {
      case WAITING_FOR_SERVICEPROVIDER:
        if (brief?.serviceProvider === user?.id) {
          return {
            status: t("tileBrief.newDirectBrief"),
            icon: <div id="cercle" className={classes.cercle} />,
          };
        }

        return {
          status: t("tileBrief.newBrief"),
          icon: <div id="cercle" className={classes.cercle} />,
        };
      case WAITING_FOR_CUSTOMER:
      default:
        return {
          status: t("tileBrief.waitingForCustomer"),
          icon: (
            <MatchingIcon style={{ stroke: "#1E1E1E", fille: "#1E1E1E" }} />
          ),
        };
    }
  };

  const getComparedDailyRate = (comparedValue) => {
    switch (comparedValue) {
      case "TO_NEGOTIATE":
        return t("tileBrief.toNegotiate");
      case "IN_YOUR_PRICES":
        return t("tileBrief.inYourPrices");
      case "WE_ARE_WIDE":
        return t("tileBrief.weAreWide");
      default:
        return "";
    }
  };

  const getLocalisation = (format, address) => {
    switch (format) {
      case "WHATEVER":
        return t("localisationFormat.whatever");
      case "REMOTE_ONLY":
        return t("localisationFormat.remote");
      case "INPLACE_ONLY":
        return address;
      case "BOTH":
        return `${t("localisationFormat.remote")} - ${address}`;
      default:
        return "";
    }
  };

  const renderTJM = (status) => {
    if (status === WAITING_FOR_CUSTOMER) {
      return `${
        quote?.averageDailyRateForServiceProvider?.toFixed(2) ||
        quote?.averageDailyRateNegotiatedForServiceProvider?.toFixed(2)
      } €`;
    }

    return getComparedDailyRate(quote?.comparisonOfAverageDailyRate);
  };

  return (
    <NavLink
      to={ROUTE_FREELANCE_BRIEF.replace("[id]", brief?.id)}
      className={classes.linkCard}
    >
      <Grid container className={classes.tileBrief}>
        <Grid item className={clsx(classes.gridRight)}>
          <Grid className={classes.middle}>
            <Typography className={classes.titleBrief}>
              {brief?.missionContext?.title}
            </Typography>
          </Grid>
          <Grid container alignItems="center">
            <Grid item className={classes.buttonTarget}>
              <img alt="Target" src={target} />
            </Grid>
            <Grid item>
              <Typography className={classes.underTitleBrief}>
                {brief?.profiles?.length
                  ? brief?.profiles?.map((profile) => profile?.text)
                  : brief?.search?.text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          className={clsx(classes.briefBottom, {})}
          alignItems="center"
          spacing={1}
        >
          <Grid item className={classes.detailItem}>
            <Grid className={classes.statusWrapper}>
              <Grid container wrap="nowrap">
                <Grid item style={{ paddingRight: 5 }}>
                  {getBriefStatus(quote)?.icon}
                </Grid>
                <Grid item>
                  <Typography className={classes.titleNewBrief}>
                    {getBriefStatus(quote)?.status}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {brief.status === BRIEF_WAITING_FOR_CUSTOMER_SELECTION &&
              brief.revealSentAt && (
                <Typography variant="body1" className={classes.underTypo}>
                  {t("tileBrief.sentToClient", {
                    date: formatDate(brief.revealSentAt),
                    interpolation: { escapeValue: false },
                  })}
                </Typography>
              )}
            {brief.status === BRIEF_WAITING_FOR_QUOTES &&
              brief.dateStartMatching && (
                <Typography variant="body1" className={classes.underTypo}>
                  {t("tileBrief.receivedAt", {
                    date: formatDate(brief.dateStartMatching),
                    interpolation: { escapeValue: false },
                  })}
                </Typography>
              )}
          </Grid>
          <Grid item className={classes.detailItem}>
            {/* TJM */}
            <Grid>
              <Typography variant="body1" className={classes.underTypo}>
                {renderTJM(quote?.status)}
                {brief?.status === BRIEF_WAITING_FOR_QUOTES && (
                  <TjmTooltip status={quote?.status} />
                )}
              </Typography>
            </Grid>

            {/* DUREE */}
            <Grid>
              <Typography variant="body1" className={classes.underTypo}>
                {t("tileBrief.durationText", {
                  nbDays: quote?.nbDays,
                  date,
                  interpolation: { escapeValue: false },
                })}
              </Typography>
            </Grid>
          </Grid>

          {/* 2ND COLUMN */}
          <Grid item className={classes.detailItem}>
            <Grid
              style={{
                maxHeight: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography variant="body1" className={classes.underTypo}>
                {getLocalisation(
                  brief?.missionContext?.format,
                  brief?.missionContext?.address
                )}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="body1" className={classes.underTypo}>
                {brief?.missionContext?.weeklyRythm === 5
                  ? t("tileBrief.fullTime")
                  : t("tileBrief.partialTime")}
                {" ("}
                {t("tileBrief.daysPerWeek", {
                  weeklyRythm: brief?.missionContext?.weeklyRythm,
                })}
                )
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </NavLink>
  );
};

export default TileBrief;
