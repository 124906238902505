import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    borderRadius: 15,
    backgroundColor: "transparent",
    height: 900,
    width: 580,
  },
  dialog: {
    backgroundColor: theme.palette.secondary.modalGreen,
  },
  inputWrapper: {
    marginBottom: 10,
  },
  iconButton: {
    position: "absolute",
    top: 0,
    right: 0,
    color: theme.palette.secondary.main,
  },
  titleRecommandation: {
    fontSize: 30,
  },
  underTitleRecommandation: {
    fontSize: 16,
    marginBottom: 15,
  },
  button: {
    minWidth: "50%",
  },
}));
