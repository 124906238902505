import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Amplify, { Auth } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line no-unused-vars

import App from "./components/App";
import { amplifyConfig } from "./conf/amplify";
// Configuration i18n
import "./conf/i18n";
import ProviderWrapper from "./utils/Provider";
import "./index.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://5da696eacb4845e89c77cf471912be8e@o479924.ingest.sentry.io/5525776",
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
    release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

// AWS Amplify Configuration
Amplify.configure({ ...amplifyConfig, ssr: true });

ReactDOM.render(ProviderWrapper(<App />), document.getElementById("root"));
