import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  resignationMain: {
    paddingTop: theme.navbar.height + 40,
  },
  columnDiv: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "16px",
    paddingBottom: "10px",
  },
  formTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 24,
    color: "#EDF906",
  },
  typographyAdditionalStyle: {
    fontFamily: "Basier Medium",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "-0.45px",
  },
  textRGPD: {
    color: theme.palette.secondary.medium,
  },
}));
