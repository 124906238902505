import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  centerPosition: {
    margin: "14px",
    textAlign: "center",
    "&a": {
      textDecoration: "none",
    },
  },
  centerTitle: {
    margin: "0 35px",
    textAlign: "center",
    color: "#EDF906",
    fontWeight: "bold",
    fontSize: "large",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      color: "#EDF906",
      fontWeight: "bold",
      fontSize: "x-large"
    },
  },
  HTMLResignationDoc: {
    fontSize: "0.5rem",
    backgroundColor: "white",
    color: "black",
    padding: "35px",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.9rem",
      backgroundColor: "white",
      color: "black",
      margin: "0 15rem",
      padding: "4rem 5rem"
    },
  },
  date: {
    textAlign: "right",
  },
  freeInfos: {
    textAlign: "left",
  },
  text: {
    color: "black"
  }
}));
