import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  container: {
    overflowStyle: "none", // IE scrollbar
    scrollbarWidth: "none", // Firefox scrollbar
    scrollbarColor: "transparent transparent", // Firefox scrollbar
    "& ::-webkit-scrollbar": {
      width: 0,
    },
  },
  root: {
    margin: 0,
    borderRadius: 15,
    width: "100%",
    color: "#fff",
    backgroundColor: theme.palette.secondary.modalGreen,
    padding: "40px 20px 25px 20px",
  },
  rootInvoice: {
    margin: 0,
    borderRadius: 15,
    backgroundColor: theme.palette.secondary.modalGreen,
    padding: "40px 20px 25px 20px",
  },
  dialogTitleBox: {
    //padding: "30px 0",
  },
  dialogTitleBoxInvoice: {
    padding: 0,
    marginTop: 17,
    marginBottom: 20,
    width: 460,
  },
  dialogTitle: {
    fontSize: 34,
  },
  iconButton: {
    position: "absolute",
    top: 0,
    right: 0,
    color: theme.palette.secondary.main,
  },
  modalSnackbar: {
    position: "sticky",
    top: 0,
    transform: "translate(-30px)",
    width: "calc(100% + 60px)",
    zIndex: 2500,
  },
}));
