import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  uploadContainer: {
    position: "relative",
  },
  invisible: {
    padding: 50,
    width: 80,
    position: "absolute",
    top: 0,
    left: -3,
    opacity: 0,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: -5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadIconWrapper: {
    textAlign: "center",
  },
  maxedFileSize: {
    color: theme.palette.primary.danger,
  },
  uploadTitle: {
    marginBottom: 15,
  },
  img: {
    cursor: "pointer",
    width: "100%",
  },
  uploadLoader: {
    position: "absolute",
    top: -68,
    right: -5,
  },
  uploadMobile: {
    "@media (max-width:960px)": {
      fontSize: "15px",
      fontFamily: "Basier Medium",
      position: "relative",
    },
  },
}));
