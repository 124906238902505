import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  container: {
    marginTop: 5,
    marginBottom: 5,
    position: "relative",
    "@media (max-width:960px)": {
      minWidth: 1000,
    },
  },
  gridCenter: {
    width: "97% !important", // allow 3% space for the invoice contextual menu
    backgroundColor: theme.palette.secondary.modalGreen,
    borderRadius: 15,
  },
  gridTitle: {
    width: "55%",
    height: "10%",
    position: "absolute",
  },
  gridRight: {
    height: "100%",
    borderRadius: " 0px 15px 15px 0px",
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.secondary.black,
    textAlign: "center",
    fontSize: 14,
    lineHeight: 1.57,
    "@media (max-width:1100px)": {
      fontSize: 12,
    },
  },
  rightRed: {
    backgroundColor: theme.palette.primary.danger,
  },
  typo: {
    fontSize: 14,
    fontFamily: "Basier Regular",
    "@media (min-width:960px) and (max-width:1100px)": {
      fontSize: 12,
    },
  },
  typoBrief: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Basier Regular",
    letterSpacing: 1.71,
  },
  chevroncopy: {
    marginLeft: 80,
    width: 30,
    height: 25,
  },

  statusTitle: {
    color: theme.palette.primary.main,
    fontFamily: "Basier Medium",
    marginLeft: 31,
    "@media (min-width:960px) and (max-width:1100px)": {
      marginLeft: 15,
    },
  },
  button: {
    fontSize: 14,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.57,
    letterSpacing: "normal",
    color: theme.palette.secondary.black,
    textAlign: "center",
    fontFamily: "Basier Regular",
  },
  withoutButton: {
    backgroundColor: theme.palette.secondary.dark,
    "&:hover": {
      cursor: "initial",
    },
  },
  buttonIcon: {
    marginLeft: 20,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  menuIcon: {},
  statusTitleRed: {
    color: theme.palette.primary.danger,
  },
  gridRightRed: {
    backgroundColor: theme.palette.primary.danger,
  },
  statusPaid: {
    color: theme.palette.secondary.main,
  },
  containerOverlay: {
    width: 282,
    borderRadius: 15,
    height: 209,
    paddingLeft: 35,
    position: "absolute",
    right: 10,
    zIndex: 7,
    backdropFilter: "blur(6px)",
    backgroundColor: "#0d110d",
    top: "-50%",
    opacity: "96%",
  },
  row: {
    padding: 4,
  },
  navLink: {
    marginTop: 10,
    display: "flex",
    fontSize: 14,
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      color: `${theme.palette.primary.main}`,
      cursor: "pointer",
    },
    "&:hover > g": {
      color: `${theme.palette.primary.main}`,
    },
  },
  icon: {
    color: "red",
  },
  iconRetard: {
    color: "red",
  },
  closeIcon: {
    position: "absolute",
    top: 20,
    right: 10,
    "&:hover": {
      cursor: "pointer",
      color: `${theme.palette.primary.main}`,
    },
  },
  navLinkRetard: {
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    fontSize: 14,
    color: theme.palette.primary.danger,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.danger,
      cursor: "pointer",
    },
    "&:hover > g": {
      color: theme.palette.primary.danger,
    },
  },
  invoicePart: {
    width: "15%",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 .5rem",
  },
  missionTitle: {
    width: "25%",
    justifyContent: "center",
    padding: "0 .5rem",
    margin: "auto",
  },
  typoMission: {
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    fontSize: 14,
    fontFamily: "Basier Regular",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  disabledDownloadButton: {
    pointerEvents: "none",
    visibility: "hidden",
  },
  disabledDownloadLink: {
    pointerEvents: "none",
    color: theme.palette.secondary.medium,
    "& svg g": {
      stroke: theme.palette.secondary.medium,
    },
  },
}));
