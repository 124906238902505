import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  modal: {
    backgroundColor: "transparent",
  },
  modalContainerDelete: {
    backgroundColor: theme.palette.secondary.modalGreen,
    borderRadius: "15px",
    width: 519,
    padding: 30,
    paddingTop: 40,
    textAlign: "left",
    "@media (max-width:960px)": {
      width: 319,
    },
  },
  iconButtonDelete: {
    position: "absolute",
    top: 0,
    right: 0,
    color: theme.palette.secondary.main,
    marginBottom: 20,
  },
  typoDelete: {
    fontSize: 17,
    marginTop: 20,
    marginBottom: 16,
    "@media (max-width:960px)": {
      fontSize: 14,
    },
  },
}));
