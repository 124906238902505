import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import React from "react";

import { Agenda } from "../../../assets/icons/Agenda";
import { capitalize } from "../../../utils/services/format";

import styles from "./styles";

import "moment/locale/fr";

moment.locale("fr");

class LocalizedUtils extends MomentUtils {
  // eslint-disable-next-line class-methods-use-this
  getDatePickerHeaderText(date) {
    const newDate = date.format("LLLL").slice(0, -5);
    return capitalize(newDate);
  }
}

const Calendar = ({
  error,
  label,
  minDate,
  handleChange,
  disableFuture,
  value,
  open,
  onClick,
  onClose,
  placeholder,
  openTo,
  maxDate = undefined,
  autoOk = undefined,
}) => {
  const classes = styles();

  // startDate: date de départ proposée à l'utilisateur quand il veut modifier.
  // minDate: l'utilisateur ne peut pas remonter plus tot que cette date
  // disableFuture: true pour pouvoir choisir une date dans le passé, false pour date dans le future

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale="fr">
      <Typography variant="h4">{label}</Typography>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          // disableToolbar
          open={open}
          onClick={onClick}
          onClose={onClose}
          variant="inline"
          format="DD/MM/yyyy"
          InputProps={{
            className: `${classes.root} ${error ? classes.error : null}`,
          }}
          orientation="portrait"
          id="date-picker-inline"
          name="dateOfBirth"
          value={value}
          onChange={(e) => handleChange(e)}
          minDate={minDate}
          disableFuture={disableFuture}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          PopoverProps={{ PaperProps: { classes: { root: classes.paper } } }}
          keyboardIcon={<Agenda />}
          style={{ width: "100%" }}
          invalidDateMessage=""
          minDateMessage={null} // Disable the error message when startDate is inferior to minDate, but keep anterior dates disabled
          placeholder={placeholder}
          openTo={openTo}
          views={["year", "month", "date"]}
          maxDate={maxDate}
          autoOk={autoOk}
          autoComplete="off"
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default Calendar;
