import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  underSecondaryTitle: {
    opacity: 100,
    textAlign: "left",
    fontSize: 10,
    "&:hover": {
      cursor: "pointer",
      color: `${theme.palette.primary.main}`,
    },
  },
  wrapper: {
    display: "block",
    minWidth: 160,
  },
  selected: {
    color: `${theme.palette.primary.main}`,
  },
  root: {
    width: "100%",
    paddingLeft: 40,
    paddingRight: 40,
    minHeight: 0,
    textTransform: "none",
    fontSize: theme.typography.h4.fontSize,
    fontFamily: "Basier Medium",
    letterSpacing: "-0.45px",
  },
}));
