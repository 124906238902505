import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import CustomButton from "../../../../components/Button";
import Tag from "../../../../components/Tags/Tag";
import TjmTooltip from "../../../../components/TjmTooltip";
import useDownloadAttachment from "../../../../hooks/useDownloadAttachment";
import useSession from "../../../../hooks/useSession";
import {
  WAITING_FOR_SIGNATURE,
  WAITING_FOR_CUSTOMER,
  WAITING_FOR_SERVICEPROVIDER,
  BRIEF_WAITING_FOR_QUOTES,
  BRIEF_WAITING_FOR_CUSTOMER_SELECTION,
  QUOTE_ACCEPTED,
} from "../../../../utils/constants";
import { formatDate } from "../../../../utils/services/format";
import CustomExpansionPanel from "../CustomExpansionPanel";
import MissionSuivi from "../MissionSuivi";

import styles from "./MainContainer.style";
import TjmContainer from "./TjmContainer";

moment().format();

// Takes an unformated date and return an understandable date
const startDate = (date) => moment(date).format("DD/MM/YYYY");

// For each deliverables return a tag
const deliverablesTag = (deliverables) =>
  (deliverables || []).map((item, index) => (
    <Tag key={index} isBig={false} isGrey={!item.code} title={item.text} />
  ));

const MainContainer = ({
  brief,
  quote,
  formik,
  total,
  status,
  averageDailyRate,
  briefNbDays,
  averageDailyRateWithCommission,
  comparedValue,
}) => {
  const { t } = useTranslation("serviceProvider");
  const classes = styles();

  const { user } = useSession();
  const { downloadAttachment } = useDownloadAttachment();
  const currentBrief = useMemo(() => brief || quote?.brief);

  // Takes a weekly rythm value and return an understandable sentence
  const weeklyRythm = (value) => {
    if (value === 0) {
      return t("brief.rythmWhatever");
    }

    return value < 5
      ? t("brief.rythmPartTime", { count: value, value })
      : t("brief.rythmFullTime", { count: value, value });
  };

  // Takes a format type and return an understandable sentence
  const format = {
    WHATEVER: t("localisationFormat.whatever"),
    REMOTE_ONLY: t("localisationFormat.remote"),
    INPLACE_ONLY: t("localisationFormat.inplaceOnly"),
    BOTH: t("localisationFormat.both"),
  };

  // Takes a duration number and type and return an understandable sentence
  const duration = (nb, type) => {
    switch (type) {
      case "DAY":
        return t("brief.durationDay", { count: nb, nb });
      case "WEEK":
        return t("brief.durationWeek", { count: nb, nb });
      default:
        return t("brief.durationMonth", { count: nb, nb });
    }
  };

  const getComparedDailyRate = () => {
    switch (comparedValue) {
      case "TO_NEGOTIATE":
        return t("quotes.toNegotiate");
      case "IN_YOUR_PRICES":
        return t("quotes.inYourPrices");
      case "WE_ARE_WIDE":
        return t("quotes.weAreWide");
      default:
        return "";
    }
  };

  // Takes a language constant and return an understandable sentence
  const languagesTag = {
    NATIVE_FRENCH: t("brief.nativeFrench"),
    FLUENT_FRENCH: t("brief.fluentFrench"),
    NATIVE_ENGLISH: t("brief.nativeEnglish"),
    FLUENT_ENGLISH: t("brief.fluentEnglish"),
    NATIVE_GERMAN: t("brief.nativeGerman"),
    FLUENT_GERMAN: t("brief.fluentGerman"),
    NATIVE_SPANISH: t("brief.nativeSpanish"),
    FLUENT_SPANISH: t("brief.fluentSpanish"),
    NATIVE_ITALIAN: t("brief.nativeItalian"),
    FLUENT_ITALIAN: t("brief.fluentItalian"),
  };

  // Takes a seniority type and return an understandable sentence
  const seniorityField = {
    JUNIOR: t("brief.seniorityValue1"),
    MIDDLE: t("brief.seniorityValue2"),
    SENIOR: t("brief.seniorityValue3"),
    EXPERT: t("brief.seniorityValue4"),
    GURU: t("brief.seniorityValue5"),
    WHATEVER: t("brief.seniorityValue6"),
  };

  const renderTitle = () => {
    if (!status) {
      return "";
    }

    if (status === QUOTE_ACCEPTED) {
      return t("missionPage.title");
    }

    if (
      status === WAITING_FOR_SERVICEPROVIDER ||
      status === WAITING_FOR_CUSTOMER
    ) {
      return currentBrief?.serviceProviderId === user?.id ||
        currentBrief?.serviceProvider === user?.id
        ? t("brief.titleForServiceProvider")
        : t("brief.title");
    }

    return t("missionPage.validationInProgressTitle");
  };

  const renderTJM = () => {
    if (quote?.status === WAITING_FOR_CUSTOMER) {
      return t("brief.adrAmount", {
        amount:
          quote?.averageDailyRateForServiceProvider?.toFixed(2) ||
          quote?.averageDailyRateNegotiatedForServiceProvider?.toFixed(2),
      });
    }

    return quote?.comparisonOfAverageDailyRate && getComparedDailyRate();
  };

  useEffect(() => {
    if (quote?.status === WAITING_FOR_SIGNATURE) {
      toast.success(t("missionPage.submittedSnackbarMessage"));
    } else if (quote?.status === WAITING_FOR_CUSTOMER) {
      toast.success(t("missionPage.acceptedSnackbarMessage"));
    }
  }, [toast]);

  return (
    <Container>
      <Grid container justify="center" style={{ marginBottom: 10 }}>
        <Grid item xs={12} lg={9}>
          <div className={classes.container}>
            <div className={classes.newBrief}>
              <Typography variant="h1" gutterBottom>
                {renderTitle()}
              </Typography>
            </div>
          </div>
          {currentBrief?.status === BRIEF_WAITING_FOR_CUSTOMER_SELECTION &&
            currentBrief?.revealSentAt && (
              <Typography
                variant="body1"
                className={classes.underTypo}
                gutterBottom
              >
                {t("tileBrief.sentToClient", {
                  date: formatDate(currentBrief?.revealSentAt),
                  interpolation: { escapeValue: false },
                })}
              </Typography>
            )}
          {currentBrief?.status === BRIEF_WAITING_FOR_QUOTES &&
            currentBrief?.dateStartMatching && (
              <Typography
                variant="body1"
                className={classes.underTypo}
                gutterBottom
              >
                {t("tileBrief.receivedAt", {
                  date: formatDate(currentBrief?.dateStartMatching),
                  interpolation: { escapeValue: false },
                })}
              </Typography>
            )}
          <Grid>
            <Hidden mdUp>
              {quote?.status === WAITING_FOR_SERVICEPROVIDER ? (
                <TjmContainer
                  formik={formik}
                  total={total}
                  status={status}
                  averageDailyRate={averageDailyRate}
                  briefNbDays={briefNbDays}
                  averageDailyRateWithCommission={
                    averageDailyRateWithCommission
                  }
                  comparedValue={comparedValue}
                />
              ) : (
                <MissionSuivi mobile quote={quote} brief={brief} />
              )}
            </Hidden>
            {/** ******* NOTE BLURB ******** */}

            <div className={classes.blurbAvatarContainer}>
              {(currentBrief?.communityAdmins || []).map((communityAdmin) => (
                <Typography
                  variant="body2"
                  color="primary"
                  gutterBottom
                  className={classes.communityAdminTitle}
                >
                  {t("brief.briefOwner", {
                    firstName: communityAdmin.firstName,
                  })}
                </Typography>
              ))}
            </div>
            {currentBrief?.serviceProviderRecommendation && (
              <div className={classes.seniority} style={{ marginBottom: 20 }}>
                {currentBrief?.serviceProviderRecommendation
                  .split("\n")
                  .map((item, index) => (
                    <Typography
                      paragraph={
                        currentBrief?.serviceProviderRecommendation.split("\n")
                          .length -
                          1 !==
                        index
                      }
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      {item}
                    </Typography>
                  ))}
              </div>
            )}
            {/** ******* NOTE CONTEXT ******** */}
            <Typography variant="h1" gutterBottom>
              {currentBrief?.missionContext?.title}
            </Typography>
            <div className={classes.contextWrapper}>
              <div className={classes.contextContainer}>
                <div className={classes.grid1}>
                  <Typography className={classes.contextTitle}>
                    {t("brief.format")}
                  </Typography>
                  <Typography
                    style={{ lineHeight: 1.47, letterSpacing: "-0.45px" }}
                  >
                    {format[currentBrief?.missionContext.format]}
                  </Typography>
                </div>
                <div className={classes.grid2}>
                  <Typography className={classes.contextTitle}>
                    {t("brief.duration")}
                  </Typography>
                  <div className={classes.mobileFlex}>
                    <Typography
                      style={{ lineHeight: 1.47, letterSpacing: "-0.45px" }}
                    >
                      {duration(
                        currentBrief?.missionContext.duration.nb,
                        currentBrief?.missionContext.duration.unit
                      )}{" "}
                      {t("brief.fromDate", {
                        date: startDate(currentBrief?.missionContext.startDate),
                        interpolation: { escapeValue: false },
                      })}
                    </Typography>
                  </div>
                </div>
                <div className={classes.grid3}>
                  <Typography className={classes.contextTitle}>
                    {t("brief.adr")}
                  </Typography>
                  <Typography
                    style={{ lineHeight: 1.47, letterSpacing: "-0.45px" }}
                  >
                    {renderTJM()}
                    {quote?.status === "WAITING_FOR_SERVICEPROVIDER" && (
                      <TjmTooltip status={quote?.status} />
                    )}
                  </Typography>
                </div>
                <div className={classes.grid4}>
                  <Typography className={classes.contextTitle}>
                    {t("brief.rythm")}
                  </Typography>
                  <Typography
                    style={{ lineHeight: 1.47, letterSpacing: "-0.45px" }}
                  >
                    {weeklyRythm(currentBrief?.missionContext.weeklyRythm)}
                  </Typography>
                </div>
                {currentBrief?.missionContext.address && (
                  <div className={classes.grid5}>
                    <Typography className={classes.contextTitle}>
                      {t("brief.address")}
                    </Typography>
                    <Typography
                      style={{ lineHeight: 1.47, letterSpacing: "-0.45px" }}
                    >
                      {currentBrief?.missionContext.address}
                    </Typography>
                  </div>
                )}
                <div className={classes.grid6}>
                  <Typography className={classes.contextTitle}>
                    {t("brief.endDate")}
                  </Typography>
                  <div className={classes.mobileFlex}>
                    <Typography
                      style={{ lineHeight: 1.47, letterSpacing: "-0.45px" }}
                    >
                      {currentBrief?.missionContext?.endDate
                        ? startDate(currentBrief?.missionContext?.endDate)
                        : t("brief.noEndDate")}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            {quote?.serviceProviderMessage && (
              <div className={classes.seniorityContainer}>
                <Typography variant="h2" className={classes.subtitleSecondary}>
                  {t("brief.message")}
                </Typography>
                <div className={classes.seniority}>
                  {quote?.serviceProviderMessage
                    .split("\n")
                    .map((item, key) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Typography as="p" key={key}>
                        {" "}
                        {item}
                      </Typography>
                    ))}
                </div>
              </div>
            )}
            {quote?.serviceProviderComment && (
              <div className={classes.seniorityContainer}>
                <Typography variant="h2" className={classes.subtitleSecondary}>
                  {t("brief.comment")}
                </Typography>
                <div className={classes.seniority}>
                  {quote?.serviceProviderComment
                    .split("\n")
                    .map((item, key) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Typography as="p" key={key}>
                        {" "}
                        {item}
                      </Typography>
                    ))}
                </div>
              </div>
            )}
            {/** ******* NOTE DELIVERABLES ******** */}
            <div className={classes.deliverablesTagsContainer}>
              <Typography variant="h2" className={classes.subtitle}>
                {t("brief.deliverables")}
              </Typography>
              <div className={classes.tagsList}>
                {deliverablesTag(currentBrief?.deliverables)}
              </div>
            </div>
            {!!currentBrief?.missionDetail?.sharedDocuments?.length && (
              <div className={classes.deliverablesTagsContainer}>
                <Typography variant="h2" className={classes.subtitle}>
                  {t("brief.documentsTitle", {
                    count: currentBrief?.missionDetail?.sharedDocuments?.length,
                  })}
                </Typography>
                <Grid container spacing={1}>
                  {currentBrief?.missionDetail?.sharedDocuments.map(
                    (document, index) => (
                      <Grid item>
                        <CustomButton
                          onClick={() =>
                            downloadAttachment(
                              document?.attachment || document?.id
                            )
                          }
                          style={{ margin: 0 }}
                          theme="secondaryButton"
                        >
                          Document {index + 1}
                        </CustomButton>
                      </Grid>
                    )
                  )}
                </Grid>
              </div>
            )}
            <div className={classes.expansion}>
              <CustomExpansionPanel
                expansionPanelOpen
                panelTitle={t("brief.contextAndTasks")}
                id="context"
              >
                {currentBrief?.missionDetail?.contextAndTasks}
              </CustomExpansionPanel>
            </div>
            <div className={classes.expansion}>
              <CustomExpansionPanel
                expansionPanelOpen
                panelTitle={t("brief.detailsOfDeliverables")}
                id="context"
              >
                {currentBrief?.missionDetail?.detailsOfDeliverables}
              </CustomExpansionPanel>
            </div>
            {/** ******* NOTE PROFILE ******** */}
            <div className={classes.profileContainer}>
              <div className={classes.profileHeader}>
                <Typography variant="h2" className={classes.subtitle}>
                  {t("brief.profileDetail")}
                </Typography>
                <Typography variant="h1" className={classes.subtitle}>
                  {currentBrief?.profiles
                    .map((profile) => profile.text)
                    ?.join(", ")}
                </Typography>
              </div>
              <div className={classes.skills}>
                <Typography variant="h2" className={classes.subtitleSecondary}>
                  {t("brief.expertises")}
                </Typography>
                <div className={classes.tagsList}>
                  {(currentBrief?.missionRequirements?.expertises || []).map(
                    (item, key) => (
                      <Tag
                        key={key}
                        title={item.expertise.text}
                        isPrimaryColor
                        tagType={t("brief.priority")}
                        checked={item.priority}
                        isWithCheckbox
                        isDisabled
                      />
                    )
                  )}
                </div>
              </div>
              <div className={classes.skills}>
                <Typography variant="h2" className={classes.subtitleSecondary}>
                  {t("brief.languages")}
                </Typography>
                <div className={classes.tagsList}>
                  {(currentBrief?.missionRequirements?.languages || []).map(
                    (item, key) => (
                      <>
                        <Tag
                          key={key}
                          title={languagesTag[item.language]}
                          isPrimaryColor
                          tagType={t("brief.priority")}
                          checked={item.essential}
                          isWithCheckbox
                          isDisabled
                        />
                      </>
                    )
                  )}
                </div>
              </div>
              <div className={classes.skills}>
                <Typography variant="h2" className={classes.subtitleSecondary}>
                  {t("brief.sensitivity")}
                </Typography>
                <div className={classes.tagsList}>
                  {currentBrief?.missionRequirements?.sensitivities?.map(
                    (currentSensitivity) => (
                      <Tag
                        title={currentSensitivity?.sensitivity?.text}
                        isPrimaryColor
                        tagType={t("brief.mandatory")}
                        checked={currentSensitivity?.essential}
                        isWithCheckbox
                        isDisabled
                      />
                    )
                  )}
                </div>
              </div>
              <div className={classes.seniorityContainer}>
                <Typography variant="h2" className={classes.subtitleSecondary}>
                  {t("brief.seniority")}
                </Typography>
                <div className={classes.seniority}>
                  <Typography>
                    {
                      seniorityField[
                        currentBrief?.missionRequirements?.seniority
                      ]
                    }
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainContainer;
