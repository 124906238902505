import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  containerLoader: {
    height: `calc(100vh - ${theme.navbar.height}px)`,
  },
  container: {
    marginTop: theme.navbar.height,
  },
  leftContainer: {
    minHeight: `calc(100vh - ${theme.navbar.height}px)`,
    backgroundColor: theme.palette.secondary.dark,
    width: 320,
  },
  card: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: "15px",
    padding: 20,
  },
  element: {
    paddingTop: 80,
  },
  cardTitle: {
    marginBottom: 20,
  },
  textfield: {
    marginTop: 15,
    marginBottom: 15,
  },
  switch: {
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 25,
  },
  tva: {
    width: 215,
    paddingLeft: 15,
  },
  saveButton: {},
  select: {
    width: "50%",
  },
  snackBar: {
    width: "auto",
    backgroundColor: "#ecf805",
    color: "#162217",
    borderRadius: "28px",
    height: 46,
    paddingLeft: 15,
    paddingRight: 15,
  },
  typoSnackBar: {
    fontSize: theme.typography.h4.fontSize,
    fontFamily: "Basier Regular",
    color: theme.palette.secondary.black,
    marginRight: 10,
    marginLeft: 10,
  },
  switchText: {
    color: theme.palette.secondary.switchGreen,
  },
  alert: {
    backgroundColor: "#ecf805",
    color: "#162217",
    padding: 10,
    marginBottom: 20,
    borderRadius: 10,
  },
  alertTypography: {
    color: "#162217",
  },
  legalList: {
    margin: 0,
    paddingLeft: 25,
  },
}));
