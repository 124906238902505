import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import React from "react";


import styles from "./styles";

export const CustomCheckBox = ({
  size,
  shape,
  checked,
  disabled,
  error,
  ...props
}) => {
  const classes = styles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checked={checked}
      disabled={disabled}
      checkedIcon={
        <span
          className={clsx(
            classes.icon,
            classes.checkedIcon,
            classes[size],
            classes[shape]
          )}
        />
      }
      icon={
        <span
          className={clsx(
            classes.icon,
            classes[size],
            classes[shape],
            error ? classes.error : null
          )}
        />
      }
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
};

export default CustomCheckBox;
