import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  linkCard: {
    textDecoration: "none",
  },
  tileBrief: {
    borderRadius: 15,
    overflow: "hidden",
    marginBottom: 10,
  },
  titleBriefs: {
    color: "#fff",
  },
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 15,
  },
  gridRight: {
    padding: 10,
    borderRadius: "15px 0  0 15x",
    backgroundColor: `${theme.palette.primary.main}`,
    flex: "1.8",
  },
  statusWrapper: {},
  middle: {},
  titleNewBrief: {
    color: `${theme.palette.secondary.black}`,
    letterSpacing: "0px",
    fontWeight: "bold",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  titleBrief: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    color: `${theme.palette.secondary.black}`,
    lineHeight: 1.4,
    fontFamily: "Basier Medium",
  },
  profileWrapper: {
    // margin: '10px 0'
  },
  underTitleBrief: {
    color: `${theme.palette.secondary.black}`,
    fontWeight: "bold",
  },
  buttonTarget: {
    display: "flex",
    paddingRight: 5,
  },
  typo: {
    color: "#575e57",
    lineHeight: "1rem",
  },
  underTypo: {
    color: "#575e57",
    fontSize: 14,
  },
  blocTypoUp: {
    marginTop: "15%",
    width: "100%",
  },
  icon: {
    width: 40,
    position: "relative",
    top: 11,
  },
  titleContainer: {
    paddingTop: "8%",
    paddingLeft: 30,
    paddingRight: 38,
  },
  title: {
    fontFamily: "Basier Medium",
  },

  briefBottom: {
    background: theme.palette.secondary.main,
    padding: 10,
    flex: "3",
  },
  statusTitle: {
    fontSize: 14,
    color: "#ecf805",
  },
  cercle: {
    width: 20,
    height: 20,
    borderRadius: 20,
    background: "#000",
  },
  detailItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flex: "1 1 0",
      width: "auto",
    },
  },
  halfContainer: {},
  firstHalf: {
    position: "relative",
    // paddingTop: '10%',
  },
}));
