import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  layout: {
    // marginTop: theme.navbar.height,
    minHeight: "100vh",
  },
  container: {
    padding: "40px 0",
    height: "100%",
    minHeight: "100vh",
  },
  logoImage: {
    width: 180,
    paddingBottom: 10,
  },
}));
