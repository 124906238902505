import "moment/locale/fr";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import {
  useAddQuoteMutation,
  useUpdateQuoteContentMutation,
} from "../../generates";
import useBrief from "../../hooks/useBrief";
import useInvoiceMutation from "../../hooks/useInvoiceMutation";
import useServiceProviderMutation from "../../hooks/useServiceProviderMutation";
import useSession from "../../hooks/useSession";
import { client } from "../../libs/graphqlRequest";
import CustomLoader from "../Loader";
import CustomModal from "../Modal";
import Wizard from "../Wizard";

import CRAForm from "./CRAForm";
import FactoringForm from "./FactoringFrom";
import InvoiceCheck from "./InvoiceCheck";
import InvoiceDetailsForm from "./InvoiceDetailsForm";
import InvoiceLatestForm from "./InvoiceLatestForm";
import QuoteDetails from "./QuoteCreationForm/QuoteDetails";
import QuoteForm from "./QuoteCreationForm/QuoteForm";
import QuotePickerForm from "./QuotePickerForm";

moment.locale("fr");

const PopinCra = ({
  open,
  handleClose,
  briefId,
  goToCreationQuote = false,
}) => {
  const modalRef = React.useRef();
  const { t } = useTranslation("serviceProvider");

  const queryClient = useQueryClient();
  const { mutateAsync: invoiceMutateAsync, status: quoteMutateStatusQuery } =
    useInvoiceMutation(client);
  const addQuoteAsyncMutation = useAddQuoteMutation(client);
  const updateQuoteContentAsyncMutation = useUpdateQuoteContentMutation(client);
  const briefQuery = useBrief(briefId);

  const brief = useMemo(() => briefQuery?.data, [briefQuery?.data]);

  const { userDynamo, user } = useSession();
  const serviceProviderMutation = useServiceProviderMutation();

  const [currentQuote, setCurrentQuote] = useState(null);
  const [displayInvoiceForm, setDisplayInvoiceForm] = useState(false);
  const [displayCreationQuoteForm, setDisplayCreationQuoteForm] =
    useState(goToCreationQuote);
  const [
    isTotalInvoiceAmountEqualQuoteAmount,
    setIsTotalInvoiceAmountEqualQuoteAmount,
  ] = useState(false);
  const [totalLeftToInvoice, setTotalLeftToInvoice] = useState(null);

  const FRENCH_ARTIST_AUTEUR_CODE = "AA";

  const hasActivityCode =
    user?.administrativeProfile?.legalForm === FRENCH_ARTIST_AUTEUR_CODE &&
    !user?.administrativeProfile?.activityCode;

  const hasInsuranceNumber =
    user?.administrativeProfile?.legalForm === FRENCH_ARTIST_AUTEUR_CODE &&
    !user?.administrativeProfile?.insuranceNumber;

  React.useEffect(() => {
    setDisplayCreationQuoteForm(goToCreationQuote);
  }, [goToCreationQuote]);

  const handleSubmitNewQuote = async ({ ...values }) => {
    await addQuoteAsyncMutation.mutateAsync(
      {
        briefId,
        payload: {
          label: values.label,
          isFullPackage: values.isFullPackage,
          nbDays: values?.isFullPackage ? 1 : values.workedDays,
          averageDailyRate: values.averageDailyRate,
          serviceProviderMessage: values.serviceProviderMessage,
          serviceProviderComment: values.serviceProviderComment,
        },
      },
      {
        onSuccess: () => {
          toast.success(t("quoteForm.creationQuoteToastSuccess"));
          queryClient.invalidateQueries("SearchBriefs");
          setCurrentQuote(null);
          setDisplayCreationQuoteForm(false);
        },
        onError: () => {
          toast.error(t("quoteForm.creationQuoteToastError"));
        },
      }
    );
  };

  const handleSubmit = async ({
    administrativeProfile,
    latestInvoice,
    isFinancedByFactoring,
    ...values
  }) => {
    if (administrativeProfile) {
      await serviceProviderMutation.mutateAsync({
        id: userDynamo?.serviceProviderId,
        administrativeProfile,
      });
    }

    if (isTotalInvoiceAmountEqualQuoteAmount) {
      await updateQuoteContentAsyncMutation.mutateAsync({
        quoteId: currentQuote?.id,
        payload: {
          isInvoiceDone: true,
        },
      });
    }

    await invoiceMutateAsync(
      {
        ...values,
        latestInvoice: latestInvoice === "true",
        isFinancedByFactoring: isFinancedByFactoring === "true",
        workedDays: parseFloat(values.workedDays),
      },
      {
        onSuccess: () => {
          toast.success(t("craForm.successMessage"));
          queryClient.invalidateQueries("SearchBriefs");
          handleClose();
        },
        onError: () => {
          toast.error(t("craForm.errorMessage"));
        },
      }
    );
  };

  const firstQuote = React.useMemo(() => brief?.quotes[0], [brief?.quotes]);

  const handleScrollToTopModal = () => {
    modalRef.current.scrollIntoView();
  };

  const handleQuotePicked = (quote) => {
    setCurrentQuote(quote);
  };

  useEffect(() => {
    const updatedQuote = brief?.quotes?.find(
      (quote) => currentQuote?.id === quote.id
    );
    if (updatedQuote) {
      setCurrentQuote(updatedQuote);
    }
  }, [brief]);

  const handleCreationQuoteDisplay = () => {
    setDisplayInvoiceForm(false);
    setDisplayCreationQuoteForm(true);
  };

  const handleInvoiceDisplay = () => {
    setDisplayInvoiceForm(true);
    setDisplayCreationQuoteForm(false);
  };

  const handleCloseForm = () => {
    setDisplayInvoiceForm(false);
    setDisplayCreationQuoteForm(false);
  };

  const isFrenchCompany =
    user?.administrativeProfile?.headOffice?.country === "France";

  const hasVat = isFrenchCompany;
  const isFactoringAllowed = isFrenchCompany;

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      displayError
      ref={modalRef}
      disableBackdropClick
    >
      {briefQuery?.status === "loading" && (
        <Grid container>
          <Box py="10px" pr="20px" mb="16px">
            <Typography variant="h1">
              {t("quoteCreationModal.quotePickerTitle")}
            </Typography>
          </Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ padding: "20px 0px" }}
          >
            <CustomLoader />
          </Grid>
        </Grid>
      )}
      {!displayCreationQuoteForm &&
        !displayInvoiceForm &&
        briefQuery?.status !== "loading" && (
          <QuotePickerForm
            quotes={brief?.quotes}
            onDisplayCreationQuoteForm={handleCreationQuoteDisplay}
            onDisplayInvoiceForm={handleInvoiceDisplay}
            onQuoteSelected={handleQuotePicked}
            currentQuote={currentQuote}
          />
        )}
      {displayCreationQuoteForm && (
        <Wizard
          onSubmit={handleSubmitNewQuote}
          initialValues={{
            workedDays: currentQuote ? currentQuote?.nbDays : 0,
            label: "",
            isFullPackage: false,
            averageDailyRate:
              firstQuote?.averageDailyRateNegotiatedForServiceProvider,
            serviceProviderMessage: "",
            serviceProviderComment: "",
          }}
        >
          <QuoteForm
            quote={firstQuote}
            validationSchema={Yup.object().shape({
              workedDays: Yup.number().when("isFullPackage", {
                is: false,
                then: Yup.number().min(0.01).required(),
              }),
              label: Yup.string().required(),
              isFullPackage: Yup.boolean().required(),
              averageDailyRate: Yup.number().min(1).required(),
            })}
            brief={brief}
            onClose={handleCloseForm}
          />
          <QuoteDetails
            quote={firstQuote}
            validationSchema={Yup.object().shape({
              serviceProviderMessage: Yup.string().required(),
              serviceProviderComment: Yup.string(),
            })}
            isLoading={addQuoteAsyncMutation.status === "loading"}
            brief={brief}
          />
        </Wizard>
      )}
      {currentQuote && displayInvoiceForm && (
        <Wizard
          onSubmit={handleSubmit}
          initialValues={{
            quoteId: currentQuote.id,
            isFullPackage: !!currentQuote?.isFullPackage,
            startDate: null,
            endDate: null,
            workedDays: null,
            comment: "",
            isFinancedByFactoring: "true",
            latestInvoice: "true",
            serviceProviderNumber: "",
            administrativeProfile: {
              legalNotice: user?.administrativeProfile?.legalNotice || "",
              intraCommunityVAT:
                user?.administrativeProfile?.intraCommunityVAT || false,
              vatNumber: user?.administrativeProfile?.vatNumber || null,
              vatRate: user?.administrativeProfile?.vatRate || null,
              ...(hasActivityCode && {
                activityCode: user?.administrativeProfile?.activityCode,
              }),
              ...(hasInsuranceNumber && {
                insuranceNumber: user?.administrativeProfile?.insuranceNumber,
              }),
            },
          }}
        >
          <CRAForm
            onSubmit={handleScrollToTopModal}
            hasVat={hasVat}
            validationSchema={Yup.object().shape({
              startDate: Yup.string().nullable().when("isFullPackage", {
                is: false,
                then: Yup.string().required(),
              }),
              endDate: Yup.string().nullable().when("isFullPackage", {
                is: false,
                then: Yup.string().required(),
              }),
              workedDays: Yup.number()
                .min(0)
                .nullable()
                .when("isFullPackage", {
                  is: false,
                  then: Yup.number().min(0).required(),
                }),
              administrativeProfile: Yup.object().shape({
                intraCommunityVAT: Yup.bool(),
                vatNumber: Yup.string().nullable().when("intraCommunityVAT", {
                  is: true,
                  then: Yup.string(),
                }),
                vatRate: Yup.number()
                  .nullable()
                  .when("intraCommunityVAT", {
                    is: true,
                    then: Yup.number().max(30).required(),
                  }),
              }),
            })}
            loading={
              quoteMutateStatusQuery === "loading" ||
              serviceProviderMutation.status === "loading"
            }
            brief={brief}
            quote={currentQuote}
            user={user}
            onTotalInvoiceAmountEqualQuoteAmount={
              setIsTotalInvoiceAmountEqualQuoteAmount
            }
            totalLeftToInvoice={setTotalLeftToInvoice}
            onClose={handleCloseForm}
          />
          {!isTotalInvoiceAmountEqualQuoteAmount && (
            <InvoiceLatestForm
              onSubmit={handleScrollToTopModal}
              validationSchema={Yup.object().shape({
                latestInvoice: Yup.string(),
              })}
              brief={brief}
              totalLeftToInvoice={totalLeftToInvoice}
            />
          )}
          <InvoiceDetailsForm
            onSubmit={isFrenchCompany && handleScrollToTopModal}
            onPrevious={handleScrollToTopModal}
            hasVat={hasVat}
            brief={brief}
            quote={currentQuote}
            validationSchema={Yup.object().shape({
              comment: Yup.string(),
              serviceProviderNumber: Yup.string(),
              administrativeProfile: Yup.object().shape({
                legalNotice: Yup.string(),
                ...(hasActivityCode && {
                  activityCode: Yup.string().required(),
                }),
                ...(hasInsuranceNumber && {
                  insuranceNumber: Yup.string().required(),
                }),
              }),
            })}
            hasActivityCode={hasActivityCode}
            hasInsuranceNumber={hasInsuranceNumber}
            loading={
              quoteMutateStatusQuery === "loading" ||
              serviceProviderMutation.status === "loading"
            }
            user={user}
          />
          {isFactoringAllowed && (
            <FactoringForm
              onPrevious={handleScrollToTopModal}
              validationSchema={Yup.object().shape({
                isFinancedByFactoring: Yup.string(),
              })}
              hasVat={hasVat}
              loading={
                quoteMutateStatusQuery === "loading" ||
                serviceProviderMutation.status === "loading"
              }
              quote={currentQuote}
              user={user}
            />
          )}
          <InvoiceCheck
            hasVat={hasVat}
            isFactoringAllowed={isFactoringAllowed}
            brief={brief}
            loading={
              quoteMutateStatusQuery === "loading" ||
              serviceProviderMutation.status === "loading"
            }
            quote={currentQuote}
          />
        </Wizard>
      )}
    </CustomModal>
  );
};

export default PopinCra;
