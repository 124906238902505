import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  navLink: {
    color: theme.palette.secondary.main,
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: 14,
    textDecoration: "none",
    "&:hover": {
      color: "#e4e4e4",
    },
    "@media (max-width:960px)": {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  yellowLink: {
    fontsize: theme.typography.h4.fontSize,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.bright,
    },
  },
  dashboardLink: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    height: "60%",
    fontSize: 18,
    alignSelf: "flex-end",
  },
  active: {
    borderBottom: `5px solid ${theme.palette.primary.main}`,
  },
  whiteLink: {
    fontsize: theme.typography.h4.fontSize,
    color: theme.palette.secondary.main,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.secondary.lighter,
    },
  },
  tabs: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 40,
    textDecoration: "none",
    color: theme.palette.secondary.lighter,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}));
