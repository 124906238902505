import { fade } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  tag: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.medium,
    borderRadius: 50,
    cursor: "default",
    fontFamily: "Basier Regular",
    fontSize: 14,
    maxWidth: "100%",
  },
  primaryColor: {
    background: theme.palette.primary.main,
  },
  contentBox: {
    margin: ".5rem 1rem",
  },
  small: {
    margin: ".2rem .5rem",
  },
  smallTagContentBox: {
    margin: ".2rem 1rem",
  },
  withInput: {
    background: theme.palette.secondary.light,
    "& input": {
      border: "none",
      boxShadow: "none",
      background: "transparent",
      "&:focus": {
        outline: "none",
      },
    },
  },
  inputContainer: {
    paddingTop: 4,
    color: "#fff",
    fontSize: 14,
  },
  checkboxTagContainer: {
    color: theme.palette.secondary.medium,
    display: "flex",
    width: "auto",
    maxWidth: "100%",
  },
  checkboxTagLabelContainer: {
    padding: "2px 0",
    flex: 1,
    minWidth: 0,
  },
  checkboxTagLabel: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  checkboxTagContent: {
    paddingLeft: "15px",
    marginLeft: "10px",
    flexShrink: 0,
  },
  checkbox: {
    padding: 0,
    marginLeft: "5px",
    marginBottom: 0,
  },
  isGrey: {
    background: theme.palette.secondary.light,
  },
  isBig: {
    padding: 7,
  },
  checkboxTagClickable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const reactSelectStyles = {
  container: (styles) => ({
      ...styles,
      minHeight: 64,
      marginTop: 5,
      width: "100%",
    }),
  multiValueRemove: (base, state) => ({
    ...base,
    cursor: "pointer",
    "&:hover": {
      background: "transparent",
    },
  }),
  control: (base, state) => ({
    ...base,
    borderRadius: 15,
    height: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "text",
    boxShadow: "none",
    // border: "none",
    color: "#565e56",
    border: "1px solid #565e56",
    fontSize: 17,
    minHeight: 64,
    background: state.isSelected ? "#ecf805" : "#162217",
    "&:hover": {
      border: "1px solid #565e56",
    },
  }),
  clearIndicator: (styles) => ({
      ...styles,
      padding: 0,
      marginRight: 22,
    }),
  placeholder: (styles) => ({
      ...styles,
      fontSize: 14,
      color: "#fff",
    }),
  indicatorsContainer: (styles) => ({
      ...styles,
      cursor: "pointer",
    }),
  indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: "#565e56",
    }),
  groupHeading: (styles) => ({
      ...styles,
      fontSize: 17,
      color: "#162217",
      textTransform: "capitalize",
      marginLeft: 0,
      paddingLeft: 28,
      fontFamily: "Basier Medium",
      position: "sticky",
      top: 0,
      background: "#fff",
      // borderBottom: "1px solid rgba(197,202,179,0.2)",
    }),
  valueContainer: (styles) => ({
      ...styles,
      padding: "8px 8px",
      borderRadius: 15,
      fontFamily: "Basier Medium",
      background: "#162217",
    }),
  input: (styles) => ({
      ...styles,
      color: "#fff",
    }),
  menu: (styles) => ({
      ...styles,
      borderRadius: 15,
      width: "100%",
      overflowStyle: "none", // IE scrollbar
      scrollbarWidth: "none", // Firefox scrollbar
      scrollbarColor: "transparent transparent", // Firefox scrollbar
      "& ::-webkit-scrollbar": {
        width: 0,
      },
    }),
  menuList: (styles) => ({
      ...styles,
      borderRadius: 15,
      paddingBottom: 0,
      paddingTop: 0,
    }),
  option: (styles, state) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      paddingLeft: 40,
      fontSize: 14,
      minHeight: 40,
      cursor: "pointer",
      transition: ".1s",
      color: "#565e56",
      background: state.isFocused ? "#ecf805" : "transparent",
      textDecoration: state.isDisabled ? "line-through" : "none",
      fontFamily: "Basier Regular",
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${fade("#C5CAB3", 0.2)}`,
      },
      "&:active": {
        background: "#ecf805",
      },
    }),
  noOptionsMessage: (styles) => ({
      ...styles,
      fontSize: 14,
      paddingLeft: 40,
      textAlign: "left",
    }),
  multiValue: (styles) => ({
      ...styles,
      textAlign: "left",
      background: "#ecf805",
      borderRadius: 50,
      cursor: "default",
      fontFamily: "Basier Regular",
      maxWidth: "100%",
      padding: "2px 2px",
    }),
};

export { reactSelectStyles };
