import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    borderRadius: 15,
  },
  alert: {
    backgroundColor: "#ecf805",
    color: "#162217",
    padding: 10,
    marginBottom: 20,
    borderRadius: 10,
  },
  helper: {
    color: theme.palette.secondary.medium,
  },
  title: { fontSize: 20, paddingRight: 30 },
  gridCalendar: {
    width: "100%",
    borderRadius: 15,
    backgroundColor: "transparent",
    border: "1px solid #fff",
    color: "#fff",
    marginBottom: 20,
    "&::before, &::after": {
      display: "none",
    },
    "& input": {
      marginLeft: 30,
    },
  },
  paper: {
    "&.MuiPaper-rounded": {
      borderRadius: 16,
      margin: ".8rem 30px",
      "&.MuiPickersDay-day": {
        color: "blue",
      },
    },
  },
  error: {
    borderColor: theme.palette.primary.danger,
  },
  dialog: {
    borderRadius: 15,
    backgroundColor: theme.palette.secondary.modalGreen,
    padding: 30,
    width: 859,
    height: 800,
  },
  quoteButton: {
    background: `${theme.palette.primary.main}`,
    border: `1px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.secondary.black}`,
    "&:hover, &:active": {
      background: `${theme.palette.primary.bright}`,
    },
    textTransform: "none",
    fontSize: 14,
    fontWeight: 500,
    margin: "1rem 0",
    borderRadius: "8px",
    "&.Mui-disabled": {
      color: theme.palette.secondary.medium,
      textDecoration: "none",
    },
  },
  iconButton: {
    position: "absolute",
    top: 72,
    marginLeft: "46%",
    color: theme.palette.secondary.main,
  },
  informationDetail: {
    fontSize: 16,
    alignItems: "center",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  buttonRelance: {
    background: `${theme.palette.primary.main}`,
    border: `1px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.secondary.black}`,
    "&:hover, &:active": {
      background: `${theme.palette.primary.bright}`,
    },
    marginTop: "80%",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 500,
    margin: "1rem 0",
    borderRadius: "8px",
    "&.Mui-disabled": {
      color: theme.palette.secondary.medium,
      textDecoration: "none",
    },
  },
  container: {
    marginTop: "5%",
    marginBottom: 50,
    width: 859,
    backgroundColor: "#212a21",
    borderRadius: 15,
  },
  gridRight: {
    marginLeft: "35%",
    marginTop: 40,
    borderRadius: "15px  15px 0 0",
    backgroundColor: "#333d34",
    "&:hover": {
      cursor: "pointer",
    },
  },
  gridRightTwo: {
    marginTop: 250,
    borderRadius: "0 0 15px 15px",
    backgroundColor: "#151d15",
    "&:hover": {
      cursor: "pointer",
    },
  },
  titleMission: {
    fontSize: 17,
    marginLeft: 20,
    marginTop: 20,
    fontWeight: "bold",
  },
  titleName: {
    fontSize: 14,
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  titleActivity: {
    marginBottom: 20,
  },
  totalMission: {
    fontSize: 14,
    marginLeft: 20,
  },
  underTitleRelance: {
    fontSize: 15,
  },
  numberMissionTwo: {
    color: theme.palette.primary.main,
    fontSize: 24,
    marginRight: "41%",
    marginLeft: 20,
    marginTop: 10,
  },
  numberUnderTitleTwo: {
    marginLeft: 20,
    fontSize: 14,
    marginRight: 10,
    marginTop: 10,
  },
  TagMission: {
    width: 20,
    height: 20,
  },
  tooltip: {
    backgroundColor: "#151d15",
    maxWidth: 320,
    borderRadius: "15px 15px 15px 15px",
    background: theme.palette.secondary.dark,
  },
  infoBulle: {
    fontSize: 14,
    color: "#fff",
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  statusTitle: {
    paddingRight: 10,
    fontSize: 14,
    color: "#ecf805",
  },
  statusExpired: {
    color: theme.palette.primary.danger,
  },
  buttonIcon: {
    width: "1%",
    height: "1%",
    marginLeft: 160,
    marginTop: 10,
    backgroundColor: "#151d15",
  },
  InfosSmallIcon: {
    width: 30,
    height: 25,
    backgroundColor: "#151d15",
    borderRadius: 15,
  },
  largeRoot: {
    width: 112,
    height: 56,
    padding: 0,
  },
  largeSwitchBase: {
    color: theme.palette.secondary.switchThumb,
    padding: 0,
    "&$checked": {
      transform: "translateX(56px)",
      color: theme.palette.primary.main,
      "& + $largeTrack": {
        backgroundColor: theme.palette.secondary.switchGreen,
        opacity: 1,
      },
      "& + $largeThumb": {
        color: theme.palette.primary.main,
      },
    },
  },
  largeThumb: {
    width: 56,
    height: 56,
  },
  largeTrack: {
    borderRadius: 50,
    backgroundColor: theme.palette.secondary.switchGreen,
    transition: theme.transitions.create(["background-color", "border"]),
    opacity: 1,
  },
  smallRoot: {
    width: 76,
    height: 38,
    padding: 1,
  },
  smallSwitchBase: {
    color: theme.palette.secondary.switchThumb,
    padding: 0,
    "&$checked": {
      transform: "translateX(38px)",
      color: theme.palette.primary.main,
      "& + $smallTrack": {
        backgroundColor: theme.palette.secondary.switchGreen,
        opacity: 1,
      },
      "& + $smallThumb": {
        color: theme.palette.primary.main,
      },
    },
  },
  smallThumb: {
    width: 38,
    height: 38,
  },
  smallTrack: {
    borderRadius: 50,
    backgroundColor: theme.palette.secondary.switchGreen,
    transition: theme.transitions.create(["background-color", "border"]),
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
  typoFacture: {
    fontSize: 15,
  },
  informationComment: {
    fontSize: 17,
    alignItems: "start",
    fontWeight: 150,
    lineHeight: "34px",
    paddingTop: 10,
  },
  commentGrid: {
    width: "60%",
  },
  typoInformationComment: {
    marginTop: 25,
    fontSize: 15,
    marginLeft: "40%",
  },
  remainingDays: {
    color: theme.palette.primary.main,
  },
  titleChiffre: {
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 3,
    marginLeft: 6,
  },
  reasonLabel: {
    margin: "auto",
  },
  summaryTypoContainer: {
    padding: "0 5px",
    height: "70px",
    [theme.breakpoints.up("md")]: {
      padding: "0 10px",
    },
  },
  packageHelper: {
    color: theme.palette.primary.main,
    marginBottom: "16px",
  },
}));
