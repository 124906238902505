import { useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import * as moment from "moment";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { AValiderIcon } from "../../../../assets/icons/AValiderIcon";
import { DemarreIcon } from "../../../../assets/icons/DemarreIcon";
import { EnCoursIcon } from "../../../../assets/icons/EnCoursIcon";
import { MenuIcon } from "../../../../assets/icons/MenuIcon";
import agency from "../../../../assets/pictures/agancy.svg";
import CustomButton from "../../../../components/Button";
import CircleImage from "../../../../components/CircleImage";
import CustomLoader from "../../../../components/Loader";
import Overlay from "../../../../components/Overlay";
import PopinCra from "../../../../components/PopinCra";
import { theme } from "../../../../utils/configureMaterialTheme";
import { QUOTE_ACCEPTED } from "../../../../utils/constants";
import { ROUTE_FREELANCE_BRIEF } from "../../../../utils/routeConstants";
import LeftOverlay from "../LeftOverlay";

import styles from "./styles";

moment.locale("fr");

const Mission = ({ brief, now }) => {
  const classes = styles();
  const { t } = useTranslation("serviceProvider");

  const history = useHistory();

  const quotesQuery = null;

  const smallScreenSize = useMediaQuery(theme.breakpoints.down("md"));
  const [popinCRAOpen, setPopinCRAOpen] = useState(false);
  const date = moment(brief?.missionContext?.startDate).format("DD/MM/YYYY");

  // remove hours from today
  const startingPointWithHour = new Date(brief?.missionContext?.startDate);
  startingPointWithHour.setHours(0, 0, 0, 0);
  const startingPoint = startingPointWithHour.getTime();
  // remove hours from today
  const todayWithHour = new Date(now);
  todayWithHour.setHours(0, 0, 0, 0);
  const todayInTimestamp = todayWithHour.getTime();

  const days = Math.floor((startingPoint - todayInTimestamp) / 86400000);

  const quotes = quotesQuery?.data || brief?.quotes || [];

  const initialQuote = useMemo(
    () => quotes.find((quote) => !quote.isAdditional),
    [quotes]
  );

  const quoteAccepted = useMemo(
    () => quotes?.find((briefQuote) => briefQuote.status === QUOTE_ACCEPTED),
    [brief, quotes]
  );

  const isInvoiceDoneFromQuote = quotes?.every(
    (quote) => quote?.isInvoiceDone === true
  );

  const getStatus = () => {
    if (brief?.status === "CLOSED" && isInvoiceDoneFromQuote) {
      return {
        icon: <AValiderIcon viewBox="0 0 21 21" style={{ height: 17 }} />,
        text: t("dashboard.missions.statusFinished"),
        future: false,
      };
    }

    if (days > 0) {
      return {
        icon: (
          <DemarreIcon
            className={classes.icon}
            viewBox="0 0 21 21"
            style={{ height: 17 }}
          />
        ),
        text: t("dashboard.missions.statusBeginIn", { diff: days }),
        future: true,
      };
    }

    return {
      icon: (
        <EnCoursIcon
          className={classes.icon}
          viewBox="0 0 21 21"
          style={{ height: 17 }}
        />
      ),
      text: t("dashboard.missions.statusInProgress"),
      future: false,
    };
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const isButtonActivated = !getStatus()?.future && !isInvoiceDoneFromQuote;

  const truncateTitle = (briefTitle, maxLength) => {
    if (briefTitle.length <= maxLength) {
      return briefTitle;
    }

    return `${briefTitle.slice(0, maxLength)}...`;
  };

  return (
    <Box mb="10px" width="100%">
      <Grid container className={classes.container}>
        <Grid
          spacing={1}
          container
          alignItems="center"
          className={classes.tileContainer}
        >
          {quotesQuery?.status === "loading" ? (
            <Grid
              className={classes.fullHeight}
              container
              alignItems="center"
              justify="center"
              style={{ minHeight: 63 }}
            >
              <CustomLoader />
            </Grid>
          ) : (
            <>
              <Grid item className={classes.tileDetailContainer}>
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  onClick={() => {
                    history.push(
                      ROUTE_FREELANCE_BRIEF.replace("[id]", brief?.id)
                    );
                  }}
                >
                  <Grid item>
                    <CircleImage src={agency} alt="Company" theme="mission" />
                  </Grid>
                  <Grid item style={{ flex: "1" }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item className={classes.detailRow}>
                        <Grid container>
                          <Grid item style={{ paddingRight: 5 }}>
                            {getStatus()?.icon}
                          </Grid>
                          <Grid item>
                            <Typography className={classes.statusTitleBase}>
                              {getStatus()?.text}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography variant="h4" className={classes.typo}>
                          {brief?.company?.name || brief?.customer?.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={classes.detailRow}
                        style={{ minWidth: 0 }}
                      >
                        <Grid>
                          <Typography
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {truncateTitle(
                              brief?.missionContext?.title,
                              smallScreenSize ? 35 : 40
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.detailRow}>
                        <Grid container>
                          <Grid item>
                            <Typography
                              variant="body1"
                              className={classes.typo}
                            >
                              {t("dashboard.missions.dailyRateAmount", {
                                amount:
                                  initialQuote?.averageDailyRateNegotiatedForServiceProvider,
                              })}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.blocTypoDown}>
                            <Typography
                              variant="body1"
                              className={classes.typo}
                            >
                              {t("dashboard.missions.durationDaysFrom", {
                                count: quoteAccepted?.nbDays,
                                date,
                                interpolation: { escapeValue: false },
                              })}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.actionContainer}>
                <Grid container spacing={1} alignItems="center">
                  {isButtonActivated && (
                    <Grid item>
                      <CustomButton
                        onClick={() => setPopinCRAOpen(true)}
                        className={classes.button}
                        style={{ margin: 0 }}
                        theme="primaryButton"
                      >
                        {t("dashboard.missions.CRAButton")}
                      </CustomButton>
                      {popinCRAOpen && (
                        <PopinCra
                          open={popinCRAOpen}
                          handleClose={() => setPopinCRAOpen(false)}
                          briefId={brief?.id}
                        />
                      )}
                    </Grid>
                  )}
                  <Grid item style={{ marginLeft: "auto" }}>
                    <IconButton
                      className={classes.buttonIcon}
                      aria-label="display more actions"
                      onClick={handleMenuOpen}
                      color="secondary"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Overlay
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleMenuClose}
                  TransitionComponent={Fade}
                >
                  <LeftOverlay brief={brief} currentQuote={quoteAccepted} />
                </Overlay>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Mission;
