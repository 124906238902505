import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  containerLoader: {
    height: `calc(100vh - ${theme.navbar.height}px)`,
  },
  container: {
    marginTop: theme.navbar.height,
  },
  leftContainer: {
    minHeight: `calc(100vh - ${theme.navbar.height}px)`,
    backgroundColor: theme.palette.secondary.dark,
    width: 320,
    "@media (max-width:960px)": {
      visibility: "hidden",
      display: "none",
    },
  },
  card: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: "15px",
    padding: 20,
  },
  element: {
    paddingTop: 40,
    flexGrow: 0,
    maxWidth: "100%",
    flexBasis: "100%",
  },
  cardTitle: {
    padding: 20,
    marginBottom: 5,
  },
  textfield: {
    marginTop: 15,
    marginBottom: 15,
  },
  switch: {
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 25,
  },
  tva: {
    width: 215,
    paddingLeft: 15,
  },
  saveButton: {},
  select: {
    width: "50%",
  },
  snackBar: {
    width: "auto",
    backgroundColor: "#ecf805",
    color: "black",
    borderRadius: "28px",
    height: 46,
    paddingLeft: 15,
    paddingRight: 15,
  },
  typoSnackBar: {
    fontSize: theme.typography.h4.fontSize,
    fontFamily: "Basier Regular",
    color: theme.palette.secondary.black,
    marginRight: 10,
    marginLeft: 10,
  },
  infoMyProfil: {
    width: "80%",
    marginTop: 40,
    paddingLeft: 16,
    "@media (max-width:960px)": {
      width: "auto",
      paddingLeft: 10,
    },
  },
}));
