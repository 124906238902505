import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  containerSidebarQuotePage: {
    "@media (min-width:960px)": {
      display: "flex",
      flexWrap: "wrap",
      position: "sticky",
      top: theme.navbar.height,
    },
  },
  alert: {
    backgroundColor: "#ecf805",
    color: "#162217",
    padding: 10,
    marginBottom: 20,
    borderRadius: 10,
  },
  horizontalMobileContainer: {
    marginBottom: 20,
    "@media (max-width:960px)": {
      position: "auto",
      overflowX: "auto",
      flexWrap: "unset !important",
      marginBottom: 0,
      overflow: "scroll",
    },
  },
  mobileCard: {
    margin: 0,
  },
  mobileCardWhite: {
    textAlign: "center",
    border: "solid 1px #565e56",
    borderRadius: 15,
    height: "100%",
  },
  mobileCardContainer: {},
  tileContainer: {
    borderRadius: 15,
  },
  tileBottomContainer: {
    borderRadius: 15,
  },
  tjmTile: {},
  tjmTopTile: {
    backgroundColor: "#212a21",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  tjmInfo: {
    color: "#ecf805",
    fontSize: 14,
    textAlign: "center",
  },
  tjmBottomTile: {
    backgroundColor: "rgba(33, 42, 33, 0.5)",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    color: "#ecf805",
    fontSize: 14,
    padding: "20px 5px",
    textAlign: "center",
  },
  subTotalTag: {
    backgroundColor: "#ecf805",
    borderRadius: 30,
    color: "#162217",
    letterSpacing: 0,
    padding: "2px 10px",
    display: "inline",
  },
  detailsTile: {},
  infoTile: {
    backgroundColor: "#151D15",
    borderRadius: "inherit",
    color: "#ffffff",
    fontSize: 17,
    height: "inherit",
    letterSpacing: -0.45,
    lineHeight: 1.41,
    padding: 20,
    opacity: 0.9,
  },
  infoTextTile: {
    fontSize: "17px !important",
    lineHeight: "1.45 !important",
    letterSpacing: "-0.30px !important",
  },
  title: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: "10px 10px 5px 10px",
  },
  detailsBox: {
    textAlign: "center",
    padding: "0px 10px 0px 10px",
  },
  paragraph: {
    display: "block",
    letterSpacing: "-0.37px",
  },
  totalBox: {
    borderTop: "1px solid #565e56",
    padding: "14px 0 14px",
  },
  total: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    letterSpacing: "-0.37px",
  },
  totalText: {},
  totalTag: {
    backgroundColor: "#c5cab3",
    borderRadius: 30,
    color: "#000",
    letterSpacing: 0,
    marginLeft: 6,
    padding: "2px 10px",
  },
  tjmBox: {},
  editButton: {
    border: "1px solid #fff",
    borderRadius: 30,
    height: 38,
  },
  editButtonClicked: {
    border: "1px solid #ecf805",
    borderRadius: 30,
    height: 38,
  },
  inputLabel: {
    marginBottom: 10,
  },
  input: {
    backgroundColor: "transparent",
    borderRadius: 10,
    fontSize: 17,
    height: 50,
    textAlign: "center",
    "@media (min-width:960px)": {
      minWidth: "165px",
    },
    padding: 0,
    "& input": {
      textAlign: "center",
    },
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
  },

  inputWrapper: {
    paddingBottom: 20,
  },
}));
