import * as Sentry from "@sentry/react";
import React from "react";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { Switch, Route, Redirect } from "react-router";
import { useLocation } from "react-router-dom";

import {
  ROUTE_FREELANCE_ADMINISTRATIVE,
  ROUTE_FREELANCE_CREATE_ACCOUNT,
  ROUTE_FREELANCE_HOME,
  ROUTE_FREELANCE_INVOICES,
  ROUTE_FREELANCE_LINKEDIN,
  ROUTE_FREELANCE_LOGIN,
  ROUTE_FREELANCE_PROFILE,
  ROUTER_FREELANCE_BRIEF,
  ROUTER_FREELANCE_MISSION,
  ROUTE_FREELANCE_MAINTENANCE,
  ROUTE_FREELANCE_RESIGNATION,
  ROUTE_FREELANCE_POOL_REQUEST,
} from "../../utils/routeConstants";
import AdministrativeView from "../../views/ServiceProvider/AdministrativeView";
import DashboardView from "../../views/ServiceProvider/DashboardView";
import InvoiceListView from "../../views/ServiceProvider/InvoiceListView";
import MaintenanceView from "../../views/ServiceProvider/MaintenanceView";
import PoolRequestView from "../../views/ServiceProvider/PoolRequestView";
import ProfileView from "../../views/ServiceProvider/ProfileView";
import QuoteView from "../../views/ServiceProvider/QuoteView";
import ResignationView from "../../views/ServiceProvider/ResignationView";
import SignInView from "../../views/ServiceProvider/SignInView";
import SignUpView from "../../views/ServiceProvider/SignUpView";
import PublicRoute from "../PublicRoute";

function App() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
    return (
      <Switch>
        <Route component={MaintenanceView} />
      </Switch>
    );
  }

  if (process.env.REACT_APP_REDIRECT_URL) {
    return (
      <Switch>
        <Route
          render={() => {
            window.location = `${process.env.REACT_APP_REDIRECT_URL}/${pathname}`;
          }}
        />
      </Switch>
    );
  }

  return (
    <Sentry.ErrorBoundary
      showDialog
      fallback={
        <div style={{ color: "#fff" }} variant="body1">
          Une erreur est survenue. Merci de rafraîchir la page.
        </div>
      }
    >
      <Switch>
        {/* PUBLIC ROUTE */}
        <Route
          exact
          path={ROUTE_FREELANCE_MAINTENANCE}
          component={MaintenanceView}
        />
        <Route
          exact
          path={ROUTE_FREELANCE_LINKEDIN}
          component={LinkedInPopUp}
        />
        <Route
          exact
          path="/"
          render={() => <Redirect to={ROUTE_FREELANCE_LOGIN} />}
        />
        <PublicRoute
          exact
          path={ROUTE_FREELANCE_LOGIN}
          fixed
          component={SignInView}
        />
        {/* <PublicRoute exact path="/password" fixed component={ForgotPassword} /> */}
        {/* PRIVATE ROUTE */}
        <PublicRoute
          exact
          path={ROUTE_FREELANCE_ADMINISTRATIVE}
          fixed
          component={AdministrativeView}
        />
        <PublicRoute
          exact
          path={ROUTE_FREELANCE_HOME}
          fixed
          component={DashboardView}
        />
        <PublicRoute
          exact
          path={ROUTE_FREELANCE_INVOICES}
          fixed
          component={InvoiceListView}
        />
        <PublicRoute
          exact
          path={ROUTE_FREELANCE_PROFILE}
          fixed
          component={ProfileView}
        />
        <PublicRoute
          exact
          path={ROUTE_FREELANCE_CREATE_ACCOUNT}
          fixed
          component={SignUpView}
        />
        <PublicRoute
          exact
          path={ROUTER_FREELANCE_BRIEF}
          fixed
          component={QuoteView}
        />
        <PublicRoute
          exact
          path={ROUTER_FREELANCE_MISSION}
          fixed
          component={QuoteView}
        />
        <PublicRoute
          exact
          path={ROUTE_FREELANCE_RESIGNATION}
          fixed
          component={ResignationView}
        />
        <PublicRoute
          exact
          path={ROUTE_FREELANCE_POOL_REQUEST}
          fixed
          component={PoolRequestView}
        />
        {/* REDIRECT WRONG PATH */}
        <Route render={() => <Redirect to={ROUTE_FREELANCE_LOGIN} />} />
      </Switch>
    </Sentry.ErrorBoundary>
  );
}

export default App;
