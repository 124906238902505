import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  loadingContainer: {
    height: 200,
  },
  container: {
    flexGrow: 1,
  },
  sectionContainer: {
    marginBottom: 40,
    maxWidth: 450,
    marginLeft: "auto",
    marginRight: "auto",
  },
  gridRightDashboard: {
    borderRadius: "0 15px 15px 0",
    width: 120,
    backgroundColor: `${theme.palette.primary.main}`,
    "&:hover": {
      cursor: "pointer",
    },
    display: "flex",
    textDecoration: "none",
    padding: "1rem",
    textAlign: "center",
    "@media (max-width:960px)": {
      width: "100%",
    },
  },
  button: {
    fontSize: "14px !important",
    fontFamily: "Basier Medium !important",
    color: `${theme.palette.secondary.black} !important`,
  },
  titleDashboard: {
    backgroundColor: "#283028",
    borderRadius: "15px 0  0 15px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      borderRadius: 15,
      justifyContent: "space-between",
    },
  },
  innerTile: {
    margin: "1rem 1.5rem 1rem 1.5rem",
    display: "flex",
    flexWrap: "wrap",
  },
  toolboxAcracy: {
    borderRadius: 1,
    border: "solid 2 #ffffff",
    display: "flex",
    alignItems: "center",
    paddingRight: "0.5rem",
  },
  toolboxContent: {
    flex: "1 1 60%",
  },
  contactAcracy: {
    // height: 190,
    borderRadius: "15px",
    backgroundColor: `${theme.palette.primary.main}`,
    "&:hover": {
      cursor: "pointer",
    },
    padding: "1rem",
    textAlign: "center",
    // minWidth: 212
  },
  titleContact: {
    fontSize: 16,
  },
  phoneCallImg: {
    width: "87px",
    height: "86px",
  },
  profileReviewing: {
    fontWeight: "bold",
    fontSize: 15,
  },
  clappingHandsImg: {
    width: "61px",
  },
  layout: {
    marginTop: theme.navbar.height,
  },
  briefsWrapper: {
    position: "relative",
  },
  toInvoicesButton: {
    textDecoration: "none",
  },
  toInvoicesButtonImg: {
    marginLeft: "1rem",
    transform: "rotate(-90deg)",
  },
  contact: {
    // maxWidth: 212
  },
  tilesButton: {
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0 0 15px 15px",
    },
  },
  emptyContainer: {
    background: theme.palette.secondary.mid,
    borderRadius: 8,
    // width: 300,
    padding: "15px 0",
    "@media (max-width:400px)": {
      // width: '100%'
    },
  },
}));
