import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import { getIn } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import useSubmitOnChange from "../../hooks/useSubmitOnChange";
import Calendar from "../Inputs/Calendar";
import CustomSwitch from "../Switch";

export const availabilityFormSchema = Yup.object().shape({
  enabled: Yup.bool().required(),
  availabilityDate: Yup.string().nullable().when("enabled", {
    is: true,
    then: Yup.string().required(),
  }),
});

const AvailabilityForm = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
}) => {
  const { t } = useTranslation("serviceProvider");
  const [calendarOpen, setCalendarOpen] = useState(false);
  useSubmitOnChange({ timeout: 100 });
  const { availabilityDate, enabled } = values;

  const changeDateValue = (champs, e) => {
    const newValue = parseInt(e?._d?.getTime(), 10);
    const dateFnsDate = format(new Date(newValue), "yyyy-MM-dd'T'HH:mm:ssxxx");

    const updatedValue = {
      target: { name: champs, value: dateFnsDate },
    };

    handleChange(updatedValue);
    setCalendarOpen(false);
  };

  const handleStatusChange = (field) => (value) => {
    setFieldValue(field, value);

    if (!value) {
      setFieldValue("availabilityDate", null);
    }
  };

  return (
    <Grid container>
      <Grid container alignItems="center" wrap="nowrap">
        <CustomSwitch
          style={{ width: "75px", margin: "0px 0% 0px 3%" }}
          switchSize="small"
          checked={enabled}
          setChecked={handleStatusChange("enabled")}
        />
        <Typography variant="h2">{t("availabilityForm.title")}</Typography>
      </Grid>
      {enabled && (
        <Box pt="20px">
          <Calendar
            label={`${t("availabilityForm.label")}`}
            name="availabilityDate"
            openTo="date"
            // minDate={new Date()}
            value={availabilityDate}
            handleChange={(e) => changeDateValue("availabilityDate", e)}
            disabled
            open={calendarOpen}
            onClick={() => setCalendarOpen(true)}
            onClose={() => setCalendarOpen(false)}
            error={
              !!getIn(touched, "availabilityDate") &&
              !!getIn(errors, "availabilityDate")
            }
          />
        </Box>
      )}
    </Grid>
  );
};
export default AvailabilityForm;
