import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(() => ({
  messageContainer: {
    width: "100%",
    backgroundColor: "#212a21",
    padding: 20,
    borderRadius: 10,
  },
  expanded: {
    marginBottom: 10,
  },
  content: {
    "&$expanded": {
      marginBottom: 10,
    },
  },
}));
