import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  container: {
    width: 337,
    borderRadius: 15,
    padding: "30px 0px",
    "&:focus": {
      outline: "none",
    },
  },
  row: {
    padding: 8,
  },
  navLink: {
    display: "flex",
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: 14,
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      color: `${theme.palette.primary.main}`,
      cursor: "pointer",
    },
  },
  navLinkDisabled: {
    display: "flex",
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: 14,
    userSelect: "none",
    color: "#565e56",
    "-moz-user-select": "none" /* Firefox */,
    "-webkit-user-select":
      "none" /* Chrome, Safari, Opéra depuis la version 15 */,
    "-ms-user-select":
      "none" /* Internet explorer depuis la version 10 et Edge */,
  },
  closeIcon: {
    position: "absolute",
    top: 20,
    right: 10,
    "&:hover": {
      cursor: "pointer",
      color: `${theme.palette.primary.main}`,
    },
  },
  list: {
    padding: 0,
    borderRadius: 15,
  },
  paper: {
    borderRadius: 15,
    backgroundColor: "rgb(13, 17, 13, 0.96)",
  },
  hideMenu: {
    "@media (min-width:960px)": {
      display: "none",
    },
  },
}));
