import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  typoTitle: {
    textAlign: "center",
    fontFamily: "Basier Regular",
    marginBottom: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  chevroncopy: {
    width: 40,
    height: 40,
  },
  invoicesTableHeader: {
    width: "89%", // Keep for title alignment
    "@media (max-width:960px)": {
      minWidth: 885,
    },
  },
  firstTitle: {
    marginLeft: 20,
  },
  invoiceTitleContainer: {
    width: "15%",
    padding: "0 .5rem",
  },
  invoiceMissionTitleContainer: {
    width: "25%",
    padding: "0 .5rem",
  },
}));
