import makeStyles from "@material-ui/core/styles/makeStyles";

import icon from "../../../../assets/icons/checkbox-checked.svg";

export default makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: "15px",
    padding: 20,
    width: "100%",
    "@media (max-width:960px)": {
      padding: 10,
      maxHeight: "100%",
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  avatar: {
    width: 170,
    height: 170,
    backgroundColor: "#565e56",
    fontSize: 14,
    textAlign: "center",
  },
  typoInformationComment: {
    marginLeft: "70%",
    marginTop: "20%",
    color: "#565e56",
  },
  root: {
    width: 150,
    color: "#fff",
    fontSize: 22,
  },
  typoStyle: {
    color: "#fff",
    marginLeft: 20,
    marginRight: "40%",
  },
  typoStyletwo: {
    color: theme.palette.primary.main,
  },
  selection: {
    width: "320%",
    marginLeft: 25,
  },
  iconAdd: {
    marginLeft: "60%",
    marginTop: 40,
  },
  typoStyleSkill: {
    color: theme.palette.primary.main,
    marginLeft: "70%",
  },
  typoStyleTwoSkill: {
    color: "#fff",
    marginTop: 10,
  },
  typoUpload: {
    marginLeft: 25,
  },
  group24: {
    width: 94,
    height: 121,
    marginLeft: 20,
  },
  upload: {
    marginBottom: 80,
  },
  input: {
    marginTop: "4%",
    position: "absolute",
    fontSize: 14,
  },
  icon: {
    borderRadius: 8,
    width: 56,
    height: 56,
    border: "solid 1px #565e56",
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    "input:hover ~ &": {
      border: "solid 1px #fff",
    },
  },
  checkedIcon: {
    "&:before": {
      border: "transparent",
      display: "block",
      width: 56,
      height: 56,
      backgroundImage: `url(${icon})`,
      content: '""',
    },
  },
  small: {
    borderRadius: 4,
    width: 28,
    height: 28,
    "&:before": {
      backgroundImage: `url(${icon})`,
      backgroundSize: "60%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "-15% -15%",
    },
    "input:hover ~ &": {
      borderColor: theme.palette.secondary.black,
    },
  },
  fullName: {
    color: theme.palette.primary.main,
  },
  alert: {
    backgroundColor: "#ecf805",
    color: "#162217",
    padding: 10,
    marginBottom: 20,
    borderRadius: 10,
  },
  alertTypography: {
    color: "#162217",
  },
}));
