import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(() => ({
  signalProblemBody: {
    opacity: 0.9,
    fontFamily: "Basier Regular",
    fontSize: 17,
    lineHeight: 1.41,
    letterSpacing: -0.45,
    color: "#ffffff",
    marginBottom: 20,
  },
  signalProblemSecondTitle: {
    fontFamily: "Basier Regular",
    fontSize: 17,
    lineHeight: 2,
    letterSpacing: -0.45,
    color: "#ffffff",
  },
}));
