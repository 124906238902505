import { GraphQLClient } from "graphql-request";
import Cookies from "js-cookie";

const endpoint = process.env.REACT_APP_APIGATEWAY_GRAPHQL_URL;

const defaultHeaders = {
  "x-api-key": process.env.REACT_APP_APIKEY,
};

const customFetch = async (input, init) => {
  try {
    const accessToken = Cookies.get("linkedInId") || null;

    if (!accessToken) {
      return fetch(input, init);
    }

    return fetch(input, {
      ...init,
      headers: {
        ...init.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (err) {
    console.log(err);
  }

  return fetch(input, init);
};

const client = new GraphQLClient(endpoint, {
  headers: defaultHeaders,
  fetch: customFetch,
});

export { client, endpoint, defaultHeaders };
