import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(13, 17, 13, 0.96)",
    maxWidth: 280,
    borderRadius: "15px 15px 15px 15px",
    background: theme.palette.secondary.dark,
  },
  InfosSmallIcon: {
    width: 20,
    height: 20,
  },
  infoBulle: {
    fontSize: 13,
    color: "#fff",
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },
  buttonIcon: {
    // width: '1%',
    // height: '1%',
    // marginLeft: 68,
    backgroundColor: "#fff",
    // top: '55.5%',
    // zIndex: '999',
  },
  statusTitleRed: {
    fontSize: 13,
    color: theme.palette.primary.main,
  },
}));
