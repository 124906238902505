import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import styles from "./styles";

const DarkWrapper = ({ children, isBleed }) => {
  const classes = styles();
  return (
    <Grid className={clsx(classes.darkWrapper, isBleed ? classes.bleed : null)}>
      {children}
    </Grid>
  );
};

DarkWrapper.propTypes = {
  children: PropTypes.node,
  isBleed: PropTypes.bool,
};

DarkWrapper.defaultProps = {
  children: null,
  isBleed: false,
};

export default DarkWrapper;
