import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: "rgb(13, 17, 13, 0.96)",
    width: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    borderRadius: "15px 0 0 15px",
    paddingLeft: 17,
    "@media (max-width:960px)": {
      flexDirection: "column",
      width: "100%",
      borderRadius: "15px 15px 0 0",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  navLink: {
    display: "flex",
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: 14,
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      color: `${theme.palette.primary.main}`,
    },
    "&:hover svg g, &:hover svg g path": {
      stroke: `${theme.palette.primary.main}`,
    },
  },
  disabledNavLink: {
    color: `${theme.palette.secondary.medium}`,
    cursor: "default",
    "&:hover": {
      color: `${theme.palette.secondary.medium}`,
    },
    "& svg g, & svg g path, &:hover svg g, &:hover svg g path": {
      stroke: `${theme.palette.secondary.medium}`,
    },
  },
  icon: {
    display: "block",
    marginRight: "5px",
    marginTop: "0px",
  },
  closeIcon: {
    position: "absolute",
    top: 20,
    right: 10,
    cursor: "pointer",
    "&:hover g": {
      stroke: `${theme.palette.primary.main}`,
    },
  },
  companyInfos: {
    "@media (max-width: 1280px)": {
      margin: "1rem auto",
    },
    "@media (min-width: 1280px)": {
      padding: "1rem",
    },
  },
}));
