import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  modal: {
    backgroundColor: "transparent",
  },
  modalContainer: {
    backgroundColor: theme.palette.secondary.modalGreen,
    borderRadius: "15px",
    width: 520,
    padding: 30,
    paddingTop: 40,
    textAlign: "left",
    "@media (max-width:960px)": {
      width: "100%",
    },
  },
  iconButton: {
    position: "absolute",
    top: 0,
    right: 0,
    color: theme.palette.secondary.main,
  },
}));
