import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import CustomButton from "../../../../components/Button";
import CustomModal from "../../../../components/Modal";
import { SERVICE_PROVIDER_COMPLETE } from "../../../../utils/constants";
import {
  ROUTE_FREELANCE_ADMINISTRATIVE,
  ROUTE_FREELANCE_PROFILE,
} from "../../../../utils/routeConstants";

const CompleteProfileModal = ({ open, onClose, serviceProvider }) => {
  const history = useHistory();
  const { t } = useTranslation("serviceProvider");
  return (
    <CustomModal open={open} handleClose={onClose}>
      <Grid>
        <Box py="20px">
          <Typography variant="h1">{t("completeProfile.title")}</Typography>
        </Box>
        {!serviceProvider?.isProfileCompleted && (
          <>
            <Box pb="5px">
              <Typography variant="h2">
                {t("completeProfile.subtitleProfile")}
              </Typography>
            </Box>
            <Box pb="10px">
              <Typography variant="body1">
                {t("completeProfile.contentProfile")}
              </Typography>
            </Box>
            <CustomButton
              style={{ margin: 0 }}
              theme="filledButton"
              title={t("completeProfile.complateProfileButton")}
              onClick={() => history.push(ROUTE_FREELANCE_PROFILE)}
            />
          </>
        )}
        {!serviceProvider?.isProfileCompleted &&
          serviceProvider?.status !== SERVICE_PROVIDER_COMPLETE && (
            <Box py="20px" />
          )}
        {serviceProvider?.status !== SERVICE_PROVIDER_COMPLETE && (
          <>
            <Box pb="5px">
              <Typography variant="h2">
                {t("completeProfile.subtitleAdministrativeProfile")}
              </Typography>
            </Box>
            <Box pb="10px">
              <Typography variant="body1">
                {t("completeProfile.contentAdministrativeProfile")}
              </Typography>
            </Box>
            <CustomButton
              style={{ margin: 0 }}
              theme="filledButton"
              title={t("completeProfile.complateAdministrativeProfileButton")}
              onClick={() => history.push(ROUTE_FREELANCE_ADMINISTRATIVE)}
            />
          </>
        )}
      </Grid>
    </CustomModal>
  );
};

export default CompleteProfileModal;
