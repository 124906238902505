import { useQueryClient } from "react-query";

// eslint-disable-next-line import/extensions,import/no-unresolved
import { useUpdateServiceProviderProfileMutation } from "../../generates";
import { client } from "../../libs/graphqlRequest";

export default function useServiceProviderMutation() {
  const queryClient = useQueryClient();

  return useUpdateServiceProviderProfileMutation(client, {
    onSuccess: () => {
      queryClient.invalidateQueries(["GetServiceProvider"]);
    },
  });
}
