import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  loadingContainer: {
    height: 200,
  },
  container: {
    flexGrow: 1,
  },
  sectionContainer: {
    padding: "40px 0",
  },
  gridRightDashboard: {
    borderRadius: "0 15px 15px 0",
    width: 120,
    backgroundColor: `${theme.palette.primary.main}`,
    "&:hover": {
      cursor: "pointer",
    },
    display: "flex",
    textDecoration: "none",
    padding: "1rem",
    textAlign: "center",
    "@media (max-width:960px)": {
      width: "100%",
    },
  },
  button: {
    fontSize: "14px !important",
    fontFamily: "Basier Medium !important",
    color: `${theme.palette.secondary.black} !important`,
  },
  title: {
    marginBottom: 10,
  },
  titleDashboard: {
    backgroundColor: "#283028",
    borderRadius: "15px 0  0 15px",
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("lg")]: {
      borderRadius: 15,
    },
  },
  innerTile: {
    margin: "10px",
    display: "flex",
    flexWrap: "wrap",
  },
  toolboxAcracy: {
    borderRadius: 1,
    border: "solid 2 #ffffff",
    display: "flex",
    alignItems: "center",
  },
  toolboxContent: {
    flex: "1 1 60%",
  },
  contactAcracy: {
    borderRadius: "15px",
    backgroundColor: `${theme.palette.primary.main}`,
    "&:hover": {
      cursor: "pointer",
    },
    padding: "1rem",
    textAlign: "center",
  },
  titleContact: {
    fontSize: 16,
  },
  phoneCallImg: {
    width: "87px",
    height: "86px",
  },
  profileReviewing: {
    fontWeight: "bold",
  },
  clappingHandsImg: {
    width: "61px",
  },
  layout: {
    marginTop: theme.navbar.height,
  },
  briefsWrapper: {
    position: "relative",
  },
  toInvoicesButton: {
    textDecoration: "none",
  },
  toInvoicesButtonImg: {
    marginLeft: "1rem",
    transform: "rotate(-90deg)",
  },
  contact: {},
  tilesButton: {
    width: "100%",
    borderRadius: "0 0 15px 15px",
  },
  emptyContainer: {
    background: theme.palette.secondary.mid,
    borderRadius: 8,
    padding: "15px 0",
    "@media (max-width:400px)": {},
  },
}));
