import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  container: {
    alignItems: "center",
    backgroundColor: "#ecf805",
    bottom: 0,
    color: "#162217",
    display: "flex",
    height: "104px",
    padding: "0 50px",
    position: "fixed",
    width: "inherit",
    "@media (max-width:1279px)": {
      padding: "0 12px",
    },
  },
  leftBox: {
    color: "#162217",
    // flex: 2,
    "@media (max-width:579px)": {
      display: "none",
    },
  },
  text: {
    fontSize: 22,
    lineHeight: 1.27,
    fontWeight: 100,
    letterSpacing: "normal",
    color: "#162217",
  },
  rightBox: {
    display: "flex",
    // flex: 1,
    justifyContent: "flex-end",
    marginLeft: "auto",
    "@media (max-width:579px)": {
      justifyContent: "center",
    },
  },
  button: {
    border: "1px solid #162217",
    display: 1,
  },
}));
