import { fade } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: 16,
    },
  },
  panel: {
    background: `${fade(theme.palette.secondary.medium, 0.1)}`,
    border: `1px solid ${theme.palette.secondary.medium}`,
  },
  panelWithoutBorder: {
    background: theme.palette.secondary.dark,
  },
  header: {
    display: "flex",
    alignItems: "center",
    margin: "0 5px",
    minHeight: 64,
    "& .MuiExpansionPanelSummary-expandIcon": {
      transform: "rotate(180deg)",
    },
  },
  detailsContainer: {
    borderTop: `1px solid ${theme.palette.secondary.medium}`,
  },
  detailsContainerInvoice: {
    "&::-webkit-scrollbar": {
      height: 6,
    },
    "&::-webkit-scrollbar-track": {
      background: `${fade(theme.palette.secondary.dark, 0.6)}`,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: 16,
    },
    "@media (max-width:960px)": {
      overflowX: "auto",
      flexWrap: "unset !important",
      maxWidth: "90vw",
      overflowStyle: "none", // IE scrollbar
      scrollbarWidth: "thin", // Firefox scrollbar
      scrollbarColor: `${theme.palette.primary.main} ${fade(
        theme.palette.secondary.dark,
        0.6
      )}`, // Firefox scrollbar
    },
  },
  invoiceTitle: {
    color: theme.palette.primary.main,
    padding: "18px 0 18px 29px",
  },
  revealText: {
    color: "#fff",
    fontSize: 17,
    letterSpacing: "-0.45px",
    lineHeight: 1.41,
    margin: "10px 10px 20px",
  },
  arrowIcon: {
    border: `1px solid ${theme.palette.secondary.medium}`,
    padding: ".2rem",
    borderRadius: 50,
    transform: "scale(1.5)",
    fill: theme.palette.secondary.light,
  },
  addIcon: {
    fill: theme.palette.secondary.light,
  },
}));
