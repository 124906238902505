import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  darkWrapper: {
    background: theme.palette.secondary.dark,
    borderRadius: 15,
    padding: "15px",
    marginBottom: "15px",
    position: "relative",
  },
  bleed: {
    transform: "translate(-1rem) !important",
    width: "calc(100% + 2rem)",
  },
}));
