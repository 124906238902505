import { GraphQLClient } from 'graphql-request';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any;
  /** The string representation of JavaScript regexp. You may provide it with flags "/^abc.*\/i" or without flags like "^abc.*". More info about RegExp characters and flags: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions */
  RegExpAsString: any;
};

export type Admin = {
  __typename?: 'Admin';
  id?: Maybe<Scalars['MongoID']>;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  phone?: Maybe<Phone>;
  email: Scalars['String'];
  permissions?: Maybe<Array<Maybe<EnumAdminPermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<AdminTasks>;
  slackId?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  calendarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type AdminTasks = {
  __typename?: 'AdminTasks';
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type AdminTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type Algolia = {
  __typename?: 'Algolia';
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type AlgoliaInput = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type AlgoliaObject = {
  __typename?: 'AlgoliaObject';
  id?: Maybe<Scalars['MongoID']>;
  externalId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Maybe<Scalars['String']>>>;
  text?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expertises?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  id?: Maybe<Scalars['MongoID']>;
  authorizer?: Maybe<AttachmentAuthorizer>;
  critical?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  type?: Maybe<EnumAttachmentType>;
  filename?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type AttachmentAuthorizer = {
  __typename?: 'AttachmentAuthorizer';
  company?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AttachmentAuthorizerInput = {
  company?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Brief = {
  __typename?: 'Brief';
  id?: Maybe<Scalars['MongoID']>;
  company?: Maybe<Company>;
  employee?: Maybe<Employee>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumBriefPermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<BriefTasks>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  search?: Maybe<Search>;
  desireds?: Maybe<Array<Maybe<Search>>>;
  briefSummary?: Maybe<ServiceProviderAdministrativeProfileLegalDocuments>;
  deliverables?: Maybe<Array<Maybe<Algolia>>>;
  matchDeliverables?: Maybe<Array<Maybe<Algolia>>>;
  matchProfile?: Maybe<Algolia>;
  matchProfiles?: Maybe<Array<Maybe<Algolia>>>;
  missionContext?: Maybe<MissionContext>;
  missionDetail?: Maybe<MissionDetail>;
  missionRequirements?: Maybe<MissionRequirements>;
  profile?: Maybe<Algolia>;
  profiles?: Maybe<Array<Maybe<Algolia>>>;
  acracyRecommandation?: Maybe<Scalars['String']>;
  serviceProviderRecommendation?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  nbQuoteToAccept?: Maybe<Scalars['Float']>;
  status?: Maybe<EnumBriefStatus>;
  revealSentAt?: Maybe<Scalars['Date']>;
  dateStartMatching?: Maybe<Scalars['Date']>;
  isImported?: Maybe<Scalars['Boolean']>;
  timeSpent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  slackUrl?: Maybe<Scalars['String']>;
  communityAdmins?: Maybe<Array<Maybe<Admin>>>;
  salesAdmins?: Maybe<Array<Maybe<Admin>>>;
};

export type BriefAlgoResult = {
  __typename?: 'BriefAlgoResult';
  id?: Maybe<Scalars['MongoID']>;
  brief?: Maybe<Brief>;
  serviceProvider?: Maybe<ServiceProvider>;
  comparisonOfAverageDailyRate?: Maybe<EnumBriefAlgoResultComparisonOfAverageDailyRate>;
  name?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  lastStep?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<BriefAlgoResultSteps>>>;
  version?: Maybe<Scalars['Float']>;
  nbMissions?: Maybe<Scalars['Float']>;
  isQuoteCreated?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type BriefAlgoResultSteps = {
  __typename?: 'BriefAlgoResultSteps';
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  maxScore?: Maybe<Scalars['Float']>;
  isImportant?: Maybe<Scalars['Boolean']>;
};

export type BriefAlgoResultStepsInput = {
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  maxScore?: Maybe<Scalars['Float']>;
  isImportant?: Maybe<Scalars['Boolean']>;
};

export type BriefTasks = {
  __typename?: 'BriefTasks';
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type BriefTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  id?: Maybe<Scalars['MongoID']>;
  admin?: Maybe<Admin>;
  employee?: Maybe<Employee>;
  serviceProvider?: Maybe<ServiceProvider>;
  message?: Maybe<Scalars['String']>;
  model?: Maybe<CommentModel>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CommentModel = {
  __typename?: 'CommentModel';
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type CommentModelInput = {
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type Company = {
  __typename?: 'Company';
  id?: Maybe<Scalars['MongoID']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<CompanyTasks>;
  organization?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  billingComment?: Maybe<Scalars['String']>;
  idSource?: Maybe<Scalars['String']>;
  idStripe?: Maybe<Scalars['String']>;
  inviteUri?: Maybe<Scalars['String']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
  status?: Maybe<EnumCompanyStatus>;
  employees?: Maybe<Array<Maybe<Employee>>>;
  allowedServiceProviders?: Maybe<Array<Maybe<CompanyAllowedServiceProviders>>>;
  stripeBillingSourceId?: Maybe<Scalars['String']>;
  administrativeProfile?: Maybe<CompanyAdministrativeProfile>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  subscription?: Maybe<CompanySubscription>;
  parentCompany?: Maybe<Company>;
  isValidatingInvoices?: Maybe<Scalars['Boolean']>;
  paymentDeadlinePolicy?: Maybe<EnumCompanyPaymentDeadlinePolicy>;
  invoicePlatformLink?: Maybe<Scalars['String']>;
  invoicePlatformLogin?: Maybe<Scalars['String']>;
  invoicePlatformPassword?: Maybe<Scalars['String']>;
  heardAboutUs?: Maybe<HeardAboutUs>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  stripeCustomerUrl?: Maybe<Scalars['String']>;
  inviteUrl?: Maybe<Scalars['String']>;
};

export type CompanyAdministrativeProfile = {
  __typename?: 'CompanyAdministrativeProfile';
  intraCommunityVAT?: Maybe<Scalars['Boolean']>;
  cityOfRcsRegistration?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  billing?: Maybe<CompanyAdministrativeProfileBilling>;
  headOffice?: Maybe<CompanyAdministrativeProfileHeadOffice>;
  sameAddress?: Maybe<Scalars['Boolean']>;
  shareCapital?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['Boolean']>;
  InternationalBankAccountNumber?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  legalForm?: Maybe<Scalars['String']>;
  cguCheck?: Maybe<Scalars['Boolean']>;
};

export type CompanyAdministrativeProfileBilling = {
  __typename?: 'CompanyAdministrativeProfileBilling';
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone?: Maybe<CompanyAdministrativeProfileBillingPhone>;
  email?: Maybe<Scalars['String']>;
  accountingEmail?: Maybe<Scalars['String']>;
};

export type CompanyAdministrativeProfileBillingInput = {
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone?: Maybe<CompanyAdministrativeProfileBillingPhoneInput>;
  email?: Maybe<Scalars['String']>;
  accountingEmail?: Maybe<Scalars['String']>;
};

export type CompanyAdministrativeProfileBillingPhone = {
  __typename?: 'CompanyAdministrativeProfileBillingPhone';
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type CompanyAdministrativeProfileBillingPhoneInput = {
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type CompanyAdministrativeProfileHeadOffice = {
  __typename?: 'CompanyAdministrativeProfileHeadOffice';
  country?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type CompanyAdministrativeProfileHeadOfficeInput = {
  country?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type CompanyAdministrativeProfileInput = {
  intraCommunityVAT?: Maybe<Scalars['Boolean']>;
  cityOfRcsRegistration?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  billing?: Maybe<CompanyAdministrativeProfileBillingInput>;
  headOffice?: Maybe<CompanyAdministrativeProfileHeadOfficeInput>;
  sameAddress?: Maybe<Scalars['Boolean']>;
  shareCapital?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['Boolean']>;
  InternationalBankAccountNumber?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  legalForm?: Maybe<Scalars['String']>;
  cguCheck?: Maybe<Scalars['Boolean']>;
};

export type CompanyAllowedServiceProviders = {
  __typename?: 'CompanyAllowedServiceProviders';
  isRevealAllowed?: Maybe<Scalars['Boolean']>;
  isBriefAllowed?: Maybe<Scalars['Boolean']>;
  isEmailSent?: Maybe<Scalars['Boolean']>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  serviceProvider?: Maybe<ServiceProvider>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type CompanyAllowedServiceProvidersInput = {
  isRevealAllowed?: Maybe<Scalars['Boolean']>;
  isBriefAllowed?: Maybe<Scalars['Boolean']>;
  isEmailSent?: Maybe<Scalars['Boolean']>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type CompanySubscription = {
  __typename?: 'CompanySubscription';
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  subscribedAt?: Maybe<Scalars['Date']>;
  cancelledAt?: Maybe<Scalars['Date']>;
  paymentDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type CompanySubscriptionInput = {
  subscriptionPlan?: Maybe<Scalars['MongoID']>;
  subscribedAt?: Maybe<Scalars['Date']>;
  cancelledAt?: Maybe<Scalars['Date']>;
  paymentDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type CompanyTasks = {
  __typename?: 'CompanyTasks';
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type CompanyTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  defaultCommissionRate?: Maybe<Scalars['Float']>;
  defaultMaxFileSize?: Maybe<Scalars['Int']>;
  briefMaxFileSize?: Maybe<Scalars['Int']>;
  countries?: Maybe<Array<Maybe<CountryConfiguration>>>;
  allowedImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedDocuments?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Count = {
  __typename?: 'Count';
  count?: Maybe<Scalars['Int']>;
};

export type CountryConfiguration = {
  __typename?: 'CountryConfiguration';
  countryLabel?: Maybe<Scalars['String']>;
  countryValue?: Maybe<Scalars['String']>;
  identityDocuments?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyDocuments?: Maybe<Array<Maybe<Scalars['String']>>>;
  legalForms?: Maybe<Scalars['JSON']>;
};


export type Deliverable = {
  __typename?: 'Deliverable';
  priority?: Maybe<Scalars['Boolean']>;
  deliverable?: Maybe<Algolia>;
};

export type DeliverableInput = {
  priority?: Maybe<Scalars['Boolean']>;
  deliverable?: Maybe<AlgoliaInput>;
};

export type DocumentHub = {
  __typename?: 'DocumentHub';
  id?: Maybe<Scalars['MongoID']>;
  model?: Maybe<DocumentHubModel>;
  folders?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type DocumentHubModel = {
  __typename?: 'DocumentHubModel';
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type DocumentHubModelInput = {
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  arguments?: Maybe<Scalars['JSON']>;
};

export type Employee = {
  __typename?: 'Employee';
  id?: Maybe<Scalars['MongoID']>;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  phone?: Maybe<Phone>;
  email: Scalars['String'];
  permissions?: Maybe<Array<Maybe<EnumEmployeePermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<EmployeeTasks>;
  company?: Maybe<Company>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmployeeStatus>;
  heardAboutUs?: Maybe<HeardAboutUs>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type EmployeeTasks = {
  __typename?: 'EmployeeTasks';
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type EmployeeTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export enum EnumAdminPermissions {
  CompanySupervisor = 'COMPANY_SUPERVISOR',
  CompanyAccessParent = 'COMPANY_ACCESS_PARENT',
  ServiceProviderProjectManager = 'SERVICE_PROVIDER_PROJECT_MANAGER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  SubscriptionAllowFastBrief = 'SUBSCRIPTION_ALLOW_FAST_BRIEF',
  SubscriptionAllowCustomPool = 'SUBSCRIPTION_ALLOW_CUSTOM_POOL',
  SubscriptionAllowDashboard = 'SUBSCRIPTION_ALLOW_DASHBOARD',
  SubscriptionHasDedicatedAccountManager = 'SUBSCRIPTION_HAS_DEDICATED_ACCOUNT_MANAGER'
}

export enum EnumAttachmentType {
  BriefSummary = 'BRIEF_SUMMARY',
  CompanyLegalDocument = 'COMPANY_LEGAL_DOCUMENT',
  Invoice = 'INVOICE',
  MissionSharedDocument = 'MISSION_SHARED_DOCUMENT',
  PurchaseOrder = 'PURCHASE_ORDER',
  ServiceProviderLegalDocument = 'SERVICE_PROVIDER_LEGAL_DOCUMENT',
  ServiceProviderPortfolioUpload = 'SERVICE_PROVIDER_PORTFOLIO_UPLOAD',
  SignedQuote = 'SIGNED_QUOTE',
  AcracyContract = 'ACRACY_CONTRACT',
  ServiceProviderAvatarUpload = 'SERVICE_PROVIDER_AVATAR_UPLOAD'
}

export enum EnumBriefAlgoResultComparisonOfAverageDailyRate {
  ToNegotiate = 'TO_NEGOTIATE',
  InYourPrices = 'IN_YOUR_PRICES',
  WeAreWide = 'WE_ARE_WIDE'
}

export enum EnumBriefPermissions {
  CompanySupervisor = 'COMPANY_SUPERVISOR',
  CompanyAccessParent = 'COMPANY_ACCESS_PARENT',
  ServiceProviderProjectManager = 'SERVICE_PROVIDER_PROJECT_MANAGER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  SubscriptionAllowFastBrief = 'SUBSCRIPTION_ALLOW_FAST_BRIEF',
  SubscriptionAllowCustomPool = 'SUBSCRIPTION_ALLOW_CUSTOM_POOL',
  SubscriptionAllowDashboard = 'SUBSCRIPTION_ALLOW_DASHBOARD',
  SubscriptionHasDedicatedAccountManager = 'SUBSCRIPTION_HAS_DEDICATED_ACCOUNT_MANAGER'
}

export enum EnumBriefStatus {
  Draft = 'DRAFT',
  HelpNeeded = 'HELP_NEEDED',
  Deleted = 'DELETED',
  Finalized = 'FINALIZED',
  Hidden = 'HIDDEN',
  Refused = 'REFUSED',
  InProgress = 'IN_PROGRESS',
  WaitingForAcceptance = 'WAITING_FOR_ACCEPTANCE',
  WaitingForMatching = 'WAITING_FOR_MATCHING',
  MatchingInProgress = 'MATCHING_IN_PROGRESS',
  WaitingForQuotes = 'WAITING_FOR_QUOTES',
  WaitingForCustomerSelection = 'WAITING_FOR_CUSTOMER_SELECTION',
  Closed = 'CLOSED',
  Abandoned = 'ABANDONED'
}

export enum EnumCompanyPaymentDeadlinePolicy {
  Days_30 = 'DAYS_30',
  Days_60 = 'DAYS_60',
  Days_45EndOfMonth = 'DAYS_45_END_OF_MONTH'
}

export enum EnumCompanyStatus {
  WaitingEligibility = 'WAITING_ELIGIBILITY',
  Complete = 'COMPLETE',
  RefusedEligibility = 'REFUSED_ELIGIBILITY',
  Deleted = 'DELETED'
}

export enum EnumEmployeePermissions {
  CompanySupervisor = 'COMPANY_SUPERVISOR',
  CompanyAccessParent = 'COMPANY_ACCESS_PARENT',
  ServiceProviderProjectManager = 'SERVICE_PROVIDER_PROJECT_MANAGER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  SubscriptionAllowFastBrief = 'SUBSCRIPTION_ALLOW_FAST_BRIEF',
  SubscriptionAllowCustomPool = 'SUBSCRIPTION_ALLOW_CUSTOM_POOL',
  SubscriptionAllowDashboard = 'SUBSCRIPTION_ALLOW_DASHBOARD',
  SubscriptionHasDedicatedAccountManager = 'SUBSCRIPTION_HAS_DEDICATED_ACCOUNT_MANAGER'
}

export enum EnumEmployeeStatus {
  WaitingEligibility = 'WAITING_ELIGIBILITY',
  RefusedEligibility = 'REFUSED_ELIGIBILITY',
  Deleted = 'DELETED',
  Complete = 'COMPLETE'
}

export enum EnumIdentityProviderType {
  Cognito = 'cognito',
  Linkedin = 'linkedin'
}

export enum EnumIdentityType {
  Company = 'company',
  ServiceProvider = 'serviceProvider',
  Admin = 'admin'
}

export enum EnumInvoiceAddressedTo {
  Company = 'COMPANY',
  ServiceProvider = 'SERVICE_PROVIDER',
  Ourselves = 'OURSELVES'
}

export enum EnumInvoicePermissions {
  CompanySupervisor = 'COMPANY_SUPERVISOR',
  CompanyAccessParent = 'COMPANY_ACCESS_PARENT',
  ServiceProviderProjectManager = 'SERVICE_PROVIDER_PROJECT_MANAGER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  SubscriptionAllowFastBrief = 'SUBSCRIPTION_ALLOW_FAST_BRIEF',
  SubscriptionAllowCustomPool = 'SUBSCRIPTION_ALLOW_CUSTOM_POOL',
  SubscriptionAllowDashboard = 'SUBSCRIPTION_ALLOW_DASHBOARD',
  SubscriptionHasDedicatedAccountManager = 'SUBSCRIPTION_HAS_DEDICATED_ACCOUNT_MANAGER'
}

export enum EnumInvoiceStatus {
  WaitingForValidation = 'WAITING_FOR_VALIDATION',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  Paid = 'PAID',
  RefusedValidation = 'REFUSED_VALIDATION'
}

export enum EnumNotificationType {
  QuoteWaitingForPurchaseOrder = 'QUOTE_WAITING_FOR_PURCHASE_ORDER'
}

export enum EnumQuotePermissions {
  CompanySupervisor = 'COMPANY_SUPERVISOR',
  CompanyAccessParent = 'COMPANY_ACCESS_PARENT',
  ServiceProviderProjectManager = 'SERVICE_PROVIDER_PROJECT_MANAGER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  SubscriptionAllowFastBrief = 'SUBSCRIPTION_ALLOW_FAST_BRIEF',
  SubscriptionAllowCustomPool = 'SUBSCRIPTION_ALLOW_CUSTOM_POOL',
  SubscriptionAllowDashboard = 'SUBSCRIPTION_ALLOW_DASHBOARD',
  SubscriptionHasDedicatedAccountManager = 'SUBSCRIPTION_HAS_DEDICATED_ACCOUNT_MANAGER'
}

export enum EnumQuoteStatus {
  WaitingForServiceprovider = 'WAITING_FOR_SERVICEPROVIDER',
  WaitingForPurchaseOrder = 'WAITING_FOR_PURCHASE_ORDER',
  ServiceproviderRefused = 'SERVICEPROVIDER_REFUSED',
  WaitingForCustomer = 'WAITING_FOR_CUSTOMER',
  CustomerRefused = 'CUSTOMER_REFUSED',
  Accepted = 'ACCEPTED',
  Abandoned = 'ABANDONED',
  Expired = 'EXPIRED'
}

export enum EnumServiceProviderCurentSituation {
  InOffice = 'IN_OFFICE',
  Freelance = 'FREELANCE'
}

export enum EnumServiceProviderDuration {
  Short = 'SHORT',
  Medium = 'MEDIUM',
  Long = 'LONG'
}

export enum EnumServiceProviderFormat {
  Whatever = 'WHATEVER',
  RemoteOnly = 'REMOTE_ONLY',
  InplaceOnly = 'INPLACE_ONLY',
  Both = 'BOTH'
}

export enum EnumServiceProviderLanguages {
  FluentEnglish = 'FLUENT_ENGLISH',
  NativeEnglish = 'NATIVE_ENGLISH',
  FluentFrench = 'FLUENT_FRENCH',
  NativeFrench = 'NATIVE_FRENCH',
  FluentGerman = 'FLUENT_GERMAN',
  NativeGerman = 'NATIVE_GERMAN',
  FluentSpanish = 'FLUENT_SPANISH',
  NativeSpanish = 'NATIVE_SPANISH',
  FluentItalian = 'FLUENT_ITALIAN',
  NativeItalian = 'NATIVE_ITALIAN'
}

export enum EnumServiceProviderPermissions {
  CompanySupervisor = 'COMPANY_SUPERVISOR',
  CompanyAccessParent = 'COMPANY_ACCESS_PARENT',
  ServiceProviderProjectManager = 'SERVICE_PROVIDER_PROJECT_MANAGER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  SubscriptionAllowFastBrief = 'SUBSCRIPTION_ALLOW_FAST_BRIEF',
  SubscriptionAllowCustomPool = 'SUBSCRIPTION_ALLOW_CUSTOM_POOL',
  SubscriptionAllowDashboard = 'SUBSCRIPTION_ALLOW_DASHBOARD',
  SubscriptionHasDedicatedAccountManager = 'SUBSCRIPTION_HAS_DEDICATED_ACCOUNT_MANAGER'
}

export enum EnumServiceProviderSeniority {
  Junior = 'JUNIOR',
  Senior = 'SENIOR',
  Middle = 'MIDDLE',
  Expert = 'EXPERT',
  Whatever = 'WHATEVER',
  Guru = 'GURU'
}

export enum EnumSubscriptionPlanPermissions {
  CompanySupervisor = 'COMPANY_SUPERVISOR',
  CompanyAccessParent = 'COMPANY_ACCESS_PARENT',
  ServiceProviderProjectManager = 'SERVICE_PROVIDER_PROJECT_MANAGER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  SubscriptionAllowFastBrief = 'SUBSCRIPTION_ALLOW_FAST_BRIEF',
  SubscriptionAllowCustomPool = 'SUBSCRIPTION_ALLOW_CUSTOM_POOL',
  SubscriptionAllowDashboard = 'SUBSCRIPTION_ALLOW_DASHBOARD',
  SubscriptionHasDedicatedAccountManager = 'SUBSCRIPTION_HAS_DEDICATED_ACCOUNT_MANAGER'
}

export enum EnumSubscriptionPlanType {
  Base = 'BASE',
  Core = 'CORE',
  Team = 'TEAM'
}

export type ErrorInterface = {
  /** Generic error message */
  message?: Maybe<Scalars['String']>;
};

export type Expertise = {
  __typename?: 'Expertise';
  id?: Maybe<Scalars['MongoID']>;
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type FilterFindManyAdminCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyAdminDepartmentOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyAdminEmailOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyAdminInput = {
  id?: Maybe<Scalars['MongoID']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phone?: Maybe<FilterFindManyPhoneInput>;
  email?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<EnumAdminPermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<FilterFindManyAdminTasksInput>;
  slackId?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  calendarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyAdminOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyAdminInput>>;
  AND?: Maybe<Array<FilterFindManyAdminInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyAdminOperatorsInput = {
  email?: Maybe<FilterFindManyAdminEmailOperatorsInput>;
  department?: Maybe<FilterFindManyAdminDepartmentOperatorsInput>;
  createdAt?: Maybe<FilterFindManyAdminCreatedAtOperatorsInput>;
};

export type FilterFindManyAdminTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type FilterFindManyAlgoliaInput = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type FilterFindManyAlgoliaObjectCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyAlgoliaObjectExternalIdOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyAlgoliaObjectInput = {
  id?: Maybe<Scalars['MongoID']>;
  externalId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Maybe<Scalars['String']>>>;
  text?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expertises?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyAlgoliaObjectOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyAlgoliaObjectInput>>;
  AND?: Maybe<Array<FilterFindManyAlgoliaObjectInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyAlgoliaObjectOperatorsInput = {
  externalId?: Maybe<FilterFindManyAlgoliaObjectExternalIdOperatorsInput>;
  createdAt?: Maybe<FilterFindManyAlgoliaObjectCreatedAtOperatorsInput>;
};

export type FilterFindManyAttachmentAuthorizerInput = {
  company?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyAttachmentCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyAttachmentInput = {
  id?: Maybe<Scalars['MongoID']>;
  authorizer?: Maybe<FilterFindManyAttachmentAuthorizerInput>;
  critical?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  type?: Maybe<EnumAttachmentType>;
  filename?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyAttachmentOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyAttachmentInput>>;
  AND?: Maybe<Array<FilterFindManyAttachmentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyAttachmentOperatorsInput = {
  createdAt?: Maybe<FilterFindManyAttachmentCreatedAtOperatorsInput>;
};

export type FilterFindManyBriefAlgoResultCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyBriefAlgoResultInput = {
  id?: Maybe<Scalars['MongoID']>;
  brief?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  comparisonOfAverageDailyRate?: Maybe<EnumBriefAlgoResultComparisonOfAverageDailyRate>;
  name?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  lastStep?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<FilterFindManyBriefAlgoResultStepsInput>>>;
  version?: Maybe<Scalars['Float']>;
  nbMissions?: Maybe<Scalars['Float']>;
  isQuoteCreated?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyBriefAlgoResultOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyBriefAlgoResultInput>>;
  AND?: Maybe<Array<FilterFindManyBriefAlgoResultInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyBriefAlgoResultOperatorsInput = {
  score?: Maybe<FilterFindManyBriefAlgoResultScoreOperatorsInput>;
  createdAt?: Maybe<FilterFindManyBriefAlgoResultCreatedAtOperatorsInput>;
};

export type FilterFindManyBriefAlgoResultScoreOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyBriefAlgoResultStepsInput = {
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  maxScore?: Maybe<Scalars['Float']>;
  isImportant?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyBriefCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyBriefInput = {
  id?: Maybe<Scalars['MongoID']>;
  company?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumBriefPermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<FilterFindManyBriefTasksInput>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  search?: Maybe<FilterFindManySearchInput>;
  desireds?: Maybe<Array<Maybe<FilterFindManySearchInput>>>;
  briefSummary?: Maybe<FilterFindManyServiceProviderAdministrativeProfileLegalDocumentsInput>;
  deliverables?: Maybe<Array<Maybe<FilterFindManyAlgoliaInput>>>;
  matchDeliverables?: Maybe<Array<Maybe<FilterFindManyAlgoliaInput>>>;
  matchProfile?: Maybe<FilterFindManyAlgoliaInput>;
  matchProfiles?: Maybe<Array<Maybe<FilterFindManyAlgoliaInput>>>;
  missionContext?: Maybe<FilterFindManyMissionContextInput>;
  missionDetail?: Maybe<FilterFindManyMissionDetailInput>;
  missionRequirements?: Maybe<FilterFindManyMissionRequirementsInput>;
  profile?: Maybe<FilterFindManyAlgoliaInput>;
  profiles?: Maybe<Array<Maybe<FilterFindManyAlgoliaInput>>>;
  acracyRecommandation?: Maybe<Scalars['String']>;
  serviceProviderRecommendation?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nbQuoteToAccept?: Maybe<Scalars['Float']>;
  status?: Maybe<EnumBriefStatus>;
  revealSentAt?: Maybe<Scalars['Date']>;
  dateStartMatching?: Maybe<Scalars['Date']>;
  isImported?: Maybe<Scalars['Boolean']>;
  timeSpent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyBriefOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyBriefInput>>;
  AND?: Maybe<Array<FilterFindManyBriefInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyBriefOperatorsInput = {
  status?: Maybe<FilterFindManyBriefStatusOperatorsInput>;
  createdAt?: Maybe<FilterFindManyBriefCreatedAtOperatorsInput>;
};

export type FilterFindManyBriefStatusOperatorsInput = {
  gt?: Maybe<EnumBriefStatus>;
  gte?: Maybe<EnumBriefStatus>;
  lt?: Maybe<EnumBriefStatus>;
  lte?: Maybe<EnumBriefStatus>;
  ne?: Maybe<EnumBriefStatus>;
  in?: Maybe<Array<Maybe<EnumBriefStatus>>>;
  nin?: Maybe<Array<Maybe<EnumBriefStatus>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyBriefTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type FilterFindManyCommentAdminOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCommentInput = {
  id?: Maybe<Scalars['MongoID']>;
  admin?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  message?: Maybe<Scalars['String']>;
  model?: Maybe<FilterFindManyCommentModelInput>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyCommentOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyCommentInput>>;
  AND?: Maybe<Array<FilterFindManyCommentInput>>;
};

export type FilterFindManyCommentMessageOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCommentModelInput = {
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyCommentOperatorsInput = {
  admin?: Maybe<FilterFindManyCommentAdminOperatorsInput>;
  message?: Maybe<FilterFindManyCommentMessageOperatorsInput>;
};

export type FilterFindManyCompanyAdministrativeProfileBillingInput = {
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone?: Maybe<FilterFindManyCompanyAdministrativeProfileBillingPhoneInput>;
  email?: Maybe<Scalars['String']>;
  accountingEmail?: Maybe<Scalars['String']>;
};

export type FilterFindManyCompanyAdministrativeProfileBillingPhoneInput = {
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type FilterFindManyCompanyAdministrativeProfileHeadOfficeInput = {
  country?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type FilterFindManyCompanyAdministrativeProfileInput = {
  intraCommunityVAT?: Maybe<Scalars['Boolean']>;
  cityOfRcsRegistration?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  billing?: Maybe<FilterFindManyCompanyAdministrativeProfileBillingInput>;
  headOffice?: Maybe<FilterFindManyCompanyAdministrativeProfileHeadOfficeInput>;
  sameAddress?: Maybe<Scalars['Boolean']>;
  shareCapital?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['Boolean']>;
  InternationalBankAccountNumber?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  legalForm?: Maybe<Scalars['String']>;
  cguCheck?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCompanyAllowedServiceProvidersInput = {
  isRevealAllowed?: Maybe<Scalars['Boolean']>;
  isBriefAllowed?: Maybe<Scalars['Boolean']>;
  isEmailSent?: Maybe<Scalars['Boolean']>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type FilterFindManyCompanyCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCompanyInput = {
  id?: Maybe<Scalars['MongoID']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<FilterFindManyCompanyTasksInput>;
  organization?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  billingComment?: Maybe<Scalars['String']>;
  idSource?: Maybe<Scalars['String']>;
  idStripe?: Maybe<Scalars['String']>;
  inviteUri?: Maybe<Scalars['String']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
  status?: Maybe<EnumCompanyStatus>;
  employees?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  allowedServiceProviders?: Maybe<Array<Maybe<FilterFindManyCompanyAllowedServiceProvidersInput>>>;
  stripeBillingSourceId?: Maybe<Scalars['String']>;
  administrativeProfile?: Maybe<FilterFindManyCompanyAdministrativeProfileInput>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  subscription?: Maybe<FilterFindManyCompanySubscriptionInput>;
  parentCompany?: Maybe<Scalars['MongoID']>;
  isValidatingInvoices?: Maybe<Scalars['Boolean']>;
  paymentDeadlinePolicy?: Maybe<EnumCompanyPaymentDeadlinePolicy>;
  invoicePlatformLink?: Maybe<Scalars['String']>;
  invoicePlatformLogin?: Maybe<Scalars['String']>;
  invoicePlatformPassword?: Maybe<Scalars['String']>;
  heardAboutUs?: Maybe<FilterFindManyHeardAboutUsInput>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyCompanyOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyCompanyInput>>;
  AND?: Maybe<Array<FilterFindManyCompanyInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyCompanyOperatorsInput = {
  createdAt?: Maybe<FilterFindManyCompanyCreatedAtOperatorsInput>;
};

export type FilterFindManyCompanySubscriptionInput = {
  subscriptionPlan?: Maybe<Scalars['MongoID']>;
  subscribedAt?: Maybe<Scalars['Date']>;
  cancelledAt?: Maybe<Scalars['Date']>;
  paymentDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type FilterFindManyCompanyTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type FilterFindManyDeliverableInput = {
  priority?: Maybe<Scalars['Boolean']>;
  deliverable?: Maybe<FilterFindManyAlgoliaInput>;
};

export type FilterFindManyDocumentHubInput = {
  id?: Maybe<Scalars['MongoID']>;
  model?: Maybe<FilterFindManyDocumentHubModelInput>;
  folders?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  OR?: Maybe<Array<FilterFindManyDocumentHubInput>>;
  AND?: Maybe<Array<FilterFindManyDocumentHubInput>>;
};

export type FilterFindManyDocumentHubModelInput = {
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyEmployeeCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyEmployeeEmailOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyEmployeeInput = {
  id?: Maybe<Scalars['MongoID']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phone?: Maybe<FilterFindManyPhoneInput>;
  email?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<EnumEmployeePermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<FilterFindManyEmployeeTasksInput>;
  company?: Maybe<Scalars['MongoID']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmployeeStatus>;
  heardAboutUs?: Maybe<FilterFindManyHeardAboutUsInput>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyEmployeeOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyEmployeeInput>>;
  AND?: Maybe<Array<FilterFindManyEmployeeInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyEmployeeOperatorsInput = {
  email?: Maybe<FilterFindManyEmployeeEmailOperatorsInput>;
  createdAt?: Maybe<FilterFindManyEmployeeCreatedAtOperatorsInput>;
};

export type FilterFindManyEmployeeTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type FilterFindManyExpertiseCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyExpertiseInput = {
  id?: Maybe<Scalars['MongoID']>;
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyExpertiseOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyExpertiseInput>>;
  AND?: Maybe<Array<FilterFindManyExpertiseInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyExpertiseOperatorsInput = {
  createdAt?: Maybe<FilterFindManyExpertiseCreatedAtOperatorsInput>;
};

export type FilterFindManyHeardAboutUsInput = {
  who?: Maybe<Scalars['String']>;
  how?: Maybe<Scalars['String']>;
};

export type FilterFindManyIdentityIdOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyIdentityInput = {
  id?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerType?: Maybe<EnumIdentityProviderType>;
  type?: Maybe<EnumIdentityType>;
  accessToken?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['Date']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  company?: Maybe<Scalars['MongoID']>;
  admin?: Maybe<Scalars['MongoID']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyIdentityOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyIdentityInput>>;
  AND?: Maybe<Array<FilterFindManyIdentityInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyIdentityOperatorsInput = {
  id?: Maybe<FilterFindManyIdentityIdOperatorsInput>;
  _id?: Maybe<FilterFindManyIdentity_IdOperatorsInput>;
};

export type FilterFindManyIdentity_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyInvoiceAddressedToOperatorsInput = {
  gt?: Maybe<EnumInvoiceAddressedTo>;
  gte?: Maybe<EnumInvoiceAddressedTo>;
  lt?: Maybe<EnumInvoiceAddressedTo>;
  lte?: Maybe<EnumInvoiceAddressedTo>;
  ne?: Maybe<EnumInvoiceAddressedTo>;
  in?: Maybe<Array<Maybe<EnumInvoiceAddressedTo>>>;
  nin?: Maybe<Array<Maybe<EnumInvoiceAddressedTo>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyInvoiceCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyInvoiceInput = {
  id?: Maybe<Scalars['MongoID']>;
  company?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumInvoicePermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<FilterFindManyInvoiceTasksInput>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  acracyAmount?: Maybe<Scalars['Float']>;
  acracyAmountTtc?: Maybe<Scalars['Float']>;
  acracyCommission?: Maybe<Scalars['Float']>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  amountForServiceProvider?: Maybe<Scalars['Float']>;
  amountTtc?: Maybe<Scalars['Float']>;
  amountTtcForServiceProvider?: Maybe<Scalars['Float']>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  averageDailyRateForCompany?: Maybe<Scalars['Float']>;
  averageDailyRateForServiceProvider?: Maybe<Scalars['Float']>;
  brief?: Maybe<Scalars['MongoID']>;
  comment?: Maybe<Scalars['String']>;
  latestInvoice?: Maybe<Scalars['Boolean']>;
  linkCGV?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['MongoID']>;
  missionTitle?: Maybe<Scalars['String']>;
  paymentDeadline?: Maybe<Scalars['Date']>;
  paymentDate?: Maybe<Scalars['Date']>;
  sentDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<EnumInvoiceStatus>;
  number?: Maybe<Scalars['String']>;
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  reasonForRefusal?: Maybe<Scalars['String']>;
  messageForRefusal?: Maybe<Scalars['String']>;
  tvaAmount?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
  workedDays?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  paymentUrl?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  dashboardUrl?: Maybe<Scalars['String']>;
  addressedTo?: Maybe<EnumInvoiceAddressedTo>;
  legalNotice?: Maybe<Scalars['String']>;
  serviceProviderNumber?: Maybe<Scalars['String']>;
  linkedInvoice?: Maybe<Scalars['MongoID']>;
  linkedInvoiceFactoring?: Maybe<Scalars['MongoID']>;
  factoringPaidAt?: Maybe<Scalars['Date']>;
  factoringPaymentDeadline?: Maybe<Scalars['Date']>;
  factoringAmount?: Maybe<Scalars['Float']>;
  isFinancedByFactoring?: Maybe<Scalars['Boolean']>;
  platformUploadedAt?: Maybe<Scalars['Date']>;
  artistTaxReportAt?: Maybe<Scalars['Date']>;
  ariaId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyInvoiceOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyInvoiceInput>>;
  AND?: Maybe<Array<FilterFindManyInvoiceInput>>;
};

export type FilterFindManyInvoiceNumberOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyInvoiceOperatorsInput = {
  status?: Maybe<FilterFindManyInvoiceStatusOperatorsInput>;
  number?: Maybe<FilterFindManyInvoiceNumberOperatorsInput>;
  addressedTo?: Maybe<FilterFindManyInvoiceAddressedToOperatorsInput>;
  serviceProviderNumber?: Maybe<FilterFindManyInvoiceServiceProviderNumberOperatorsInput>;
  createdAt?: Maybe<FilterFindManyInvoiceCreatedAtOperatorsInput>;
};

export type FilterFindManyInvoiceServiceProviderNumberOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyInvoiceStatusOperatorsInput = {
  gt?: Maybe<EnumInvoiceStatus>;
  gte?: Maybe<EnumInvoiceStatus>;
  lt?: Maybe<EnumInvoiceStatus>;
  lte?: Maybe<EnumInvoiceStatus>;
  ne?: Maybe<EnumInvoiceStatus>;
  in?: Maybe<Array<Maybe<EnumInvoiceStatus>>>;
  nin?: Maybe<Array<Maybe<EnumInvoiceStatus>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyInvoiceTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type FilterFindManyMissionContextBudgetInput = {
  dailyRateForServiceProvider?: Maybe<Scalars['Float']>;
  matchDailyRate?: Maybe<Scalars['Float']>;
  budgetForServiceProvider?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  dailyRateForCompany?: Maybe<Scalars['Float']>;
  dailyRate?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  budgetForCompany?: Maybe<Scalars['Float']>;
};

export type FilterFindManyMissionContextDurationInput = {
  nb?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  nbDay?: Maybe<Scalars['Float']>;
};

export type FilterFindManyMissionContextInput = {
  duration?: Maybe<FilterFindManyMissionContextDurationInput>;
  matchDuration?: Maybe<FilterFindManyMissionContextMatchDurationInput>;
  matchWeeklyRythm?: Maybe<Scalars['Float']>;
  profilNumber?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  estimatedAverageDailyRate?: Maybe<Scalars['Float']>;
  matchFormat?: Maybe<Scalars['String']>;
  weeklyRythm?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  budget?: Maybe<FilterFindManyMissionContextBudgetInput>;
};

export type FilterFindManyMissionContextMatchDurationInput = {
  nb?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  nbDay?: Maybe<Scalars['Float']>;
};

export type FilterFindManyMissionDetailInput = {
  contextAndTasks?: Maybe<Scalars['String']>;
  detailsOfDeliverables?: Maybe<Scalars['String']>;
  sharedDocuments?: Maybe<Array<Maybe<FilterFindManyServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
};

export type FilterFindManyMissionRequirementsExpertisesInput = {
  priority?: Maybe<Scalars['Boolean']>;
  expertise?: Maybe<AlgoliaInput>;
};

export type FilterFindManyMissionRequirementsInput = {
  expertises?: Maybe<Array<Maybe<FilterFindManyMissionRequirementsExpertisesInput>>>;
  matchExpertises?: Maybe<Array<Maybe<FilterFindManyMissionRequirementsExpertisesInput>>>;
  languages?: Maybe<Array<Maybe<FilterFindManyMissionRequirementsLanguagesInput>>>;
  matchLanguages?: Maybe<Array<Maybe<FilterFindManyMissionRequirementsLanguagesInput>>>;
  sensitivity?: Maybe<FilterFindManyMissionRequirementsSensitivityInput>;
  sensitivities?: Maybe<Array<Maybe<FilterFindManyMissionRequirementsSensitivityInput>>>;
  matchSensitivity?: Maybe<FilterFindManyMissionRequirementsSensitivityInput>;
  matchSensitivities?: Maybe<Array<Maybe<FilterFindManyMissionRequirementsSensitivityInput>>>;
  seniority?: Maybe<Scalars['String']>;
  matchSeniority?: Maybe<Scalars['String']>;
  matchPersona?: Maybe<FilterFindManyMissionRequirementsMatchPersonaInput>;
};

export type FilterFindManyMissionRequirementsLanguagesInput = {
  essential?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};

export type FilterFindManyMissionRequirementsMatchPersonaInput = {
  personas?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAnyOfList?: Maybe<Scalars['Boolean']>;
  isNotIn?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyMissionRequirementsSensitivityInput = {
  essential?: Maybe<Scalars['Boolean']>;
  sensitivity?: Maybe<AlgoliaInput>;
};

export type FilterFindManyNotificationAboutInput = {
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyNotificationInput = {
  id?: Maybe<Scalars['MongoID']>;
  sentDate?: Maybe<Scalars['Date']>;
  readDate?: Maybe<Scalars['Date']>;
  sentBy?: Maybe<FilterFindManyNotificationSentByInput>;
  sentTo?: Maybe<FilterFindManyNotificationSentToInput>;
  about?: Maybe<FilterFindManyNotificationAboutInput>;
  type?: Maybe<EnumNotificationType>;
  isEmail?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  OR?: Maybe<Array<FilterFindManyNotificationInput>>;
  AND?: Maybe<Array<FilterFindManyNotificationInput>>;
};

export type FilterFindManyNotificationSentByInput = {
  name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyNotificationSentToInput = {
  name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyPersonaInput = {
  id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  OR?: Maybe<Array<FilterFindManyPersonaInput>>;
  AND?: Maybe<Array<FilterFindManyPersonaInput>>;
};

export type FilterFindManyPhoneInput = {
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type FilterFindManyQuoteCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyQuoteInput = {
  id?: Maybe<Scalars['MongoID']>;
  company?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumQuotePermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<FilterFindManyQuoteTasksInput>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  brief?: Maybe<Scalars['MongoID']>;
  revealPosition?: Maybe<Scalars['Float']>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiated?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiatedForCompany?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiatedForServiceProvider?: Maybe<Scalars['Float']>;
  nbDays?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  amountTtc?: Maybe<Scalars['Float']>;
  acracyCommission?: Maybe<Scalars['Float']>;
  acracyCommissionTtc?: Maybe<Scalars['Float']>;
  reasonForRefusal?: Maybe<Scalars['String']>;
  reasonForRefusalMessage?: Maybe<Scalars['String']>;
  serviceProviderMessage?: Maybe<Scalars['String']>;
  serviceProviderComment?: Maybe<Scalars['String']>;
  invoices?: Maybe<Scalars['MongoID']>;
  signedQuote?: Maybe<FilterFindManyQuoteSignedQuoteInput>;
  interview?: Maybe<FilterFindManyQuoteInterviewInput>;
  status?: Maybe<EnumQuoteStatus>;
  yousign?: Maybe<FilterFindManyServiceProviderYousignInput>;
  comparisonOfAverageDailyRate?: Maybe<Scalars['String']>;
  acracyBlurb?: Maybe<Scalars['String']>;
  linkCGV?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<FilterFindManyQuotePurchaseOrderInput>;
  isAdditional?: Maybe<Scalars['Boolean']>;
  isInvoiceDone?: Maybe<Scalars['Boolean']>;
  isFullPackage?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyQuoteOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyQuoteInput>>;
  AND?: Maybe<Array<FilterFindManyQuoteInput>>;
};

export type FilterFindManyQuoteInterviewAvailabilityDatesInput = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  isChosen?: Maybe<Scalars['Boolean']>;
  proposedBy?: Maybe<Scalars['String']>;
  proposedAt?: Maybe<Scalars['String']>;
  acceptedBy?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
  message?: Maybe<Scalars['String']>;
};

export type FilterFindManyQuoteInterviewInput = {
  availabilityDates?: Maybe<Array<Maybe<FilterFindManyQuoteInterviewAvailabilityDatesInput>>>;
  messageToServiceProvider?: Maybe<Scalars['String']>;
  messageToUs?: Maybe<Scalars['String']>;
  sentDate?: Maybe<Scalars['Date']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyQuoteOperatorsInput = {
  status?: Maybe<FilterFindManyQuoteStatusOperatorsInput>;
  createdAt?: Maybe<FilterFindManyQuoteCreatedAtOperatorsInput>;
};

export type FilterFindManyQuotePurchaseOrderInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  amount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
};

export type FilterFindManyQuoteSignedQuoteInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  link?: Maybe<Scalars['String']>;
};

export type FilterFindManyQuoteStatusOperatorsInput = {
  gt?: Maybe<EnumQuoteStatus>;
  gte?: Maybe<EnumQuoteStatus>;
  lt?: Maybe<EnumQuoteStatus>;
  lte?: Maybe<EnumQuoteStatus>;
  ne?: Maybe<EnumQuoteStatus>;
  in?: Maybe<Array<Maybe<EnumQuoteStatus>>>;
  nin?: Maybe<Array<Maybe<EnumQuoteStatus>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyQuoteTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type FilterFindManyScoreCardInput = {
  persona?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment?: Maybe<Scalars['String']>;
  followUp?: Maybe<Scalars['String']>;
};

export type FilterFindManySearchInput = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FilterFindManySensitivityCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManySensitivityInput = {
  id?: Maybe<Scalars['MongoID']>;
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManySensitivityOperatorsInput>;
  OR?: Maybe<Array<FilterFindManySensitivityInput>>;
  AND?: Maybe<Array<FilterFindManySensitivityInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManySensitivityOperatorsInput = {
  createdAt?: Maybe<FilterFindManySensitivityCreatedAtOperatorsInput>;
};

export type FilterFindManyServiceProviderAcracyContractInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  signed?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  signatureDate?: Maybe<Scalars['Date']>;
};

export type FilterFindManyServiceProviderAdministrativeProfileBillingInput = {
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone?: Maybe<FilterFindManyServiceProviderAdministrativeProfileBillingPhoneInput>;
  email?: Maybe<Scalars['String']>;
};

export type FilterFindManyServiceProviderAdministrativeProfileBillingPhoneInput = {
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type FilterFindManyServiceProviderAdministrativeProfileHeadOfficeInput = {
  country?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type FilterFindManyServiceProviderAdministrativeProfileInput = {
  intraCommunityVAT?: Maybe<Scalars['Boolean']>;
  cityOfRcsRegistration?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  billing?: Maybe<FilterFindManyServiceProviderAdministrativeProfileBillingInput>;
  headOffice?: Maybe<FilterFindManyServiceProviderAdministrativeProfileHeadOfficeInput>;
  sameAddress?: Maybe<Scalars['Boolean']>;
  shareCapital?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['Boolean']>;
  InternationalBankAccountNumber?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  legalForm?: Maybe<Scalars['String']>;
  cguCheck?: Maybe<Scalars['Boolean']>;
  legalDocuments?: Maybe<Array<Maybe<FilterFindManyServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
  legalNotice?: Maybe<Scalars['String']>;
  activityCode?: Maybe<Scalars['String']>;
  insuranceNumber?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  isWithholdingTaxExemption?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyServiceProviderAdministrativeProfileLegalDocumentsInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FilterFindManyServiceProviderCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyServiceProviderEmailOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyServiceProviderInput = {
  id?: Maybe<Scalars['MongoID']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phone?: Maybe<FilterFindManyPhoneInput>;
  email?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<EnumServiceProviderPermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  acracyBlurb?: Maybe<Scalars['String']>;
  acracyContract?: Maybe<FilterFindManyServiceProviderAcracyContractInput>;
  administrativeProfile?: Maybe<FilterFindManyServiceProviderAdministrativeProfileInput>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  format?: Maybe<EnumServiceProviderFormat>;
  seniority?: Maybe<EnumServiceProviderSeniority>;
  languages?: Maybe<Array<Maybe<EnumServiceProviderLanguages>>>;
  curentSituation?: Maybe<EnumServiceProviderCurentSituation>;
  duration?: Maybe<Array<Maybe<EnumServiceProviderDuration>>>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  hasLegalStatus?: Maybe<Scalars['Boolean']>;
  heardAboutUs?: Maybe<FilterFindManyHeardAboutUsInput>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  linkGeneralConditionOfSale?: Maybe<Scalars['String']>;
  linkedinAvatar?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  portfolioLink?: Maybe<Scalars['String']>;
  portfolioUpload?: Maybe<Array<Maybe<FilterFindManyServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
  avatarUpload?: Maybe<Array<Maybe<FilterFindManyServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
  profiles?: Maybe<Array<Maybe<FilterFindManyAlgoliaInput>>>;
  sensitivities?: Maybe<Array<Maybe<FilterFindManyMissionRequirementsSensitivityInput>>>;
  expertises?: Maybe<Array<Maybe<FilterFindManyMissionRequirementsExpertisesInput>>>;
  deliverables?: Maybe<Array<Maybe<FilterFindManyDeliverableInput>>>;
  selfPresentation?: Maybe<Scalars['String']>;
  stripeSourceId?: Maybe<Scalars['String']>;
  stripeSourceIban?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConnectedCustomerId?: Maybe<Scalars['String']>;
  isIgnoringBilling?: Maybe<Scalars['Boolean']>;
  weeklyRythm?: Maybe<Array<Maybe<Scalars['Float']>>>;
  scoreCard?: Maybe<FilterFindManyScoreCardInput>;
  yousign?: Maybe<FilterFindManyServiceProviderYousignInput>;
  unavailableUntilDate?: Maybe<Scalars['Date']>;
  isOnBoardingBlocked?: Maybe<Scalars['Boolean']>;
  inviteUri?: Maybe<Scalars['String']>;
  isInvited?: Maybe<Scalars['Boolean']>;
  ariaId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyServiceProviderOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyServiceProviderInput>>;
  AND?: Maybe<Array<FilterFindManyServiceProviderInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyServiceProviderOperatorsInput = {
  email?: Maybe<FilterFindManyServiceProviderEmailOperatorsInput>;
  status?: Maybe<FilterFindManyServiceProviderStatusOperatorsInput>;
  createdAt?: Maybe<FilterFindManyServiceProviderCreatedAtOperatorsInput>;
};

export type FilterFindManyServiceProviderStatusOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyServiceProviderYousignInput = {
  procedureId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type FilterFindManySubscriptionPlanCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManySubscriptionPlanFeaturesInput = {
  maxUsers?: Maybe<Scalars['Float']>;
};

export type FilterFindManySubscriptionPlanInput = {
  id?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumSubscriptionPlanType>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  features?: Maybe<FilterFindManySubscriptionPlanFeaturesInput>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<Maybe<EnumSubscriptionPlanPermissions>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManySubscriptionPlanOperatorsInput>;
  OR?: Maybe<Array<FilterFindManySubscriptionPlanInput>>;
  AND?: Maybe<Array<FilterFindManySubscriptionPlanInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManySubscriptionPlanOperatorsInput = {
  type?: Maybe<FilterFindManySubscriptionPlanTypeOperatorsInput>;
  createdAt?: Maybe<FilterFindManySubscriptionPlanCreatedAtOperatorsInput>;
};

export type FilterFindManySubscriptionPlanTypeOperatorsInput = {
  gt?: Maybe<EnumSubscriptionPlanType>;
  gte?: Maybe<EnumSubscriptionPlanType>;
  lt?: Maybe<EnumSubscriptionPlanType>;
  lte?: Maybe<EnumSubscriptionPlanType>;
  ne?: Maybe<EnumSubscriptionPlanType>;
  in?: Maybe<Array<Maybe<EnumSubscriptionPlanType>>>;
  nin?: Maybe<Array<Maybe<EnumSubscriptionPlanType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManySystemInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManySystemOperatorsInput>;
  OR?: Maybe<Array<FilterFindManySystemInput>>;
  AND?: Maybe<Array<FilterFindManySystemInput>>;
};

export type FilterFindManySystemKeyOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManySystemOperatorsInput = {
  key?: Maybe<FilterFindManySystemKeyOperatorsInput>;
  _id?: Maybe<FilterFindManySystem_IdOperatorsInput>;
};

export type FilterFindManySystem_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyTagInput = {
  id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  OR?: Maybe<Array<FilterFindManyTagInput>>;
  AND?: Maybe<Array<FilterFindManyTagInput>>;
};

export type FilterFindManyVersionInput = {
  c?: Maybe<Scalars['String']>;
  t?: Maybe<Scalars['Date']>;
  o?: Maybe<Scalars['String']>;
  d?: Maybe<Scalars['JSON']>;
  admin?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyVersionOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyVersionInput>>;
  AND?: Maybe<Array<FilterFindManyVersionInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyVersionOperatorsInput = {
  _id?: Maybe<FilterFindManyVersion_IdOperatorsInput>;
};

export type FilterFindManyVersion_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type HeardAboutUs = {
  __typename?: 'HeardAboutUs';
  who?: Maybe<Scalars['String']>;
  how?: Maybe<Scalars['String']>;
};

export type HeardAboutUsInput = {
  who?: Maybe<Scalars['String']>;
  how?: Maybe<Scalars['String']>;
};

export type Identity = {
  __typename?: 'Identity';
  id: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerType?: Maybe<EnumIdentityProviderType>;
  type?: Maybe<EnumIdentityType>;
  accessToken?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['Date']>;
  employee?: Maybe<Employee>;
  serviceProvider?: Maybe<ServiceProvider>;
  company?: Maybe<Scalars['MongoID']>;
  admin?: Maybe<Admin>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  createdAt?: Maybe<Scalars['Date']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id?: Maybe<Scalars['MongoID']>;
  company?: Maybe<Company>;
  employee?: Maybe<Employee>;
  serviceProvider?: Maybe<ServiceProvider>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumInvoicePermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<InvoiceTasks>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  acracyAmount?: Maybe<Scalars['Float']>;
  acracyAmountTtc?: Maybe<Scalars['Float']>;
  acracyCommission?: Maybe<Scalars['Float']>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  amountForServiceProvider?: Maybe<Scalars['Float']>;
  amountTtc?: Maybe<Scalars['Float']>;
  amountTtcForServiceProvider?: Maybe<Scalars['Float']>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  averageDailyRateForCompany?: Maybe<Scalars['Float']>;
  averageDailyRateForServiceProvider?: Maybe<Scalars['Float']>;
  brief?: Maybe<Brief>;
  comment?: Maybe<Scalars['String']>;
  latestInvoice?: Maybe<Scalars['Boolean']>;
  linkCGV?: Maybe<Scalars['String']>;
  quote?: Maybe<Quote>;
  missionTitle?: Maybe<Scalars['String']>;
  paymentDeadline?: Maybe<Scalars['Date']>;
  paymentDate?: Maybe<Scalars['Date']>;
  sentDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<EnumInvoiceStatus>;
  number?: Maybe<Scalars['String']>;
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  reasonForRefusal?: Maybe<Scalars['String']>;
  messageForRefusal?: Maybe<Scalars['String']>;
  tvaAmount?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
  workedDays?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  paymentUrl?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  dashboardUrl?: Maybe<Scalars['String']>;
  addressedTo?: Maybe<EnumInvoiceAddressedTo>;
  legalNotice?: Maybe<Scalars['String']>;
  serviceProviderNumber?: Maybe<Scalars['String']>;
  linkedInvoice?: Maybe<Invoice>;
  linkedInvoiceFactoring?: Maybe<Invoice>;
  factoringPaidAt?: Maybe<Scalars['Date']>;
  factoringPaymentDeadline?: Maybe<Scalars['Date']>;
  factoringAmount?: Maybe<Scalars['Float']>;
  isFinancedByFactoring?: Maybe<Scalars['Boolean']>;
  platformUploadedAt?: Maybe<Scalars['Date']>;
  artistTaxReportAt?: Maybe<Scalars['Date']>;
  ariaId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type InvoiceTasks = {
  __typename?: 'InvoiceTasks';
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};


export type MissionContext = {
  __typename?: 'MissionContext';
  duration?: Maybe<MissionContextDuration>;
  matchDuration?: Maybe<MissionContextMatchDuration>;
  matchWeeklyRythm?: Maybe<Scalars['Float']>;
  profilNumber?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  estimatedAverageDailyRate?: Maybe<Scalars['Float']>;
  matchFormat?: Maybe<Scalars['String']>;
  weeklyRythm?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  budget?: Maybe<MissionContextBudget>;
};

export type MissionContextBudget = {
  __typename?: 'MissionContextBudget';
  dailyRateForServiceProvider?: Maybe<Scalars['Float']>;
  matchDailyRate?: Maybe<Scalars['Float']>;
  budgetForServiceProvider?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  dailyRateForCompany?: Maybe<Scalars['Float']>;
  dailyRate?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  budgetForCompany?: Maybe<Scalars['Float']>;
};

export type MissionContextBudgetInput = {
  dailyRateForServiceProvider?: Maybe<Scalars['Float']>;
  matchDailyRate?: Maybe<Scalars['Float']>;
  budgetForServiceProvider?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  dailyRateForCompany?: Maybe<Scalars['Float']>;
  dailyRate?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  budgetForCompany?: Maybe<Scalars['Float']>;
};

export type MissionContextDuration = {
  __typename?: 'MissionContextDuration';
  nb?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  nbDay?: Maybe<Scalars['Float']>;
};

export type MissionContextDurationInput = {
  nb?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  nbDay?: Maybe<Scalars['Float']>;
};

export type MissionContextInput = {
  duration?: Maybe<MissionContextDurationInput>;
  matchDuration?: Maybe<MissionContextMatchDurationInput>;
  matchWeeklyRythm?: Maybe<Scalars['Float']>;
  profilNumber?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  estimatedAverageDailyRate?: Maybe<Scalars['Float']>;
  matchFormat?: Maybe<Scalars['String']>;
  weeklyRythm?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  budget?: Maybe<MissionContextBudgetInput>;
};

export type MissionContextMatchDuration = {
  __typename?: 'MissionContextMatchDuration';
  nb?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  nbDay?: Maybe<Scalars['Float']>;
};

export type MissionContextMatchDurationInput = {
  nb?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  nbDay?: Maybe<Scalars['Float']>;
};

export type MissionDetail = {
  __typename?: 'MissionDetail';
  contextAndTasks?: Maybe<Scalars['String']>;
  detailsOfDeliverables?: Maybe<Scalars['String']>;
  sharedDocuments?: Maybe<Array<Maybe<ServiceProviderAdministrativeProfileLegalDocuments>>>;
};

export type MissionDetailInput = {
  contextAndTasks?: Maybe<Scalars['String']>;
  detailsOfDeliverables?: Maybe<Scalars['String']>;
  sharedDocuments?: Maybe<Array<Maybe<ServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
};

export type MissionRequirements = {
  __typename?: 'MissionRequirements';
  expertises?: Maybe<Array<Maybe<MissionRequirementsExpertises>>>;
  matchExpertises?: Maybe<Array<Maybe<MissionRequirementsExpertises>>>;
  languages?: Maybe<Array<Maybe<MissionRequirementsLanguages>>>;
  matchLanguages?: Maybe<Array<Maybe<MissionRequirementsLanguages>>>;
  sensitivity?: Maybe<MissionRequirementsSensitivity>;
  sensitivities?: Maybe<Array<Maybe<MissionRequirementsSensitivity>>>;
  matchSensitivity?: Maybe<MissionRequirementsSensitivity>;
  matchSensitivities?: Maybe<Array<Maybe<MissionRequirementsSensitivity>>>;
  seniority?: Maybe<Scalars['String']>;
  matchSeniority?: Maybe<Scalars['String']>;
  matchPersona?: Maybe<MissionRequirementsMatchPersona>;
};

export type MissionRequirementsExpertises = {
  __typename?: 'MissionRequirementsExpertises';
  priority?: Maybe<Scalars['Boolean']>;
  expertise?: Maybe<Algolia>;
};

export type MissionRequirementsExpertisesInput = {
  priority?: Maybe<Scalars['Boolean']>;
  expertise?: Maybe<AlgoliaInput>;
};

export type MissionRequirementsInput = {
  expertises?: Maybe<Array<Maybe<MissionRequirementsExpertisesInput>>>;
  matchExpertises?: Maybe<Array<Maybe<MissionRequirementsExpertisesInput>>>;
  languages?: Maybe<Array<Maybe<MissionRequirementsLanguagesInput>>>;
  matchLanguages?: Maybe<Array<Maybe<MissionRequirementsLanguagesInput>>>;
  sensitivity?: Maybe<MissionRequirementsSensitivityInput>;
  sensitivities?: Maybe<Array<Maybe<MissionRequirementsSensitivityInput>>>;
  matchSensitivity?: Maybe<MissionRequirementsSensitivityInput>;
  matchSensitivities?: Maybe<Array<Maybe<MissionRequirementsSensitivityInput>>>;
  seniority?: Maybe<Scalars['String']>;
  matchSeniority?: Maybe<Scalars['String']>;
  matchPersona?: Maybe<MissionRequirementsMatchPersonaInput>;
};

export type MissionRequirementsLanguages = {
  __typename?: 'MissionRequirementsLanguages';
  essential?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};

export type MissionRequirementsLanguagesInput = {
  essential?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};

export type MissionRequirementsMatchPersona = {
  __typename?: 'MissionRequirementsMatchPersona';
  personas?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAnyOfList?: Maybe<Scalars['Boolean']>;
  isNotIn?: Maybe<Scalars['Boolean']>;
};

export type MissionRequirementsMatchPersonaInput = {
  personas?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAnyOfList?: Maybe<Scalars['Boolean']>;
  isNotIn?: Maybe<Scalars['Boolean']>;
};

export type MissionRequirementsSensitivity = {
  __typename?: 'MissionRequirementsSensitivity';
  essential?: Maybe<Scalars['Boolean']>;
  sensitivity?: Maybe<Algolia>;
};

export type MissionRequirementsSensitivityInput = {
  essential?: Maybe<Scalars['Boolean']>;
  sensitivity?: Maybe<AlgoliaInput>;
};

export type MongoError = ErrorInterface & {
  __typename?: 'MongoError';
  /** MongoDB error message */
  message?: Maybe<Scalars['String']>;
  /** MongoDB error code */
  code?: Maybe<Scalars['Int']>;
};


export type Mutation = {
  __typename?: 'Mutation';
  createServiceProvider?: Maybe<ServiceProvider>;
  updateServiceProvider?: Maybe<ServiceProvider>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteServiceProvider?: Maybe<RemoveByIdServiceProviderPayload>;
  createCompany?: Maybe<Company>;
  updateCompany?: Maybe<Company>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteCompany?: Maybe<RemoveByIdCompanyPayload>;
  createEmployee?: Maybe<Employee>;
  updateEmployee?: Maybe<Employee>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteEmployee?: Maybe<RemoveByIdEmployeePayload>;
  createAttachment?: Maybe<Attachment>;
  updateAttachment?: Maybe<Attachment>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteAttachment?: Maybe<RemoveByIdAttachmentPayload>;
  createBrief?: Maybe<Brief>;
  updateBrief?: Maybe<Brief>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteBrief?: Maybe<RemoveByIdBriefPayload>;
  createBriefAlgoResult?: Maybe<BriefAlgoResult>;
  updateBriefAlgoResult?: Maybe<BriefAlgoResult>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteBriefAlgoResult?: Maybe<RemoveByIdBriefAlgoResultPayload>;
  createQuote?: Maybe<Quote>;
  updateQuote?: Maybe<Quote>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteQuote?: Maybe<RemoveByIdQuotePayload>;
  createInvoice?: Maybe<Invoice>;
  updateInvoice?: Maybe<Invoice>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteInvoice?: Maybe<RemoveByIdInvoicePayload>;
  createIdentity?: Maybe<Identity>;
  updateIdentity?: Maybe<Identity>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteIdentity?: Maybe<RemoveByIdIdentityPayload>;
  createSystem?: Maybe<System>;
  updateSystem?: Maybe<System>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteSystem?: Maybe<RemoveByIdSystemPayload>;
  createAdmin?: Maybe<Admin>;
  updateAdmin?: Maybe<Admin>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteAdmin?: Maybe<RemoveByIdAdminPayload>;
  createVersion?: Maybe<Version>;
  updateVersion?: Maybe<Version>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteVersion?: Maybe<RemoveByIdVersionPayload>;
  createComment?: Maybe<Comment>;
  updateComment?: Maybe<Comment>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteComment?: Maybe<RemoveByIdCommentPayload>;
  createExpertise?: Maybe<Expertise>;
  updateExpertise?: Maybe<Expertise>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteExpertise?: Maybe<RemoveByIdExpertisePayload>;
  createSensitivity?: Maybe<Sensitivity>;
  updateSensitivity?: Maybe<Sensitivity>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteSensitivity?: Maybe<RemoveByIdSensitivityPayload>;
  createAlgoliaObject?: Maybe<AlgoliaObject>;
  updateAlgoliaObject?: Maybe<AlgoliaObject>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteAlgoliaObject?: Maybe<RemoveByIdAlgoliaObjectPayload>;
  createSubscriptionPlan?: Maybe<SubscriptionPlan>;
  updateSubscriptionPlan?: Maybe<SubscriptionPlan>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteSubscriptionPlan?: Maybe<RemoveByIdSubscriptionPlanPayload>;
  createDocumentHub?: Maybe<DocumentHub>;
  updateDocumentHub?: Maybe<DocumentHub>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteDocumentHub?: Maybe<RemoveByIdDocumentHubPayload>;
  createPersona?: Maybe<Persona>;
  updatePersona?: Maybe<Persona>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deletePersona?: Maybe<RemoveByIdPersonaPayload>;
  createTag?: Maybe<Tag>;
  updateTag?: Maybe<Tag>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteTag?: Maybe<RemoveByIdTagPayload>;
  createNotification?: Maybe<Notification>;
  updateNotification?: Maybe<Notification>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  deleteNotification?: Maybe<RemoveByIdNotificationPayload>;
  updateServiceProviderProfile?: Maybe<ServiceProvider>;
  removeServiceProvider?: Maybe<ServiceProvider>;
  optInCompanyPool?: Maybe<ServiceProvider>;
  updateServiceProviderInvite?: Maybe<ServiceProvider>;
  registerCompany?: Maybe<Company>;
  updateCompanyProfile?: Maybe<Company>;
  updateCompanyInvite?: Maybe<Company>;
  registerEmployee?: Maybe<Employee>;
  updateEmployeeProfile?: Maybe<Employee>;
  createBriefContent?: Maybe<Brief>;
  updateBriefContent?: Maybe<Brief>;
  actionBrief?: Maybe<Brief>;
  abandonBrief?: Maybe<Brief>;
  copyBrief?: Maybe<Brief>;
  uploadAttachment?: Maybe<Attachment>;
  removeAttachment?: Maybe<Attachment>;
  actionInvoice?: Maybe<Invoice>;
  actionQuote?: Maybe<Quote>;
  addQuote?: Maybe<Quote>;
  addQuotePurchaseOrder?: Maybe<Quote>;
  updateQuoteContent?: Maybe<Quote>;
  callbackLinkedin?: Maybe<Identity>;
  actionMessage?: Maybe<Identity>;
  adminActionMatching?: Maybe<Array<Maybe<BriefAlgoResult>>>;
  adminQuotesForBriefAndServiceProviders?: Maybe<Array<Maybe<Quote>>>;
  adminQuotesForBriefAlgoResults?: Maybe<Array<Maybe<Quote>>>;
  adminActionSelection?: Maybe<Brief>;
  adminAddAccount?: Maybe<Admin>;
  adminUpdatePassword?: Maybe<Admin>;
  superAdminUpdatePassword?: Maybe<Admin>;
  adminEnableUser?: Maybe<Admin>;
  adminDisableUser?: Maybe<Admin>;
  adminUploadAttachment?: Maybe<Attachment>;
  adminDeleteAttachment?: Maybe<Attachment>;
  adminOnBoardingServiceProvider?: Maybe<ServiceProvider>;
  adminOnBoardingCompany?: Maybe<Company>;
  adminActionYousign?: Maybe<ServiceProvider>;
  adminActionInvoice?: Maybe<Invoice>;
  adminEmailSend?: Maybe<Array<Maybe<Count>>>;
  adminEmailPoolReady?: Maybe<Admin>;
  adminAriaCreateServiceProvider?: Maybe<Admin>;
  adminAriaUpdateServiceProvider?: Maybe<Admin>;
  adminAriaUploadServiceProviderIdCard?: Maybe<Admin>;
  adminAriaDeleteServiceProvider?: Maybe<Admin>;
  adminAriaCreateInvoice?: Maybe<Admin>;
  adminStripeMarkInvoiceAsPaid?: Maybe<Invoice>;
  elevateAdminToSuperAdmin?: Maybe<Admin>;
};


export type MutationCreateServiceProviderArgs = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  phone?: Maybe<PhoneInput>;
  email: Scalars['String'];
  permissions?: Maybe<Array<Maybe<EnumServiceProviderPermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  acracyBlurb?: Maybe<Scalars['String']>;
  acracyContract?: Maybe<ServiceProviderAcracyContractInput>;
  administrativeProfile?: Maybe<ServiceProviderAdministrativeProfileInput>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  format?: Maybe<EnumServiceProviderFormat>;
  seniority?: Maybe<EnumServiceProviderSeniority>;
  languages?: Maybe<Array<Maybe<EnumServiceProviderLanguages>>>;
  curentSituation?: Maybe<EnumServiceProviderCurentSituation>;
  duration?: Maybe<Array<Maybe<EnumServiceProviderDuration>>>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  hasLegalStatus?: Maybe<Scalars['Boolean']>;
  heardAboutUs?: Maybe<HeardAboutUsInput>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  linkGeneralConditionOfSale?: Maybe<Scalars['String']>;
  linkedinAvatar?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  portfolioLink?: Maybe<Scalars['String']>;
  portfolioUpload?: Maybe<Array<Maybe<ServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
  avatarUpload?: Maybe<Array<Maybe<ServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
  profiles?: Maybe<Array<Maybe<AlgoliaInput>>>;
  sensitivities?: Maybe<Array<Maybe<MissionRequirementsSensitivityInput>>>;
  expertises?: Maybe<Array<Maybe<MissionRequirementsExpertisesInput>>>;
  deliverables?: Maybe<Array<Maybe<DeliverableInput>>>;
  selfPresentation?: Maybe<Scalars['String']>;
  stripeSourceId?: Maybe<Scalars['String']>;
  stripeSourceIban?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConnectedCustomerId?: Maybe<Scalars['String']>;
  isIgnoringBilling?: Maybe<Scalars['Boolean']>;
  weeklyRythm?: Maybe<Array<Maybe<Scalars['Float']>>>;
  scoreCard?: Maybe<ScoreCardInput>;
  yousign?: Maybe<ServiceProviderYousignInput>;
  unavailableUntilDate?: Maybe<Scalars['Date']>;
  isOnBoardingBlocked?: Maybe<Scalars['Boolean']>;
  inviteUri?: Maybe<Scalars['String']>;
  isInvited?: Maybe<Scalars['Boolean']>;
  ariaId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateServiceProviderArgs = {
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phone?: Maybe<UpdateByIdPhoneInput>;
  email?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<EnumServiceProviderPermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  acracyBlurb?: Maybe<Scalars['String']>;
  acracyContract?: Maybe<UpdateByIdServiceProviderAcracyContractInput>;
  administrativeProfile?: Maybe<UpdateByIdServiceProviderAdministrativeProfileInput>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  format?: Maybe<EnumServiceProviderFormat>;
  seniority?: Maybe<EnumServiceProviderSeniority>;
  languages?: Maybe<Array<Maybe<EnumServiceProviderLanguages>>>;
  curentSituation?: Maybe<EnumServiceProviderCurentSituation>;
  duration?: Maybe<Array<Maybe<EnumServiceProviderDuration>>>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  hasLegalStatus?: Maybe<Scalars['Boolean']>;
  heardAboutUs?: Maybe<UpdateByIdHeardAboutUsInput>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  linkGeneralConditionOfSale?: Maybe<Scalars['String']>;
  linkedinAvatar?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  portfolioLink?: Maybe<Scalars['String']>;
  portfolioUpload?: Maybe<Array<Maybe<UpdateByIdServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
  avatarUpload?: Maybe<Array<Maybe<UpdateByIdServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
  profiles?: Maybe<Array<Maybe<UpdateByIdAlgoliaInput>>>;
  sensitivities?: Maybe<Array<Maybe<UpdateByIdMissionRequirementsSensitivityInput>>>;
  expertises?: Maybe<Array<Maybe<UpdateByIdMissionRequirementsExpertisesInput>>>;
  deliverables?: Maybe<Array<Maybe<UpdateByIdDeliverableInput>>>;
  selfPresentation?: Maybe<Scalars['String']>;
  stripeSourceId?: Maybe<Scalars['String']>;
  stripeSourceIban?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConnectedCustomerId?: Maybe<Scalars['String']>;
  isIgnoringBilling?: Maybe<Scalars['Boolean']>;
  weeklyRythm?: Maybe<Array<Maybe<Scalars['Float']>>>;
  scoreCard?: Maybe<UpdateByIdScoreCardInput>;
  yousign?: Maybe<UpdateByIdServiceProviderYousignInput>;
  unavailableUntilDate?: Maybe<Scalars['Date']>;
  isOnBoardingBlocked?: Maybe<Scalars['Boolean']>;
  inviteUri?: Maybe<Scalars['String']>;
  isInvited?: Maybe<Scalars['Boolean']>;
  ariaId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteServiceProviderArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateCompanyArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<CompanyTasksInput>;
  organization?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  billingComment?: Maybe<Scalars['String']>;
  idSource?: Maybe<Scalars['String']>;
  idStripe?: Maybe<Scalars['String']>;
  inviteUri?: Maybe<Scalars['String']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
  status?: Maybe<EnumCompanyStatus>;
  employees?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  allowedServiceProviders?: Maybe<Array<Maybe<CompanyAllowedServiceProvidersInput>>>;
  stripeBillingSourceId?: Maybe<Scalars['String']>;
  administrativeProfile?: Maybe<CompanyAdministrativeProfileInput>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  subscription?: Maybe<CompanySubscriptionInput>;
  parentCompany?: Maybe<Scalars['MongoID']>;
  isValidatingInvoices?: Maybe<Scalars['Boolean']>;
  paymentDeadlinePolicy?: Maybe<EnumCompanyPaymentDeadlinePolicy>;
  invoicePlatformLink?: Maybe<Scalars['String']>;
  invoicePlatformLogin?: Maybe<Scalars['String']>;
  invoicePlatformPassword?: Maybe<Scalars['String']>;
  heardAboutUs?: Maybe<HeardAboutUsInput>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateCompanyArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<UpdateByIdCompanyTasksInput>;
  organization?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  billingComment?: Maybe<Scalars['String']>;
  idSource?: Maybe<Scalars['String']>;
  idStripe?: Maybe<Scalars['String']>;
  inviteUri?: Maybe<Scalars['String']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
  status?: Maybe<EnumCompanyStatus>;
  employees?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  allowedServiceProviders?: Maybe<Array<Maybe<UpdateByIdCompanyAllowedServiceProvidersInput>>>;
  stripeBillingSourceId?: Maybe<Scalars['String']>;
  administrativeProfile?: Maybe<UpdateByIdCompanyAdministrativeProfileInput>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  subscription?: Maybe<UpdateByIdCompanySubscriptionInput>;
  parentCompany?: Maybe<Scalars['MongoID']>;
  isValidatingInvoices?: Maybe<Scalars['Boolean']>;
  paymentDeadlinePolicy?: Maybe<EnumCompanyPaymentDeadlinePolicy>;
  invoicePlatformLink?: Maybe<Scalars['String']>;
  invoicePlatformLogin?: Maybe<Scalars['String']>;
  invoicePlatformPassword?: Maybe<Scalars['String']>;
  heardAboutUs?: Maybe<UpdateByIdHeardAboutUsInput>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteCompanyArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateEmployeeArgs = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  phone?: Maybe<PhoneInput>;
  email: Scalars['String'];
  permissions?: Maybe<Array<Maybe<EnumEmployeePermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<EmployeeTasksInput>;
  company?: Maybe<Scalars['MongoID']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmployeeStatus>;
  heardAboutUs?: Maybe<HeardAboutUsInput>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateEmployeeArgs = {
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phone?: Maybe<UpdateByIdPhoneInput>;
  email?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<EnumEmployeePermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<UpdateByIdEmployeeTasksInput>;
  company?: Maybe<Scalars['MongoID']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmployeeStatus>;
  heardAboutUs?: Maybe<UpdateByIdHeardAboutUsInput>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteEmployeeArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateAttachmentArgs = {
  authorizer?: Maybe<AttachmentAuthorizerInput>;
  critical?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  type?: Maybe<EnumAttachmentType>;
  filename?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateAttachmentArgs = {
  authorizer?: Maybe<UpdateByIdAttachmentAuthorizerInput>;
  critical?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  type?: Maybe<EnumAttachmentType>;
  filename?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteAttachmentArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateBriefArgs = {
  company?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumBriefPermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<BriefTasksInput>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  search?: Maybe<SearchInput>;
  desireds?: Maybe<Array<Maybe<SearchInput>>>;
  briefSummary?: Maybe<ServiceProviderAdministrativeProfileLegalDocumentsInput>;
  deliverables?: Maybe<Array<Maybe<AlgoliaInput>>>;
  matchDeliverables?: Maybe<Array<Maybe<AlgoliaInput>>>;
  matchProfile?: Maybe<AlgoliaInput>;
  matchProfiles?: Maybe<Array<Maybe<AlgoliaInput>>>;
  missionContext?: Maybe<MissionContextInput>;
  missionDetail?: Maybe<MissionDetailInput>;
  missionRequirements?: Maybe<MissionRequirementsInput>;
  profile?: Maybe<AlgoliaInput>;
  profiles?: Maybe<Array<Maybe<AlgoliaInput>>>;
  acracyRecommandation?: Maybe<Scalars['String']>;
  serviceProviderRecommendation?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nbQuoteToAccept?: Maybe<Scalars['Float']>;
  status?: Maybe<EnumBriefStatus>;
  revealSentAt?: Maybe<Scalars['Date']>;
  dateStartMatching?: Maybe<Scalars['Date']>;
  isImported?: Maybe<Scalars['Boolean']>;
  timeSpent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateBriefArgs = {
  company?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumBriefPermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<UpdateByIdBriefTasksInput>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  search?: Maybe<UpdateByIdSearchInput>;
  desireds?: Maybe<Array<Maybe<UpdateByIdSearchInput>>>;
  briefSummary?: Maybe<UpdateByIdServiceProviderAdministrativeProfileLegalDocumentsInput>;
  deliverables?: Maybe<Array<Maybe<UpdateByIdAlgoliaInput>>>;
  matchDeliverables?: Maybe<Array<Maybe<UpdateByIdAlgoliaInput>>>;
  matchProfile?: Maybe<UpdateByIdAlgoliaInput>;
  matchProfiles?: Maybe<Array<Maybe<UpdateByIdAlgoliaInput>>>;
  missionContext?: Maybe<UpdateByIdMissionContextInput>;
  missionDetail?: Maybe<UpdateByIdMissionDetailInput>;
  missionRequirements?: Maybe<UpdateByIdMissionRequirementsInput>;
  profile?: Maybe<UpdateByIdAlgoliaInput>;
  profiles?: Maybe<Array<Maybe<UpdateByIdAlgoliaInput>>>;
  acracyRecommandation?: Maybe<Scalars['String']>;
  serviceProviderRecommendation?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nbQuoteToAccept?: Maybe<Scalars['Float']>;
  status?: Maybe<EnumBriefStatus>;
  revealSentAt?: Maybe<Scalars['Date']>;
  dateStartMatching?: Maybe<Scalars['Date']>;
  isImported?: Maybe<Scalars['Boolean']>;
  timeSpent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteBriefArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateBriefAlgoResultArgs = {
  brief?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  comparisonOfAverageDailyRate?: Maybe<EnumBriefAlgoResultComparisonOfAverageDailyRate>;
  name?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  lastStep?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<BriefAlgoResultStepsInput>>>;
  version?: Maybe<Scalars['Float']>;
  nbMissions?: Maybe<Scalars['Float']>;
  isQuoteCreated?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateBriefAlgoResultArgs = {
  brief?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  comparisonOfAverageDailyRate?: Maybe<EnumBriefAlgoResultComparisonOfAverageDailyRate>;
  name?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  lastStep?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<UpdateByIdBriefAlgoResultStepsInput>>>;
  version?: Maybe<Scalars['Float']>;
  nbMissions?: Maybe<Scalars['Float']>;
  isQuoteCreated?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteBriefAlgoResultArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateQuoteArgs = {
  company?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumQuotePermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<QuoteTasksInput>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  brief?: Maybe<Scalars['MongoID']>;
  revealPosition?: Maybe<Scalars['Float']>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiated?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiatedForCompany?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiatedForServiceProvider?: Maybe<Scalars['Float']>;
  nbDays?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  amountTtc?: Maybe<Scalars['Float']>;
  acracyCommission?: Maybe<Scalars['Float']>;
  acracyCommissionTtc?: Maybe<Scalars['Float']>;
  reasonForRefusal?: Maybe<Scalars['String']>;
  reasonForRefusalMessage?: Maybe<Scalars['String']>;
  serviceProviderMessage?: Maybe<Scalars['String']>;
  serviceProviderComment?: Maybe<Scalars['String']>;
  invoices?: Maybe<Scalars['MongoID']>;
  signedQuote?: Maybe<QuoteSignedQuoteInput>;
  interview?: Maybe<QuoteInterviewInput>;
  status?: Maybe<EnumQuoteStatus>;
  yousign?: Maybe<ServiceProviderYousignInput>;
  comparisonOfAverageDailyRate?: Maybe<Scalars['String']>;
  acracyBlurb?: Maybe<Scalars['String']>;
  linkCGV?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<QuotePurchaseOrderInput>;
  isAdditional?: Maybe<Scalars['Boolean']>;
  isInvoiceDone?: Maybe<Scalars['Boolean']>;
  isFullPackage?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateQuoteArgs = {
  company?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumQuotePermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<UpdateByIdQuoteTasksInput>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  brief?: Maybe<Scalars['MongoID']>;
  revealPosition?: Maybe<Scalars['Float']>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiated?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiatedForCompany?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiatedForServiceProvider?: Maybe<Scalars['Float']>;
  nbDays?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  amountTtc?: Maybe<Scalars['Float']>;
  acracyCommission?: Maybe<Scalars['Float']>;
  acracyCommissionTtc?: Maybe<Scalars['Float']>;
  reasonForRefusal?: Maybe<Scalars['String']>;
  reasonForRefusalMessage?: Maybe<Scalars['String']>;
  serviceProviderMessage?: Maybe<Scalars['String']>;
  serviceProviderComment?: Maybe<Scalars['String']>;
  invoices?: Maybe<Scalars['MongoID']>;
  signedQuote?: Maybe<UpdateByIdQuoteSignedQuoteInput>;
  interview?: Maybe<UpdateByIdQuoteInterviewInput>;
  status?: Maybe<EnumQuoteStatus>;
  yousign?: Maybe<UpdateByIdServiceProviderYousignInput>;
  comparisonOfAverageDailyRate?: Maybe<Scalars['String']>;
  acracyBlurb?: Maybe<Scalars['String']>;
  linkCGV?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<UpdateByIdQuotePurchaseOrderInput>;
  isAdditional?: Maybe<Scalars['Boolean']>;
  isInvoiceDone?: Maybe<Scalars['Boolean']>;
  isFullPackage?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteQuoteArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateInvoiceArgs = {
  quoteId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  workedDays?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  latestInvoice?: Maybe<Scalars['Boolean']>;
  serviceProviderNumber?: Maybe<Scalars['String']>;
  isFinancedByFactoring?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateInvoiceArgs = {
  company?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumInvoicePermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<UpdateByIdInvoiceTasksInput>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  acracyAmount?: Maybe<Scalars['Float']>;
  acracyAmountTtc?: Maybe<Scalars['Float']>;
  acracyCommission?: Maybe<Scalars['Float']>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  amountForServiceProvider?: Maybe<Scalars['Float']>;
  amountTtc?: Maybe<Scalars['Float']>;
  amountTtcForServiceProvider?: Maybe<Scalars['Float']>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  averageDailyRateForCompany?: Maybe<Scalars['Float']>;
  averageDailyRateForServiceProvider?: Maybe<Scalars['Float']>;
  brief?: Maybe<Scalars['MongoID']>;
  comment?: Maybe<Scalars['String']>;
  latestInvoice?: Maybe<Scalars['Boolean']>;
  linkCGV?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['MongoID']>;
  missionTitle?: Maybe<Scalars['String']>;
  paymentDeadline?: Maybe<Scalars['Date']>;
  paymentDate?: Maybe<Scalars['Date']>;
  sentDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<EnumInvoiceStatus>;
  number?: Maybe<Scalars['String']>;
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  reasonForRefusal?: Maybe<Scalars['String']>;
  messageForRefusal?: Maybe<Scalars['String']>;
  tvaAmount?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
  workedDays?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  paymentUrl?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  dashboardUrl?: Maybe<Scalars['String']>;
  addressedTo?: Maybe<EnumInvoiceAddressedTo>;
  legalNotice?: Maybe<Scalars['String']>;
  serviceProviderNumber?: Maybe<Scalars['String']>;
  linkedInvoice?: Maybe<Scalars['MongoID']>;
  linkedInvoiceFactoring?: Maybe<Scalars['MongoID']>;
  factoringPaidAt?: Maybe<Scalars['Date']>;
  factoringPaymentDeadline?: Maybe<Scalars['Date']>;
  factoringAmount?: Maybe<Scalars['Float']>;
  isFinancedByFactoring?: Maybe<Scalars['Boolean']>;
  platformUploadedAt?: Maybe<Scalars['Date']>;
  artistTaxReportAt?: Maybe<Scalars['Date']>;
  ariaId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteInvoiceArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateIdentityArgs = {
  companyId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerType?: Maybe<EnumIdentityProviderType>;
  type?: Maybe<EnumIdentityType>;
  accessToken?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['Date']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  company?: Maybe<Scalars['MongoID']>;
  admin?: Maybe<Scalars['MongoID']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateIdentityArgs = {
  companyId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerType?: Maybe<EnumIdentityProviderType>;
  type?: Maybe<EnumIdentityType>;
  accessToken?: Maybe<Scalars['String']>;
  lastConnectedAt?: Maybe<Scalars['Date']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  company?: Maybe<Scalars['MongoID']>;
  admin?: Maybe<Scalars['MongoID']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteIdentityArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateSystemArgs = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateSystemArgs = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteSystemArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateAdminArgs = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  phone?: Maybe<PhoneInput>;
  email: Scalars['String'];
  permissions?: Maybe<Array<Maybe<EnumAdminPermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<AdminTasksInput>;
  slackId?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  calendarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateAdminArgs = {
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phone?: Maybe<UpdateByIdPhoneInput>;
  email?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<EnumAdminPermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<UpdateByIdAdminTasksInput>;
  slackId?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  calendarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteAdminArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateVersionArgs = {
  c?: Maybe<Scalars['String']>;
  t: Scalars['Date'];
  o: Scalars['String'];
  d: Scalars['JSON'];
  admin?: Maybe<Scalars['MongoID']>;
};


export type MutationUpdateVersionArgs = {
  c?: Maybe<Scalars['String']>;
  t?: Maybe<Scalars['Date']>;
  o?: Maybe<Scalars['String']>;
  d?: Maybe<Scalars['JSON']>;
  admin?: Maybe<Scalars['MongoID']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteVersionArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateCommentArgs = {
  admin?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  message?: Maybe<Scalars['String']>;
  model?: Maybe<CommentModelInput>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateCommentArgs = {
  admin?: Maybe<Scalars['MongoID']>;
  employee?: Maybe<Scalars['MongoID']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  message?: Maybe<Scalars['String']>;
  model?: Maybe<UpdateByIdCommentModelInput>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteCommentArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateExpertiseArgs = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateExpertiseArgs = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteExpertiseArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateSensitivityArgs = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateSensitivityArgs = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteSensitivityArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateAlgoliaObjectArgs = {
  externalId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Maybe<Scalars['String']>>>;
  text?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expertises?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateAlgoliaObjectArgs = {
  externalId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Maybe<Scalars['String']>>>;
  text?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expertises?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteAlgoliaObjectArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateSubscriptionPlanArgs = {
  type?: Maybe<EnumSubscriptionPlanType>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  features?: Maybe<SubscriptionPlanFeaturesInput>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<Maybe<EnumSubscriptionPlanPermissions>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateSubscriptionPlanArgs = {
  type?: Maybe<EnumSubscriptionPlanType>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  features?: Maybe<UpdateByIdSubscriptionPlanFeaturesInput>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<Maybe<EnumSubscriptionPlanPermissions>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteSubscriptionPlanArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateDocumentHubArgs = {
  model?: Maybe<DocumentHubModelInput>;
  folders?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateDocumentHubArgs = {
  model?: Maybe<UpdateByIdDocumentHubModelInput>;
  folders?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteDocumentHubArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreatePersonaArgs = {
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdatePersonaArgs = {
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeletePersonaArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateTagArgs = {
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTagArgs = {
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteTagArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCreateNotificationArgs = {
  sentDate?: Maybe<Scalars['Date']>;
  readDate?: Maybe<Scalars['Date']>;
  sentBy?: Maybe<NotificationSentByInput>;
  sentTo?: Maybe<NotificationSentToInput>;
  about?: Maybe<NotificationAboutInput>;
  type?: Maybe<EnumNotificationType>;
  isEmail?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateNotificationArgs = {
  sentDate?: Maybe<Scalars['Date']>;
  readDate?: Maybe<Scalars['Date']>;
  sentBy?: Maybe<UpdateByIdNotificationSentByInput>;
  sentTo?: Maybe<UpdateByIdNotificationSentToInput>;
  about?: Maybe<UpdateByIdNotificationAboutInput>;
  type?: Maybe<EnumNotificationType>;
  isEmail?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['MongoID'];
};


export type MutationDeleteNotificationArgs = {
  _id: Scalars['MongoID'];
};


export type MutationUpdateServiceProviderProfileArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  profiles?: Maybe<Array<Maybe<AlgoliaInput>>>;
  phone?: Maybe<PhoneInput>;
  selfPresentation?: Maybe<Scalars['String']>;
  portfolioLink?: Maybe<Scalars['String']>;
  averageDailyRate?: Maybe<Scalars['Int']>;
  seniority?: Maybe<Scalars['String']>;
  heardAboutUs?: Maybe<HeardAboutUsInput>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  expertises?: Maybe<Array<Maybe<MissionRequirementsExpertisesInput>>>;
  sensitivities?: Maybe<Array<Maybe<MissionRequirementsSensitivityInput>>>;
  deliverables?: Maybe<Array<Maybe<DeliverableInput>>>;
  curentSituation?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  duration?: Maybe<Array<Maybe<Scalars['String']>>>;
  weeklyRythm?: Maybe<Array<Maybe<Scalars['Int']>>>;
  linkGeneralConditionOfSale?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  hasLegalStatus?: Maybe<Scalars['Boolean']>;
  administrativeProfile?: Maybe<ServiceProviderAdministrativeProfileInput>;
  unavailableUntilDate?: Maybe<Scalars['String']>;
};


export type MutationOptInCompanyPoolArgs = {
  companyId: Scalars['String'];
};


export type MutationRegisterCompanyArgs = {
  name: Scalars['String'];
};


export type MutationUpdateCompanyProfileArgs = {
  name?: Maybe<Scalars['String']>;
  administrativeProfile?: Maybe<CompanyAdministrativeProfileInput>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  isValidatingInvoices?: Maybe<Scalars['Boolean']>;
  billingComment?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  serviceProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceProvidersAllowed?: Maybe<Array<Maybe<Scalars['String']>>>;
  heardAboutUs?: Maybe<HeardAboutUsInput>;
};


export type MutationRegisterEmployeeArgs = {
  companyId: Scalars['String'];
  inviteUri: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
  phone: PhoneInput;
  heardAboutUs?: Maybe<HeardAboutUsInput>;
};


export type MutationUpdateEmployeeProfileArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneInput>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  employeeId?: Maybe<Scalars['String']>;
  heardAboutUs?: Maybe<HeardAboutUsInput>;
};


export type MutationCreateBriefContentArgs = {
  search?: Maybe<SearchInput>;
  desireds?: Maybe<Array<Maybe<SearchInput>>>;
  profiles?: Maybe<Array<Maybe<AlgoliaInput>>>;
  deliverables?: Maybe<Array<Maybe<AlgoliaInput>>>;
  missionContext?: Maybe<MissionContextInput>;
  missionDetail?: Maybe<MissionDetailInput>;
  missionRequirements?: Maybe<MissionRequirementsInput>;
  serviceProviderId?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateBriefContentArgs = {
  id: Scalars['String'];
  search?: Maybe<SearchInput>;
  desireds?: Maybe<Array<Maybe<SearchInput>>>;
  profiles?: Maybe<Array<Maybe<AlgoliaInput>>>;
  deliverables?: Maybe<Array<Maybe<AlgoliaInput>>>;
  missionContext?: Maybe<MissionContextInput>;
  missionDetail?: Maybe<MissionDetailInput>;
  missionRequirements?: Maybe<MissionRequirementsInput>;
  serviceProviderId?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationActionBriefArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationAbandonBriefArgs = {
  id: Scalars['String'];
};


export type MutationCopyBriefArgs = {
  id: Scalars['String'];
};


export type MutationUploadAttachmentArgs = {
  type: Scalars['String'];
  name: Scalars['String'];
  filename: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationRemoveAttachmentArgs = {
  id: Scalars['String'];
};


export type MutationActionInvoiceArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationActionQuoteArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationAddQuoteArgs = {
  briefId?: Maybe<Scalars['String']>;
  serviceProviderId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationAddQuotePurchaseOrderArgs = {
  quoteId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationUpdateQuoteContentArgs = {
  quoteId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationCallbackLinkedinArgs = {
  code?: Maybe<Scalars['String']>;
  redirect_uri?: Maybe<Scalars['String']>;
  inviteUri?: Maybe<Scalars['String']>;
  serviceProviderId?: Maybe<Scalars['String']>;
};


export type MutationActionMessageArgs = {
  type: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationAdminActionMatchingArgs = {
  briefId: Scalars['String'];
  serviceProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
  matchingName?: Maybe<Scalars['String']>;
};


export type MutationAdminQuotesForBriefAndServiceProvidersArgs = {
  briefId: Scalars['String'];
  serviceProviders: Array<Maybe<Scalars['String']>>;
};


export type MutationAdminQuotesForBriefAlgoResultsArgs = {
  briefAlgoResults: Array<Maybe<Scalars['String']>>;
};


export type MutationAdminActionSelectionArgs = {
  briefId: Scalars['String'];
};


export type MutationAdminAddAccountArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAdminUpdatePasswordArgs = {
  password: Scalars['String'];
};


export type MutationSuperAdminUpdatePasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAdminEnableUserArgs = {
  email: Scalars['String'];
};


export type MutationAdminDisableUserArgs = {
  email: Scalars['String'];
};


export type MutationAdminUploadAttachmentArgs = {
  type: Scalars['String'];
  name: Scalars['String'];
  filename: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationAdminDeleteAttachmentArgs = {
  id: Scalars['String'];
  serviceProviderId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};


export type MutationAdminOnBoardingServiceProviderArgs = {
  serviceProvider: Scalars['String'];
};


export type MutationAdminOnBoardingCompanyArgs = {
  company: Scalars['String'];
};


export type MutationAdminActionYousignArgs = {
  object?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type MutationAdminActionInvoiceArgs = {
  invoice: Scalars['String'];
};


export type MutationAdminEmailSendArgs = {
  template: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationAdminEmailPoolReadyArgs = {
  employeeId: Scalars['String'];
};


export type MutationAdminAriaCreateServiceProviderArgs = {
  serviceProviderId: Scalars['String'];
};


export type MutationAdminAriaUpdateServiceProviderArgs = {
  serviceProviderId: Scalars['String'];
};


export type MutationAdminAriaUploadServiceProviderIdCardArgs = {
  serviceProviderId: Scalars['String'];
};


export type MutationAdminAriaDeleteServiceProviderArgs = {
  serviceProviderId: Scalars['String'];
};


export type MutationAdminAriaCreateInvoiceArgs = {
  invoiceId: Scalars['String'];
};


export type MutationAdminStripeMarkInvoiceAsPaidArgs = {
  invoiceId: Scalars['String'];
};


export type MutationElevateAdminToSuperAdminArgs = {
  adminId: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  id?: Maybe<Scalars['MongoID']>;
  sentDate?: Maybe<Scalars['Date']>;
  readDate?: Maybe<Scalars['Date']>;
  sentBy?: Maybe<NotificationSentBy>;
  sentTo?: Maybe<NotificationSentTo>;
  about?: Maybe<NotificationAbout>;
  type?: Maybe<EnumNotificationType>;
  isEmail?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type NotificationAbout = {
  __typename?: 'NotificationAbout';
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type NotificationAboutInput = {
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type NotificationSentBy = {
  __typename?: 'NotificationSentBy';
  name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['MongoID']>;
};

export type NotificationSentByInput = {
  name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['MongoID']>;
};

export type NotificationSentTo = {
  __typename?: 'NotificationSentTo';
  name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['MongoID']>;
};

export type NotificationSentToInput = {
  name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['MongoID']>;
};

export type Persona = {
  __typename?: 'Persona';
  id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type Phone = {
  __typename?: 'Phone';
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type PhoneInput = {
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  ServiceProvider?: Maybe<ServiceProvider>;
  allServiceProviders?: Maybe<Array<Maybe<ServiceProvider>>>;
  _allServiceProvidersMeta?: Maybe<Count>;
  Company?: Maybe<Company>;
  allCompanies?: Maybe<Array<Maybe<Company>>>;
  _allCompaniesMeta?: Maybe<Count>;
  Employee?: Maybe<Employee>;
  allEmployees?: Maybe<Array<Maybe<Employee>>>;
  _allEmployeesMeta?: Maybe<Count>;
  Attachment?: Maybe<Attachment>;
  allAttachments?: Maybe<Array<Maybe<Attachment>>>;
  _allAttachmentsMeta?: Maybe<Count>;
  Brief?: Maybe<Brief>;
  allBriefs?: Maybe<Array<Maybe<Brief>>>;
  _allBriefsMeta?: Maybe<Count>;
  BriefAlgoResult?: Maybe<BriefAlgoResult>;
  allBriefAlgoResults?: Maybe<Array<Maybe<BriefAlgoResult>>>;
  _allBriefAlgoResultsMeta?: Maybe<Count>;
  Quote?: Maybe<Quote>;
  allQuotes?: Maybe<Array<Maybe<Quote>>>;
  _allQuotesMeta?: Maybe<Count>;
  Invoice?: Maybe<Invoice>;
  allInvoices?: Maybe<Array<Maybe<Invoice>>>;
  _allInvoicesMeta?: Maybe<Count>;
  Identity?: Maybe<Identity>;
  allIdentities?: Maybe<Array<Maybe<Identity>>>;
  _allIdentitiesMeta?: Maybe<Count>;
  System?: Maybe<System>;
  allSystems?: Maybe<Array<Maybe<System>>>;
  _allSystemsMeta?: Maybe<Count>;
  Admin?: Maybe<Admin>;
  allAdmins?: Maybe<Array<Maybe<Admin>>>;
  _allAdminsMeta?: Maybe<Count>;
  Version?: Maybe<Version>;
  allVersions?: Maybe<Array<Maybe<Version>>>;
  _allVersionsMeta?: Maybe<Count>;
  Comment?: Maybe<Comment>;
  allComments?: Maybe<Array<Maybe<Comment>>>;
  _allCommentsMeta?: Maybe<Count>;
  Expertise?: Maybe<Expertise>;
  allExpertise?: Maybe<Array<Maybe<Expertise>>>;
  _allExpertiseMeta?: Maybe<Count>;
  Sensitivity?: Maybe<Sensitivity>;
  allSensitivities?: Maybe<Array<Maybe<Sensitivity>>>;
  _allSensitivitiesMeta?: Maybe<Count>;
  AlgoliaObject?: Maybe<AlgoliaObject>;
  allAlgoliaObjects?: Maybe<Array<Maybe<AlgoliaObject>>>;
  _allAlgoliaObjectsMeta?: Maybe<Count>;
  SubscriptionPlan?: Maybe<SubscriptionPlan>;
  allSubscriptionPlans?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  _allSubscriptionPlansMeta?: Maybe<Count>;
  DocumentHub?: Maybe<DocumentHub>;
  allDocumentHubs?: Maybe<Array<Maybe<DocumentHub>>>;
  _allDocumentHubsMeta?: Maybe<Count>;
  Persona?: Maybe<Persona>;
  allPersonas?: Maybe<Array<Maybe<Persona>>>;
  _allPersonasMeta?: Maybe<Count>;
  Tag?: Maybe<Tag>;
  allTags?: Maybe<Array<Maybe<Tag>>>;
  _allTagsMeta?: Maybe<Count>;
  Notification?: Maybe<Notification>;
  allNotifications?: Maybe<Array<Maybe<Notification>>>;
  _allNotificationsMeta?: Maybe<Count>;
  getServiceProvider?: Maybe<ServiceProvider>;
  hasServiceProviderWorkedWithMe?: Maybe<Scalars['Boolean']>;
  getCompany?: Maybe<Company>;
  getEmployee?: Maybe<Employee>;
  getBrief?: Maybe<Brief>;
  searchBriefs?: Maybe<Array<Maybe<Brief>>>;
  getAttachment?: Maybe<Attachment>;
  getInvoice?: Maybe<Invoice>;
  searchInvoices?: Maybe<Array<Maybe<Invoice>>>;
  getQuote?: Maybe<Quote>;
  searchQuotes?: Maybe<Array<Maybe<Quote>>>;
  getProfiles?: Maybe<Array<Maybe<AlgoliaObject>>>;
  getDeliverables?: Maybe<Array<Maybe<AlgoliaObject>>>;
  getExpertises?: Maybe<Array<Maybe<Expertise>>>;
  getSensitivities?: Maybe<Array<Maybe<Sensitivity>>>;
  getWelcomeMessage?: Maybe<WelcomeHome>;
  getConfiguration?: Maybe<Configuration>;
  getSubscriptionPlans?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  getSession?: Maybe<Session>;
  getCompanyDashboardStatistics?: Maybe<Scalars['JSON']>;
  getBriefStatistics?: Maybe<Scalars['JSON']>;
  getServiceProviderDailyByProfileAndSeniority?: Maybe<Scalars['JSON']>;
  getBriefDeliverablesForProfiles?: Maybe<Scalars['JSON']>;
  adminEmailList?: Maybe<Array<Maybe<EmailTemplate>>>;
  adminUserEmailHistory?: Maybe<Scalars['JSON']>;
  adminAriaGetByServiceProviderSiren?: Maybe<Scalars['JSON']>;
  adminAriaAdvanceByUserAriaIdAndAdvanceAriaId?: Maybe<Scalars['JSON']>;
  adminGenerateFile?: Maybe<Scalars['String']>;
};


export type QueryServiceProviderArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllServiceProvidersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyServiceProviderInput>;
};


export type Query_AllServiceProvidersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyServiceProviderInput>;
};


export type QueryCompanyArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllCompaniesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyCompanyInput>;
};


export type Query_AllCompaniesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyCompanyInput>;
};


export type QueryEmployeeArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllEmployeesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyEmployeeInput>;
};


export type Query_AllEmployeesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyEmployeeInput>;
};


export type QueryAttachmentArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllAttachmentsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyAttachmentInput>;
};


export type Query_AllAttachmentsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyAttachmentInput>;
};


export type QueryBriefArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllBriefsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyBriefInput>;
};


export type Query_AllBriefsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyBriefInput>;
};


export type QueryBriefAlgoResultArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllBriefAlgoResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyBriefAlgoResultInput>;
};


export type Query_AllBriefAlgoResultsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyBriefAlgoResultInput>;
};


export type QueryQuoteArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllQuotesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyQuoteInput>;
};


export type Query_AllQuotesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyQuoteInput>;
};


export type QueryInvoiceArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllInvoicesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyInvoiceInput>;
};


export type Query_AllInvoicesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyInvoiceInput>;
};


export type QueryIdentityArgs = {
  id: Scalars['MongoID'];
};


export type QueryAllIdentitiesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyIdentityInput>;
};


export type Query_AllIdentitiesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyIdentityInput>;
};


export type QuerySystemArgs = {
  id: Scalars['MongoID'];
};


export type QueryAllSystemsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManySystemInput>;
};


export type Query_AllSystemsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManySystemInput>;
};


export type QueryAdminArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllAdminsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyAdminInput>;
};


export type Query_AllAdminsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyAdminInput>;
};


export type QueryVersionArgs = {
  id: Scalars['MongoID'];
};


export type QueryAllVersionsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyVersionInput>;
};


export type Query_AllVersionsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyVersionInput>;
};


export type QueryCommentArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllCommentsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyCommentInput>;
};


export type Query_AllCommentsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyCommentInput>;
};


export type QueryExpertiseArgs = {
  id?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
};


export type QueryAllExpertiseArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyExpertiseInput>;
};


export type Query_AllExpertiseMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyExpertiseInput>;
};


export type QuerySensitivityArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllSensitivitiesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManySensitivityInput>;
};


export type Query_AllSensitivitiesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManySensitivityInput>;
};


export type QueryAlgoliaObjectArgs = {
  id?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
};


export type QueryAllAlgoliaObjectsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyAlgoliaObjectInput>;
};


export type Query_AllAlgoliaObjectsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyAlgoliaObjectInput>;
};


export type QuerySubscriptionPlanArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllSubscriptionPlansArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManySubscriptionPlanInput>;
};


export type Query_AllSubscriptionPlansMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManySubscriptionPlanInput>;
};


export type QueryDocumentHubArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllDocumentHubsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyDocumentHubInput>;
};


export type Query_AllDocumentHubsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyDocumentHubInput>;
};


export type QueryPersonaArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllPersonasArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyPersonaInput>;
};


export type Query_AllPersonasMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyPersonaInput>;
};


export type QueryTagArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllTagsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyTagInput>;
};


export type Query_AllTagsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyTagInput>;
};


export type QueryNotificationArgs = {
  id?: Maybe<Scalars['MongoID']>;
};


export type QueryAllNotificationsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyNotificationInput>;
};


export type Query_AllNotificationsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyNotificationInput>;
};


export type QueryGetServiceProviderArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryHasServiceProviderWorkedWithMeArgs = {
  id: Scalars['String'];
};


export type QueryGetCompanyArgs = {
  inviteUri?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetBriefArgs = {
  id: Scalars['String'];
};


export type QuerySearchBriefsArgs = {
  includeStatus?: Maybe<Array<Maybe<EnumBriefStatus>>>;
  excludeStatus?: Maybe<Array<Maybe<EnumBriefStatus>>>;
  employeeId?: Maybe<Scalars['String']>;
};


export type QueryGetAttachmentArgs = {
  id: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  id: Scalars['String'];
};


export type QuerySearchInvoicesArgs = {
  status?: Maybe<Array<Maybe<EnumInvoiceStatus>>>;
  briefId?: Maybe<Scalars['String']>;
  missionId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
};


export type QueryGetQuoteArgs = {
  id: Scalars['String'];
};


export type QuerySearchQuotesArgs = {
  status?: Maybe<Array<Maybe<EnumQuoteStatus>>>;
  briefId?: Maybe<Scalars['String']>;
};


export type QueryGetProfilesArgs = {
  text?: Maybe<Scalars['String']>;
};


export type QueryGetDeliverablesArgs = {
  text?: Maybe<Scalars['String']>;
};


export type QueryGetExpertisesArgs = {
  profiles?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyDashboardStatisticsArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
};


export type QueryGetBriefStatisticsArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};


export type QueryGetBriefDeliverablesForProfilesArgs = {
  status: Scalars['String'];
  profile: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};


export type QueryAdminUserEmailHistoryArgs = {
  email: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryAdminAriaGetByServiceProviderSirenArgs = {
  serviceProviderId: Scalars['String'];
};


export type QueryAdminAriaAdvanceByUserAriaIdAndAdvanceAriaIdArgs = {
  serviceProviderAriaId: Scalars['String'];
  advanceAriaId: Scalars['String'];
};


export type QueryAdminGenerateFileArgs = {
  fileType: Scalars['String'];
  objectId?: Maybe<Scalars['String']>;
};

export type Quote = {
  __typename?: 'Quote';
  id?: Maybe<Scalars['MongoID']>;
  company?: Maybe<Company>;
  employee?: Maybe<Employee>;
  serviceProvider?: Maybe<ServiceProvider>;
  notificationUsers?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  permissions?: Maybe<Array<Maybe<EnumQuotePermissions>>>;
  slackId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['JSON']>;
  hubspotId?: Maybe<Scalars['String']>;
  tasks?: Maybe<QuoteTasks>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  statusChangedAt?: Maybe<Scalars['Date']>;
  brief?: Maybe<Brief>;
  revealPosition?: Maybe<Scalars['Float']>;
  acracyCommissionRate?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiated?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiatedForCompany?: Maybe<Scalars['Float']>;
  averageDailyRateNegotiatedForServiceProvider?: Maybe<Scalars['Float']>;
  nbDays?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  amountTtc?: Maybe<Scalars['Float']>;
  acracyCommission?: Maybe<Scalars['Float']>;
  acracyCommissionTtc?: Maybe<Scalars['Float']>;
  reasonForRefusal?: Maybe<Scalars['String']>;
  reasonForRefusalMessage?: Maybe<Scalars['String']>;
  serviceProviderMessage?: Maybe<Scalars['String']>;
  serviceProviderComment?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  signedQuote?: Maybe<QuoteSignedQuote>;
  interview?: Maybe<QuoteInterview>;
  status?: Maybe<EnumQuoteStatus>;
  yousign?: Maybe<ServiceProviderYousign>;
  comparisonOfAverageDailyRate?: Maybe<Scalars['String']>;
  acracyBlurb?: Maybe<Scalars['String']>;
  linkCGV?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<QuotePurchaseOrder>;
  isAdditional?: Maybe<Scalars['Boolean']>;
  isInvoiceDone?: Maybe<Scalars['Boolean']>;
  isFullPackage?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type QuoteInterview = {
  __typename?: 'QuoteInterview';
  availabilityDates?: Maybe<Array<Maybe<QuoteInterviewAvailabilityDates>>>;
  messageToServiceProvider?: Maybe<Scalars['String']>;
  messageToUs?: Maybe<Scalars['String']>;
  sentDate?: Maybe<Scalars['Date']>;
};

export type QuoteInterviewAvailabilityDates = {
  __typename?: 'QuoteInterviewAvailabilityDates';
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  isChosen?: Maybe<Scalars['Boolean']>;
  proposedBy?: Maybe<Scalars['String']>;
  proposedAt?: Maybe<Scalars['String']>;
  acceptedBy?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
  message?: Maybe<Scalars['String']>;
};

export type QuoteInterviewAvailabilityDatesInput = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  isChosen?: Maybe<Scalars['Boolean']>;
  proposedBy?: Maybe<Scalars['String']>;
  proposedAt?: Maybe<Scalars['String']>;
  acceptedBy?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
  message?: Maybe<Scalars['String']>;
};

export type QuoteInterviewInput = {
  availabilityDates?: Maybe<Array<Maybe<QuoteInterviewAvailabilityDatesInput>>>;
  messageToServiceProvider?: Maybe<Scalars['String']>;
  messageToUs?: Maybe<Scalars['String']>;
  sentDate?: Maybe<Scalars['Date']>;
};

export type QuotePurchaseOrder = {
  __typename?: 'QuotePurchaseOrder';
  attachment?: Maybe<Scalars['MongoID']>;
  amount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
};

export type QuotePurchaseOrderInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  amount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
};

export type QuoteSignedQuote = {
  __typename?: 'QuoteSignedQuote';
  attachment?: Maybe<Scalars['MongoID']>;
  link?: Maybe<Scalars['String']>;
};

export type QuoteSignedQuoteInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  link?: Maybe<Scalars['String']>;
};

export type QuoteTasks = {
  __typename?: 'QuoteTasks';
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type QuoteTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};


export type RemoveByIdAdminPayload = {
  __typename?: 'RemoveByIdAdminPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Admin>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdAlgoliaObjectPayload = {
  __typename?: 'RemoveByIdAlgoliaObjectPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<AlgoliaObject>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdAttachmentPayload = {
  __typename?: 'RemoveByIdAttachmentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Attachment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdBriefAlgoResultPayload = {
  __typename?: 'RemoveByIdBriefAlgoResultPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<BriefAlgoResult>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdBriefPayload = {
  __typename?: 'RemoveByIdBriefPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Brief>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdCommentPayload = {
  __typename?: 'RemoveByIdCommentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Comment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdCompanyPayload = {
  __typename?: 'RemoveByIdCompanyPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Company>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdDocumentHubPayload = {
  __typename?: 'RemoveByIdDocumentHubPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<DocumentHub>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdEmployeePayload = {
  __typename?: 'RemoveByIdEmployeePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Employee>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdExpertisePayload = {
  __typename?: 'RemoveByIdExpertisePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Expertise>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdIdentityPayload = {
  __typename?: 'RemoveByIdIdentityPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Identity>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdInvoicePayload = {
  __typename?: 'RemoveByIdInvoicePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Invoice>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdNotificationPayload = {
  __typename?: 'RemoveByIdNotificationPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Notification>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdPersonaPayload = {
  __typename?: 'RemoveByIdPersonaPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Persona>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdQuotePayload = {
  __typename?: 'RemoveByIdQuotePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Quote>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdSensitivityPayload = {
  __typename?: 'RemoveByIdSensitivityPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Sensitivity>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdServiceProviderPayload = {
  __typename?: 'RemoveByIdServiceProviderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<ServiceProvider>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdSubscriptionPlanPayload = {
  __typename?: 'RemoveByIdSubscriptionPlanPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<SubscriptionPlan>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdSystemPayload = {
  __typename?: 'RemoveByIdSystemPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<System>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdTagPayload = {
  __typename?: 'RemoveByIdTagPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Tag>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdVersionPayload = {
  __typename?: 'RemoveByIdVersionPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Version>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RuntimeError = ErrorInterface & {
  __typename?: 'RuntimeError';
  /** Runtime error message */
  message?: Maybe<Scalars['String']>;
};

export type ScoreCard = {
  __typename?: 'ScoreCard';
  persona?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment?: Maybe<Scalars['String']>;
  followUp?: Maybe<Scalars['String']>;
};

export type ScoreCardInput = {
  persona?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment?: Maybe<Scalars['String']>;
  followUp?: Maybe<Scalars['String']>;
};

export type Search = {
  __typename?: 'Search';
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SearchInput = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Sensitivity = {
  __typename?: 'Sensitivity';
  id?: Maybe<Scalars['MongoID']>;
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type ServiceProvider = {
  __typename?: 'ServiceProvider';
  id?: Maybe<Scalars['MongoID']>;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  phone?: Maybe<Phone>;
  email: Scalars['String'];
  permissions?: Maybe<Array<Maybe<EnumServiceProviderPermissions>>>;
  organization?: Maybe<Scalars['JSON']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  acracyBlurb?: Maybe<Scalars['String']>;
  acracyContract?: Maybe<ServiceProviderAcracyContract>;
  administrativeProfile?: Maybe<ServiceProviderAdministrativeProfile>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  format?: Maybe<EnumServiceProviderFormat>;
  seniority?: Maybe<EnumServiceProviderSeniority>;
  languages?: Maybe<Array<Maybe<EnumServiceProviderLanguages>>>;
  curentSituation?: Maybe<EnumServiceProviderCurentSituation>;
  duration?: Maybe<Array<Maybe<EnumServiceProviderDuration>>>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  hasLegalStatus?: Maybe<Scalars['Boolean']>;
  heardAboutUs?: Maybe<HeardAboutUs>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  linkGeneralConditionOfSale?: Maybe<Scalars['String']>;
  linkedinAvatar?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  portfolioLink?: Maybe<Scalars['String']>;
  portfolioUpload?: Maybe<Array<Maybe<ServiceProviderAdministrativeProfileLegalDocuments>>>;
  avatarUpload?: Maybe<Array<Maybe<ServiceProviderAdministrativeProfileLegalDocuments>>>;
  profiles?: Maybe<Array<Maybe<Algolia>>>;
  sensitivities?: Maybe<Array<Maybe<MissionRequirementsSensitivity>>>;
  expertises?: Maybe<Array<Maybe<MissionRequirementsExpertises>>>;
  deliverables?: Maybe<Array<Maybe<Deliverable>>>;
  selfPresentation?: Maybe<Scalars['String']>;
  stripeSourceId?: Maybe<Scalars['String']>;
  stripeSourceIban?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConnectedCustomerId?: Maybe<Scalars['String']>;
  isIgnoringBilling?: Maybe<Scalars['Boolean']>;
  weeklyRythm?: Maybe<Array<Maybe<Scalars['Float']>>>;
  scoreCard?: Maybe<ScoreCard>;
  yousign?: Maybe<ServiceProviderYousign>;
  unavailableUntilDate?: Maybe<Scalars['Date']>;
  isOnBoardingBlocked?: Maybe<Scalars['Boolean']>;
  inviteUri?: Maybe<Scalars['String']>;
  isInvited?: Maybe<Scalars['Boolean']>;
  ariaId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  stripeCustomerUrl?: Maybe<Scalars['String']>;
  stripeConnectedAccountUrl?: Maybe<Scalars['String']>;
  inviteUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ServiceProviderAcracyContract = {
  __typename?: 'ServiceProviderAcracyContract';
  attachment?: Maybe<Scalars['MongoID']>;
  signed?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  signatureDate?: Maybe<Scalars['Date']>;
};

export type ServiceProviderAcracyContractInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  signed?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  signatureDate?: Maybe<Scalars['Date']>;
};

export type ServiceProviderAdministrativeProfile = {
  __typename?: 'ServiceProviderAdministrativeProfile';
  intraCommunityVAT?: Maybe<Scalars['Boolean']>;
  cityOfRcsRegistration?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  billing?: Maybe<ServiceProviderAdministrativeProfileBilling>;
  headOffice?: Maybe<ServiceProviderAdministrativeProfileHeadOffice>;
  sameAddress?: Maybe<Scalars['Boolean']>;
  shareCapital?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['Boolean']>;
  InternationalBankAccountNumber?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  legalForm?: Maybe<Scalars['String']>;
  cguCheck?: Maybe<Scalars['Boolean']>;
  legalDocuments?: Maybe<Array<Maybe<ServiceProviderAdministrativeProfileLegalDocuments>>>;
  legalNotice?: Maybe<Scalars['String']>;
  activityCode?: Maybe<Scalars['String']>;
  insuranceNumber?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  isWithholdingTaxExemption?: Maybe<Scalars['Boolean']>;
};

export type ServiceProviderAdministrativeProfileBilling = {
  __typename?: 'ServiceProviderAdministrativeProfileBilling';
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone?: Maybe<ServiceProviderAdministrativeProfileBillingPhone>;
  email?: Maybe<Scalars['String']>;
};

export type ServiceProviderAdministrativeProfileBillingInput = {
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone?: Maybe<ServiceProviderAdministrativeProfileBillingPhoneInput>;
  email?: Maybe<Scalars['String']>;
};

export type ServiceProviderAdministrativeProfileBillingPhone = {
  __typename?: 'ServiceProviderAdministrativeProfileBillingPhone';
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type ServiceProviderAdministrativeProfileBillingPhoneInput = {
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type ServiceProviderAdministrativeProfileHeadOffice = {
  __typename?: 'ServiceProviderAdministrativeProfileHeadOffice';
  country?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type ServiceProviderAdministrativeProfileHeadOfficeInput = {
  country?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type ServiceProviderAdministrativeProfileInput = {
  intraCommunityVAT?: Maybe<Scalars['Boolean']>;
  cityOfRcsRegistration?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  billing?: Maybe<ServiceProviderAdministrativeProfileBillingInput>;
  headOffice?: Maybe<ServiceProviderAdministrativeProfileHeadOfficeInput>;
  sameAddress?: Maybe<Scalars['Boolean']>;
  shareCapital?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['Boolean']>;
  InternationalBankAccountNumber?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  legalForm?: Maybe<Scalars['String']>;
  cguCheck?: Maybe<Scalars['Boolean']>;
  legalDocuments?: Maybe<Array<Maybe<ServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
  legalNotice?: Maybe<Scalars['String']>;
  activityCode?: Maybe<Scalars['String']>;
  insuranceNumber?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  isWithholdingTaxExemption?: Maybe<Scalars['Boolean']>;
};

export type ServiceProviderAdministrativeProfileLegalDocuments = {
  __typename?: 'ServiceProviderAdministrativeProfileLegalDocuments';
  attachment?: Maybe<Scalars['MongoID']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ServiceProviderAdministrativeProfileLegalDocumentsInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ServiceProviderYousign = {
  __typename?: 'ServiceProviderYousign';
  procedureId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type ServiceProviderYousignInput = {
  procedureId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  identity?: Maybe<Identity>;
  cognitoId?: Maybe<Scalars['String']>;
  isCognito?: Maybe<Scalars['Boolean']>;
  isLinkedIn?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  serviceProviderId?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  isServiceProvider?: Maybe<Scalars['Boolean']>;
  isConnected?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  id?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumSubscriptionPlanType>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  features?: Maybe<SubscriptionPlanFeatures>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<Maybe<EnumSubscriptionPlanPermissions>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type SubscriptionPlanFeatures = {
  __typename?: 'SubscriptionPlanFeatures';
  maxUsers?: Maybe<Scalars['Float']>;
};

export type SubscriptionPlanFeaturesInput = {
  maxUsers?: Maybe<Scalars['Float']>;
};

export type System = {
  __typename?: 'System';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type Tag = {
  __typename?: 'Tag';
  id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSearch?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdAdminTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type UpdateByIdAlgoliaInput = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type UpdateByIdAttachmentAuthorizerInput = {
  company?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateByIdBriefAlgoResultStepsInput = {
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  maxScore?: Maybe<Scalars['Float']>;
  isImportant?: Maybe<Scalars['Boolean']>;
};

export type UpdateByIdBriefTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type UpdateByIdCommentModelInput = {
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdCompanyAdministrativeProfileBillingInput = {
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone?: Maybe<UpdateByIdCompanyAdministrativeProfileBillingPhoneInput>;
  email?: Maybe<Scalars['String']>;
  accountingEmail?: Maybe<Scalars['String']>;
};

export type UpdateByIdCompanyAdministrativeProfileBillingPhoneInput = {
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type UpdateByIdCompanyAdministrativeProfileHeadOfficeInput = {
  country?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type UpdateByIdCompanyAdministrativeProfileInput = {
  intraCommunityVAT?: Maybe<Scalars['Boolean']>;
  cityOfRcsRegistration?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  billing?: Maybe<UpdateByIdCompanyAdministrativeProfileBillingInput>;
  headOffice?: Maybe<UpdateByIdCompanyAdministrativeProfileHeadOfficeInput>;
  sameAddress?: Maybe<Scalars['Boolean']>;
  shareCapital?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['Boolean']>;
  InternationalBankAccountNumber?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  legalForm?: Maybe<Scalars['String']>;
  cguCheck?: Maybe<Scalars['Boolean']>;
};

export type UpdateByIdCompanyAllowedServiceProvidersInput = {
  isRevealAllowed?: Maybe<Scalars['Boolean']>;
  isBriefAllowed?: Maybe<Scalars['Boolean']>;
  isEmailSent?: Maybe<Scalars['Boolean']>;
  averageDailyRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  serviceProvider?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdCompanySubscriptionInput = {
  subscriptionPlan?: Maybe<Scalars['MongoID']>;
  subscribedAt?: Maybe<Scalars['Date']>;
  cancelledAt?: Maybe<Scalars['Date']>;
  paymentDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type UpdateByIdCompanyTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type UpdateByIdDeliverableInput = {
  priority?: Maybe<Scalars['Boolean']>;
  deliverable?: Maybe<UpdateByIdAlgoliaInput>;
};

export type UpdateByIdDocumentHubModelInput = {
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdEmployeeTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type UpdateByIdHeardAboutUsInput = {
  who?: Maybe<Scalars['String']>;
  how?: Maybe<Scalars['String']>;
};

export type UpdateByIdInvoiceTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type UpdateByIdMissionContextBudgetInput = {
  dailyRateForServiceProvider?: Maybe<Scalars['Float']>;
  matchDailyRate?: Maybe<Scalars['Float']>;
  budgetForServiceProvider?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  dailyRateForCompany?: Maybe<Scalars['Float']>;
  dailyRate?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  budgetForCompany?: Maybe<Scalars['Float']>;
};

export type UpdateByIdMissionContextDurationInput = {
  nb?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  nbDay?: Maybe<Scalars['Float']>;
};

export type UpdateByIdMissionContextInput = {
  duration?: Maybe<UpdateByIdMissionContextDurationInput>;
  matchDuration?: Maybe<UpdateByIdMissionContextMatchDurationInput>;
  matchWeeklyRythm?: Maybe<Scalars['Float']>;
  profilNumber?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  estimatedAverageDailyRate?: Maybe<Scalars['Float']>;
  matchFormat?: Maybe<Scalars['String']>;
  weeklyRythm?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  budget?: Maybe<UpdateByIdMissionContextBudgetInput>;
};

export type UpdateByIdMissionContextMatchDurationInput = {
  nb?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  nbDay?: Maybe<Scalars['Float']>;
};

export type UpdateByIdMissionDetailInput = {
  contextAndTasks?: Maybe<Scalars['String']>;
  detailsOfDeliverables?: Maybe<Scalars['String']>;
  sharedDocuments?: Maybe<Array<Maybe<UpdateByIdServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
};

export type UpdateByIdMissionRequirementsExpertisesInput = {
  priority?: Maybe<Scalars['Boolean']>;
  expertise?: Maybe<AlgoliaInput>;
};

export type UpdateByIdMissionRequirementsInput = {
  expertises?: Maybe<Array<Maybe<UpdateByIdMissionRequirementsExpertisesInput>>>;
  matchExpertises?: Maybe<Array<Maybe<UpdateByIdMissionRequirementsExpertisesInput>>>;
  languages?: Maybe<Array<Maybe<UpdateByIdMissionRequirementsLanguagesInput>>>;
  matchLanguages?: Maybe<Array<Maybe<UpdateByIdMissionRequirementsLanguagesInput>>>;
  sensitivity?: Maybe<UpdateByIdMissionRequirementsSensitivityInput>;
  sensitivities?: Maybe<Array<Maybe<UpdateByIdMissionRequirementsSensitivityInput>>>;
  matchSensitivity?: Maybe<UpdateByIdMissionRequirementsSensitivityInput>;
  matchSensitivities?: Maybe<Array<Maybe<UpdateByIdMissionRequirementsSensitivityInput>>>;
  seniority?: Maybe<Scalars['String']>;
  matchSeniority?: Maybe<Scalars['String']>;
  matchPersona?: Maybe<UpdateByIdMissionRequirementsMatchPersonaInput>;
};

export type UpdateByIdMissionRequirementsLanguagesInput = {
  essential?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};

export type UpdateByIdMissionRequirementsMatchPersonaInput = {
  personas?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAnyOfList?: Maybe<Scalars['Boolean']>;
  isNotIn?: Maybe<Scalars['Boolean']>;
};

export type UpdateByIdMissionRequirementsSensitivityInput = {
  essential?: Maybe<Scalars['Boolean']>;
  sensitivity?: Maybe<AlgoliaInput>;
};

export type UpdateByIdNotificationAboutInput = {
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdNotificationSentByInput = {
  name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdNotificationSentToInput = {
  name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdPhoneInput = {
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type UpdateByIdQuoteInterviewAvailabilityDatesInput = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  isChosen?: Maybe<Scalars['Boolean']>;
  proposedBy?: Maybe<Scalars['String']>;
  proposedAt?: Maybe<Scalars['String']>;
  acceptedBy?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
  message?: Maybe<Scalars['String']>;
};

export type UpdateByIdQuoteInterviewInput = {
  availabilityDates?: Maybe<Array<Maybe<UpdateByIdQuoteInterviewAvailabilityDatesInput>>>;
  messageToServiceProvider?: Maybe<Scalars['String']>;
  messageToUs?: Maybe<Scalars['String']>;
  sentDate?: Maybe<Scalars['Date']>;
};

export type UpdateByIdQuotePurchaseOrderInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  amount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
};

export type UpdateByIdQuoteSignedQuoteInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  link?: Maybe<Scalars['String']>;
};

export type UpdateByIdQuoteTasksInput = {
  selection?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type UpdateByIdScoreCardInput = {
  persona?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment?: Maybe<Scalars['String']>;
  followUp?: Maybe<Scalars['String']>;
};

export type UpdateByIdSearchInput = {
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UpdateByIdServiceProviderAcracyContractInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  signed?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  signatureDate?: Maybe<Scalars['Date']>;
};

export type UpdateByIdServiceProviderAdministrativeProfileBillingInput = {
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone?: Maybe<UpdateByIdServiceProviderAdministrativeProfileBillingPhoneInput>;
  email?: Maybe<Scalars['String']>;
};

export type UpdateByIdServiceProviderAdministrativeProfileBillingPhoneInput = {
  number?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type UpdateByIdServiceProviderAdministrativeProfileHeadOfficeInput = {
  country?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type UpdateByIdServiceProviderAdministrativeProfileInput = {
  intraCommunityVAT?: Maybe<Scalars['Boolean']>;
  cityOfRcsRegistration?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  billing?: Maybe<UpdateByIdServiceProviderAdministrativeProfileBillingInput>;
  headOffice?: Maybe<UpdateByIdServiceProviderAdministrativeProfileHeadOfficeInput>;
  sameAddress?: Maybe<Scalars['Boolean']>;
  shareCapital?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['Boolean']>;
  InternationalBankAccountNumber?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  legalForm?: Maybe<Scalars['String']>;
  cguCheck?: Maybe<Scalars['Boolean']>;
  legalDocuments?: Maybe<Array<Maybe<UpdateByIdServiceProviderAdministrativeProfileLegalDocumentsInput>>>;
  legalNotice?: Maybe<Scalars['String']>;
  activityCode?: Maybe<Scalars['String']>;
  insuranceNumber?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  isWithholdingTaxExemption?: Maybe<Scalars['Boolean']>;
};

export type UpdateByIdServiceProviderAdministrativeProfileLegalDocumentsInput = {
  attachment?: Maybe<Scalars['MongoID']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateByIdServiceProviderYousignInput = {
  procedureId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type UpdateByIdSubscriptionPlanFeaturesInput = {
  maxUsers?: Maybe<Scalars['Float']>;
};

export type ValidationError = ErrorInterface & {
  __typename?: 'ValidationError';
  /** Combined error message from all validators */
  message?: Maybe<Scalars['String']>;
  /** List of validator errors */
  errors?: Maybe<Array<ValidatorError>>;
};

export type ValidatorError = {
  __typename?: 'ValidatorError';
  /** Validation error message */
  message?: Maybe<Scalars['String']>;
  /** Source of the validation error from the model path */
  path?: Maybe<Scalars['String']>;
  /** Field value which occurs the validation error */
  value?: Maybe<Scalars['JSON']>;
  /** Input record idx in array which occurs the validation error. This `idx` is useful for createMany operation. For singular operations it always be 0. For *Many operations `idx` represents record index in array received from user. */
  idx: Scalars['Int'];
};

export type Version = {
  __typename?: 'Version';
  c?: Maybe<Scalars['String']>;
  t: Scalars['Date'];
  o: Scalars['String'];
  d: Scalars['JSON'];
  admin?: Maybe<Admin>;
  _id: Scalars['MongoID'];
};

export type WelcomeHome = {
  __typename?: 'welcomeHome';
  messageOfTheDay?: Maybe<Scalars['String']>;
};

export type FullBriefFragment = (
  { __typename?: 'Brief' }
  & Pick<Brief, 'id' | 'serviceProviderRecommendation' | 'serviceProvider' | 'createdAt' | 'dateStartMatching' | 'acracyRecommandation' | 'status' | 'revealSentAt'>
  & { externalId: Brief['id'] }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'firstName' | 'lastName' | 'email'>
    & { phone?: Maybe<(
      { __typename?: 'Phone' }
      & Pick<Phone, 'number' | 'code'>
    )> }
  )>, briefSummary?: Maybe<(
    { __typename?: 'ServiceProviderAdministrativeProfileLegalDocuments' }
    & Pick<ServiceProviderAdministrativeProfileLegalDocuments, 'name' | 'attachment'>
    & { id: ServiceProviderAdministrativeProfileLegalDocuments['attachment'], externalId: ServiceProviderAdministrativeProfileLegalDocuments['attachment'] }
  )>, deliverables?: Maybe<Array<Maybe<(
    { __typename?: 'Algolia' }
    & Pick<Algolia, 'text' | 'code'>
  )>>>, missionContext?: Maybe<(
    { __typename?: 'MissionContext' }
    & Pick<MissionContext, 'profilNumber' | 'estimatedAverageDailyRate' | 'format' | 'weeklyRythm' | 'title' | 'startDate' | 'endDate'>
    & { duration?: Maybe<(
      { __typename?: 'MissionContextDuration' }
      & Pick<MissionContextDuration, 'unit' | 'nbDay' | 'nb'>
    )>, budget?: Maybe<(
      { __typename?: 'MissionContextBudget' }
      & Pick<MissionContextBudget, 'dailyRateForServiceProvider' | 'budgetForServiceProvider' | 'type' | 'value' | 'dailyRateForCompany' | 'dailyRate' | 'budget' | 'budgetForCompany'>
    )> }
  )>, missionDetail?: Maybe<(
    { __typename?: 'MissionDetail' }
    & Pick<MissionDetail, 'contextAndTasks' | 'detailsOfDeliverables'>
    & { sharedDocuments?: Maybe<Array<Maybe<(
      { __typename?: 'ServiceProviderAdministrativeProfileLegalDocuments' }
      & Pick<ServiceProviderAdministrativeProfileLegalDocuments, 'name' | 'attachment'>
      & { id: ServiceProviderAdministrativeProfileLegalDocuments['attachment'], externalId: ServiceProviderAdministrativeProfileLegalDocuments['attachment'] }
    )>>> }
  )>, salesAdmins?: Maybe<Array<Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'firstName' | 'id'>
  )>>>, communityAdmins?: Maybe<Array<Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'firstName' | 'id'>
  )>>>, missionRequirements?: Maybe<(
    { __typename?: 'MissionRequirements' }
    & Pick<MissionRequirements, 'seniority'>
    & { expertises?: Maybe<Array<Maybe<(
      { __typename?: 'MissionRequirementsExpertises' }
      & Pick<MissionRequirementsExpertises, 'priority'>
      & { expertise?: Maybe<(
        { __typename?: 'Algolia' }
        & Pick<Algolia, 'code' | 'text'>
      )> }
    )>>>, languages?: Maybe<Array<Maybe<(
      { __typename?: 'MissionRequirementsLanguages' }
      & Pick<MissionRequirementsLanguages, 'essential' | 'language'>
    )>>>, sensitivities?: Maybe<Array<Maybe<(
      { __typename?: 'MissionRequirementsSensitivity' }
      & Pick<MissionRequirementsSensitivity, 'essential'>
      & { sensitivity?: Maybe<(
        { __typename?: 'Algolia' }
        & Pick<Algolia, 'code' | 'text'>
      )> }
    )>>> }
  )>, search?: Maybe<(
    { __typename?: 'Search' }
    & Pick<Search, 'text'>
  )>, profiles?: Maybe<Array<Maybe<(
    { __typename?: 'Algolia' }
    & Pick<Algolia, 'text' | 'code'>
  )>>> }
);

export type FullCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'billingComment' | 'idStripe' | 'idSource' | 'taxExempt' | 'website' | 'status' | 'stripeBillingSourceId'>
  & { externalId: Company['id'] }
);

export type FullInvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'number' | 'createdAt' | 'serviceProviderNumber' | 'legalNotice' | 'addressedTo' | 'dashboardUrl' | 'pdfUrl' | 'paymentUrl' | 'workedDays' | 'vatRate' | 'status' | 'tvaAmount' | 'startDate' | 'sentDate' | 'paymentDeadline' | 'linkCGV' | 'latestInvoice' | 'endDate' | 'comment' | 'averageDailyRateForServiceProvider' | 'averageDailyRateForCompany' | 'averageDailyRate' | 'amountTtc' | 'amountTtcForServiceProvider' | 'amountForServiceProvider' | 'acracyCommissionRate' | 'amount' | 'acracyCommission' | 'acracyAmountTtc' | 'acracyAmount' | 'id'>
  & { externalId: Invoice['id'] }
  & { linkedInvoiceFactoring?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
  )>, linkedInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
  )>, brief?: Maybe<(
    { __typename?: 'Brief' }
    & { missionContext?: Maybe<(
      { __typename?: 'MissionContext' }
      & Pick<MissionContext, 'title'>
    )> }
  )>, employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'firstName' | 'lastName'>
  )>, company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )> }
);

export type FullQuoteFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id' | 'acracyCommissionRate' | 'averageDailyRateNegotiatedForCompany' | 'averageDailyRateNegotiatedForServiceProvider' | 'linkCGV' | 'acracyBlurb' | 'comparisonOfAverageDailyRate' | 'status' | 'serviceProviderComment' | 'nbDays' | 'createdAt' | 'isFullPackage' | 'isAdditional' | 'isInvoiceDone'>
  & { externalId: Quote['id'] }
  & { yousign?: Maybe<(
    { __typename?: 'ServiceProviderYousign' }
    & Pick<ServiceProviderYousign, 'link' | 'procedureId'>
  )>, interview?: Maybe<(
    { __typename?: 'QuoteInterview' }
    & Pick<QuoteInterview, 'sentDate' | 'messageToUs' | 'messageToServiceProvider'>
    & { availabilityDates?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteInterviewAvailabilityDates' }
      & Pick<QuoteInterviewAvailabilityDates, 'startDate' | 'endDate'>
    )>>> }
  )>, signedQuote?: Maybe<(
    { __typename?: 'QuoteSignedQuote' }
    & Pick<QuoteSignedQuote, 'attachment' | 'link'>
  )>, purchaseOrder?: Maybe<(
    { __typename?: 'QuotePurchaseOrder' }
    & Pick<QuotePurchaseOrder, 'number'>
  )> }
);

export type FullServiceProviderFragment = (
  { __typename?: 'ServiceProvider' }
  & Pick<ServiceProvider, 'id' | 'lastName' | 'firstName' | 'inviteUri' | 'inviteUrl' | 'isInvited' | 'phoneNumber' | 'isProfileCompleted' | 'email' | 'stripeAccountId' | 'format' | 'duration' | 'weeklyRythm' | 'status' | 'selfPresentation' | 'curentSituation' | 'portfolioLink' | 'nationality' | 'linkedinId' | 'linkedinLink' | 'linkedinAvatar' | 'linkGeneralConditionOfSale' | 'seniority' | 'languages' | 'averageDailyRate' | 'hasLegalStatus' | 'dateOfBirth' | 'unavailableUntilDate'>
  & { serviceProviderId: ServiceProvider['id'], externalId: ServiceProvider['id'] }
  & { phone?: Maybe<(
    { __typename?: 'Phone' }
    & Pick<Phone, 'code' | 'number'>
  )>, yousign?: Maybe<(
    { __typename?: 'ServiceProviderYousign' }
    & Pick<ServiceProviderYousign, 'procedureId' | 'link'>
  )>, deliverables?: Maybe<Array<Maybe<(
    { __typename?: 'Deliverable' }
    & Pick<Deliverable, 'priority'>
    & { deliverable?: Maybe<(
      { __typename?: 'Algolia' }
      & Pick<Algolia, 'code' | 'text'>
    )> }
  )>>>, expertises?: Maybe<Array<Maybe<(
    { __typename?: 'MissionRequirementsExpertises' }
    & Pick<MissionRequirementsExpertises, 'priority'>
    & { expertise?: Maybe<(
      { __typename?: 'Algolia' }
      & Pick<Algolia, 'text' | 'code'>
    )> }
  )>>>, sensitivities?: Maybe<Array<Maybe<(
    { __typename?: 'MissionRequirementsSensitivity' }
    & Pick<MissionRequirementsSensitivity, 'essential'>
    & { sensitivity?: Maybe<(
      { __typename?: 'Algolia' }
      & Pick<Algolia, 'code' | 'text'>
    )> }
  )>>>, profiles?: Maybe<Array<Maybe<(
    { __typename?: 'Algolia' }
    & Pick<Algolia, 'text' | 'code'>
  )>>>, avatarUpload?: Maybe<Array<Maybe<(
    { __typename?: 'ServiceProviderAdministrativeProfileLegalDocuments' }
    & Pick<ServiceProviderAdministrativeProfileLegalDocuments, 'attachment' | 'name'>
  )>>>, portfolioUpload?: Maybe<Array<Maybe<(
    { __typename?: 'ServiceProviderAdministrativeProfileLegalDocuments' }
    & Pick<ServiceProviderAdministrativeProfileLegalDocuments, 'attachment' | 'name'>
  )>>>, administrativeProfile?: Maybe<(
    { __typename?: 'ServiceProviderAdministrativeProfile' }
    & Pick<ServiceProviderAdministrativeProfile, 'vatNumber' | 'cguCheck' | 'legalForm' | 'socialReason' | 'purchaseOrder' | 'InternationalBankAccountNumber' | 'sameAddress' | 'shareCapital' | 'intraCommunityVAT' | 'cityOfRcsRegistration' | 'siret' | 'legalNotice' | 'vatRate' | 'isWithholdingTaxExemption' | 'insuranceNumber' | 'activityCode'>
    & { billing?: Maybe<(
      { __typename?: 'ServiceProviderAdministrativeProfileBilling' }
      & Pick<ServiceProviderAdministrativeProfileBilling, 'lastName' | 'firstName' | 'zipCode' | 'address' | 'email' | 'city'>
      & { phone?: Maybe<(
        { __typename?: 'ServiceProviderAdministrativeProfileBillingPhone' }
        & Pick<ServiceProviderAdministrativeProfileBillingPhone, 'code' | 'number'>
      )> }
    )>, headOffice?: Maybe<(
      { __typename?: 'ServiceProviderAdministrativeProfileHeadOffice' }
      & Pick<ServiceProviderAdministrativeProfileHeadOffice, 'city' | 'address' | 'zipCode' | 'country'>
    )>, legalDocuments?: Maybe<Array<Maybe<(
      { __typename?: 'ServiceProviderAdministrativeProfileLegalDocuments' }
      & Pick<ServiceProviderAdministrativeProfileLegalDocuments, 'name' | 'attachment'>
    )>>> }
  )> }
);

export type ActionMessageMutationVariables = Exact<{
  type: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
}>;


export type ActionMessageMutation = (
  { __typename?: 'Mutation' }
  & { actionMessage?: Maybe<(
    { __typename?: 'Identity' }
    & Pick<Identity, 'id'>
  )> }
);

export type ActionQuoteMutationVariables = Exact<{
  id: Scalars['String'];
  type: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
}>;


export type ActionQuoteMutation = (
  { __typename?: 'Mutation' }
  & { actionQuote?: Maybe<(
    { __typename?: 'Quote' }
    & FullQuoteFragment
  )> }
);

export type AddQuoteMutationVariables = Exact<{
  briefId: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
}>;


export type AddQuoteMutation = (
  { __typename?: 'Mutation' }
  & { addQuote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type CallbackLinkedinMutationVariables = Exact<{
  code?: Maybe<Scalars['String']>;
  redirect_uri?: Maybe<Scalars['String']>;
  inviteUri?: Maybe<Scalars['String']>;
  serviceProviderId?: Maybe<Scalars['String']>;
}>;


export type CallbackLinkedinMutation = (
  { __typename?: 'Mutation' }
  & { callbackLinkedin?: Maybe<(
    { __typename?: 'Identity' }
    & Pick<Identity, 'id' | 'companyId' | 'providerId' | 'profileId' | 'providerType' | 'type' | 'accessToken'>
    & { serviceProvider?: Maybe<(
      { __typename?: 'ServiceProvider' }
      & FullServiceProviderFragment
    )> }
  )> }
);

export type CreateInvoiceMutationVariables = Exact<{
  quoteId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  workedDays: Scalars['Float'];
  comment?: Maybe<Scalars['String']>;
  latestInvoice?: Maybe<Scalars['Boolean']>;
  serviceProviderNumber?: Maybe<Scalars['String']>;
  isFinancedByFactoring?: Maybe<Scalars['Boolean']>;
}>;


export type CreateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & FullInvoiceFragment
  )> }
);

export type OptInCompanyPoolMutationVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type OptInCompanyPoolMutation = (
  { __typename?: 'Mutation' }
  & { optInCompanyPool?: Maybe<(
    { __typename?: 'ServiceProvider' }
    & FullServiceProviderFragment
  )> }
);

export type RemoveAttachmentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { removeAttachment?: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'url' | 'filename' | 'type' | 'payload' | 'name' | 'critical' | 'id'>
  )> }
);

export type UpdateQuoteContentMutationVariables = Exact<{
  quoteId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
}>;


export type UpdateQuoteContentMutation = (
  { __typename?: 'Mutation' }
  & { updateQuoteContent?: Maybe<(
    { __typename?: 'Quote' }
    & FullQuoteFragment
  )> }
);

export type UpdateServiceProviderInviteMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateServiceProviderInviteMutation = (
  { __typename?: 'Mutation' }
  & { updateServiceProviderInvite?: Maybe<(
    { __typename?: 'ServiceProvider' }
    & FullServiceProviderFragment
  )> }
);

export type UpdateServiceProviderProfileMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  profiles?: Maybe<Array<Maybe<AlgoliaInput>> | Maybe<AlgoliaInput>>;
  phone?: Maybe<PhoneInput>;
  portfolioLink?: Maybe<Scalars['String']>;
  selfPresentation?: Maybe<Scalars['String']>;
  averageDailyRate?: Maybe<Scalars['Int']>;
  seniority?: Maybe<Scalars['String']>;
  heardAboutUs?: Maybe<HeardAboutUsInput>;
  administrativeProfile?: Maybe<ServiceProviderAdministrativeProfileInput>;
  hasLegalStatus?: Maybe<Scalars['Boolean']>;
  linkedinLink?: Maybe<Scalars['String']>;
  linkGeneralConditionOfSale?: Maybe<Scalars['String']>;
  weeklyRythm?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  duration?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  format?: Maybe<Scalars['String']>;
  curentSituation?: Maybe<Scalars['String']>;
  deliverables?: Maybe<Array<Maybe<DeliverableInput>> | Maybe<DeliverableInput>>;
  languages?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  expertises?: Maybe<Array<Maybe<MissionRequirementsExpertisesInput>> | Maybe<MissionRequirementsExpertisesInput>>;
  sensitivities?: Maybe<Array<Maybe<MissionRequirementsSensitivityInput>> | Maybe<MissionRequirementsSensitivityInput>>;
  unavailableUntilDate?: Maybe<Scalars['String']>;
}>;


export type UpdateServiceProviderProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateServiceProviderProfile?: Maybe<(
    { __typename?: 'ServiceProvider' }
    & FullServiceProviderFragment
  )> }
);

export type UploadAttachmentMutationVariables = Exact<{
  name: Scalars['String'];
  filename: Scalars['String'];
  type: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
}>;


export type UploadAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { uploadAttachment?: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'url' | 'filename' | 'type' | 'payload' | 'name' | 'critical' | 'id'>
  )> }
);

export type GetAttachmentQueryVariables = Exact<{
  getAttachmentId: Scalars['String'];
}>;


export type GetAttachmentQuery = (
  { __typename?: 'Query' }
  & { getAttachment?: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id' | 'critical' | 'name' | 'type' | 'filename' | 'payload' | 'url'>
  )> }
);

export type GetBriefQueryVariables = Exact<{
  getBriefId: Scalars['String'];
}>;


export type GetBriefQuery = (
  { __typename?: 'Query' }
  & { getBrief?: Maybe<(
    { __typename?: 'Brief' }
    & { quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & { invoices?: Maybe<Array<Maybe<(
        { __typename?: 'Invoice' }
        & FullInvoiceFragment
      )>>> }
      & FullQuoteFragment
    )>>> }
    & FullBriefFragment
  )> }
);

export type GetConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigurationQuery = (
  { __typename?: 'Query' }
  & { getConfiguration?: Maybe<(
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'defaultCommissionRate' | 'defaultMaxFileSize' | 'briefMaxFileSize' | 'allowedImages' | 'allowedDocuments'>
    & { countries?: Maybe<Array<Maybe<(
      { __typename?: 'CountryConfiguration' }
      & Pick<CountryConfiguration, 'countryLabel' | 'countryValue' | 'identityDocuments' | 'companyDocuments' | 'legalForms'>
    )>>> }
  )> }
);

export type GetExpertisesQueryVariables = Exact<{
  profiles?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
}>;


export type GetExpertisesQuery = (
  { __typename?: 'Query' }
  & { getExpertises?: Maybe<Array<Maybe<(
    { __typename?: 'Expertise' }
    & Pick<Expertise, 'code' | 'text'>
  )>>> }
);

export type GetInvoiceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetInvoiceQuery = (
  { __typename?: 'Query' }
  & { getInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & { brief?: Maybe<(
      { __typename?: 'Brief' }
      & FullBriefFragment
    )>, quote?: Maybe<(
      { __typename?: 'Quote' }
      & FullQuoteFragment
    )> }
    & FullInvoiceFragment
  )> }
);

export type GetProfilesAndDeliverablesQueryVariables = Exact<{
  text?: Maybe<Scalars['String']>;
}>;


export type GetProfilesAndDeliverablesQuery = (
  { __typename?: 'Query' }
  & { getProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'AlgoliaObject' }
    & Pick<AlgoliaObject, 'id' | 'externalId' | 'reference' | 'links' | 'text' | 'category' | 'type' | 'expertises' | 'ids' | 'customSearch' | 'createdAt'>
    & { code: AlgoliaObject['externalId'] }
  )>>>, getDeliverables?: Maybe<Array<Maybe<(
    { __typename?: 'AlgoliaObject' }
    & Pick<AlgoliaObject, 'id' | 'externalId' | 'reference' | 'links' | 'text' | 'category' | 'type' | 'expertises' | 'ids' | 'customSearch' | 'createdAt'>
    & { code: AlgoliaObject['externalId'] }
  )>>> }
);

export type GetQuoteQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetQuoteQuery = (
  { __typename?: 'Query' }
  & { getQuote?: Maybe<(
    { __typename?: 'Quote' }
    & { brief?: Maybe<(
      { __typename?: 'Brief' }
      & FullBriefFragment
    )>, invoices?: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & FullInvoiceFragment
    )>>> }
    & FullQuoteFragment
  )> }
);

export type GetSensitivitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSensitivitiesQuery = (
  { __typename?: 'Query' }
  & { getSensitivities?: Maybe<Array<Maybe<(
    { __typename?: 'Sensitivity' }
    & Pick<Sensitivity, 'code' | 'text'>
  )>>> }
);

export type GetServiceProviderQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServiceProviderQuery = (
  { __typename?: 'Query' }
  & { getServiceProvider?: Maybe<(
    { __typename?: 'ServiceProvider' }
    & FullServiceProviderFragment
  )> }
);

export type GetSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSessionQuery = (
  { __typename?: 'Query' }
  & { getSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'isConnected' | 'isServiceProvider' | 'isCompany' | 'serviceProviderId' | 'employeeId' | 'isCognito' | 'companyId' | 'cognitoId'>
    & { identity?: Maybe<(
      { __typename?: 'Identity' }
      & Pick<Identity, 'id' | 'companyId' | 'profileId' | 'providerId' | 'providerType' | 'type' | 'accessToken'>
      & { serviceProvider?: Maybe<(
        { __typename?: 'ServiceProvider' }
        & FullServiceProviderFragment
      )> }
    )> }
  )> }
);

export type RemoveServiceProviderMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveServiceProviderMutation = (
  { __typename?: 'Mutation' }
  & { removeServiceProvider?: Maybe<(
    { __typename?: 'ServiceProvider' }
    & Pick<ServiceProvider, 'id'>
  )> }
);

export type SearchBriefsQueryVariables = Exact<{
  searchBriefsExcludeStatus?: Maybe<Array<Maybe<EnumBriefStatus>> | Maybe<EnumBriefStatus>>;
  searchBriefsIncludeStatus?: Maybe<Array<Maybe<EnumBriefStatus>> | Maybe<EnumBriefStatus>>;
}>;


export type SearchBriefsQuery = (
  { __typename?: 'Query' }
  & { searchBriefs?: Maybe<Array<Maybe<(
    { __typename?: 'Brief' }
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & FullCompanyFragment
    )>, employee?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'name' | 'email' | 'firstName' | 'lastName'>
      & { phone?: Maybe<(
        { __typename?: 'Phone' }
        & Pick<Phone, 'number' | 'code'>
      )> }
    )>, quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & { invoices?: Maybe<Array<Maybe<(
        { __typename?: 'Invoice' }
        & FullInvoiceFragment
      )>>> }
      & FullQuoteFragment
    )>>> }
    & FullBriefFragment
  )>>> }
);

export type SearchInvoicesQueryVariables = Exact<{
  missionId?: Maybe<Scalars['String']>;
  briefId?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<EnumInvoiceStatus>> | Maybe<EnumInvoiceStatus>>;
}>;


export type SearchInvoicesQuery = (
  { __typename?: 'Query' }
  & { searchInvoices?: Maybe<Array<Maybe<(
    { __typename?: 'Invoice' }
    & { brief?: Maybe<(
      { __typename?: 'Brief' }
      & FullBriefFragment
    )>, quote?: Maybe<(
      { __typename?: 'Quote' }
      & FullQuoteFragment
    )> }
    & FullInvoiceFragment
  )>>> }
);

export type SearchQuotesQueryVariables = Exact<{
  briefId?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<EnumQuoteStatus>> | Maybe<EnumQuoteStatus>>;
}>;


export type SearchQuotesQuery = (
  { __typename?: 'Query' }
  & { searchQuotes?: Maybe<Array<Maybe<(
    { __typename?: 'Quote' }
    & { brief?: Maybe<(
      { __typename?: 'Brief' }
      & FullBriefFragment
    )>, invoices?: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & FullInvoiceFragment
    )>>> }
    & FullQuoteFragment
  )>>> }
);

export const FullBriefFragmentDoc = `
    fragment FullBrief on Brief {
  id
  externalId: id
  employee {
    id
    firstName
    lastName
    email
    phone {
      number
      code
    }
  }
  serviceProviderRecommendation
  serviceProvider
  createdAt
  briefSummary {
    name
    id: attachment
    externalId: attachment
    attachment
  }
  deliverables {
    text
    code
  }
  dateStartMatching
  acracyRecommandation
  serviceProvider
  missionContext {
    duration {
      unit
      nbDay
      nb
    }
    profilNumber
    estimatedAverageDailyRate
    format
    weeklyRythm
    title
    startDate
    endDate
    budget {
      dailyRateForServiceProvider
      budgetForServiceProvider
      type
      value
      dailyRateForCompany
      dailyRate
      budget
      budgetForCompany
    }
  }
  missionDetail {
    contextAndTasks
    detailsOfDeliverables
    sharedDocuments {
      name
      attachment
      id: attachment
      externalId: attachment
    }
  }
  salesAdmins {
    firstName
    id
  }
  communityAdmins {
    firstName
    id
  }
  missionRequirements {
    expertises {
      priority
      expertise {
        code
        text
      }
    }
    languages {
      essential
      language
    }
    sensitivities {
      essential
      sensitivity {
        code
        text
      }
    }
    seniority
  }
  search {
    text
  }
  status
  profiles {
    text
    code
  }
  revealSentAt
}
    `;
export const FullCompanyFragmentDoc = `
    fragment FullCompany on Company {
  id
  name
  billingComment
  externalId: id
  idStripe
  idSource
  taxExempt
  website
  status
  stripeBillingSourceId
}
    `;
export const FullInvoiceFragmentDoc = `
    fragment FullInvoice on Invoice {
  linkedInvoiceFactoring {
    id
  }
  linkedInvoice {
    id
  }
  number
  createdAt
  brief {
    missionContext {
      title
    }
  }
  serviceProviderNumber
  legalNotice
  addressedTo
  dashboardUrl
  pdfUrl
  paymentUrl
  workedDays
  vatRate
  status
  tvaAmount
  startDate
  sentDate
  paymentDeadline
  linkCGV
  latestInvoice
  endDate
  employee {
    firstName
    lastName
  }
  company {
    name
  }
  comment
  averageDailyRateForServiceProvider
  averageDailyRateForCompany
  averageDailyRate
  amountTtc
  amountTtcForServiceProvider
  amountForServiceProvider
  acracyCommissionRate
  amount
  acracyCommission
  acracyAmountTtc
  acracyAmount
  id
  externalId: id
}
    `;
export const FullQuoteFragmentDoc = `
    fragment FullQuote on Quote {
  id
  externalId: id
  acracyCommissionRate
  averageDailyRateNegotiatedForCompany
  averageDailyRateNegotiatedForServiceProvider
  linkCGV
  acracyBlurb
  comparisonOfAverageDailyRate
  yousign {
    link
    procedureId
  }
  status
  interview {
    sentDate
    messageToUs
    messageToServiceProvider
    availabilityDates {
      startDate
      endDate
    }
  }
  signedQuote {
    attachment
    link
  }
  serviceProviderComment
  nbDays
  createdAt
  purchaseOrder {
    number
  }
  isFullPackage
  isAdditional
  isInvoiceDone
}
    `;
export const FullServiceProviderFragmentDoc = `
    fragment FullServiceProvider on ServiceProvider {
  id
  serviceProviderId: id
  lastName
  firstName
  inviteUri
  inviteUrl
  isInvited
  phone {
    code
    number
  }
  phoneNumber
  isProfileCompleted
  yousign {
    procedureId
    link
  }
  email
  externalId: id
  stripeAccountId
  deliverables {
    deliverable {
      code
      text
    }
    priority
  }
  expertises {
    priority
    expertise {
      text
      code
    }
  }
  format
  duration
  weeklyRythm
  status
  selfPresentation
  curentSituation
  sensitivities {
    essential
    sensitivity {
      code
      text
    }
  }
  profiles {
    text
    code
  }
  avatarUpload {
    attachment
    name
  }
  portfolioUpload {
    attachment
    name
  }
  portfolioLink
  nationality
  linkedinId
  linkedinLink
  linkedinAvatar
  linkGeneralConditionOfSale
  seniority
  languages
  averageDailyRate
  hasLegalStatus
  dateOfBirth
  unavailableUntilDate
  administrativeProfile {
    vatNumber
    cguCheck
    legalForm
    socialReason
    purchaseOrder
    InternationalBankAccountNumber
    sameAddress
    shareCapital
    billing {
      lastName
      firstName
      zipCode
      address
      phone {
        code
        number
      }
      email
      city
    }
    intraCommunityVAT
    cityOfRcsRegistration
    siret
    headOffice {
      city
      address
      zipCode
      country
    }
    legalNotice
    vatRate
    isWithholdingTaxExemption
    insuranceNumber
    activityCode
    legalDocuments {
      name
      attachment
    }
  }
}
    `;
export const ActionMessageDocument = `
    mutation ActionMessage($type: String!, $payload: JSON) {
  actionMessage(type: $type, payload: $payload) {
    id
  }
}
    `;
export const useActionMessageMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ActionMessageMutation, TError, ActionMessageMutationVariables, TContext>
    ) => 
    useMutation<ActionMessageMutation, TError, ActionMessageMutationVariables, TContext>(
      (variables?: ActionMessageMutationVariables) => fetcher<ActionMessageMutation, ActionMessageMutationVariables>(client, ActionMessageDocument, variables)(),
      options
    );
export const ActionQuoteDocument = `
    mutation ActionQuote($id: String!, $type: String!, $payload: JSON) {
  actionQuote(id: $id, type: $type, payload: $payload) {
    ...FullQuote
  }
}
    ${FullQuoteFragmentDoc}`;
export const useActionQuoteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ActionQuoteMutation, TError, ActionQuoteMutationVariables, TContext>
    ) => 
    useMutation<ActionQuoteMutation, TError, ActionQuoteMutationVariables, TContext>(
      (variables?: ActionQuoteMutationVariables) => fetcher<ActionQuoteMutation, ActionQuoteMutationVariables>(client, ActionQuoteDocument, variables)(),
      options
    );
export const AddQuoteDocument = `
    mutation AddQuote($briefId: String!, $payload: JSON) {
  addQuote(briefId: $briefId, payload: $payload) {
    id
  }
}
    `;
export const useAddQuoteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<AddQuoteMutation, TError, AddQuoteMutationVariables, TContext>
    ) => 
    useMutation<AddQuoteMutation, TError, AddQuoteMutationVariables, TContext>(
      (variables?: AddQuoteMutationVariables) => fetcher<AddQuoteMutation, AddQuoteMutationVariables>(client, AddQuoteDocument, variables)(),
      options
    );
export const CallbackLinkedinDocument = `
    mutation CallbackLinkedin($code: String, $redirect_uri: String, $inviteUri: String, $serviceProviderId: String) {
  callbackLinkedin(
    code: $code
    redirect_uri: $redirect_uri
    inviteUri: $inviteUri
    serviceProviderId: $serviceProviderId
  ) {
    id
    companyId
    providerId
    profileId
    providerType
    type
    accessToken
    serviceProvider {
      ...FullServiceProvider
    }
  }
}
    ${FullServiceProviderFragmentDoc}`;
export const useCallbackLinkedinMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CallbackLinkedinMutation, TError, CallbackLinkedinMutationVariables, TContext>
    ) => 
    useMutation<CallbackLinkedinMutation, TError, CallbackLinkedinMutationVariables, TContext>(
      (variables?: CallbackLinkedinMutationVariables) => fetcher<CallbackLinkedinMutation, CallbackLinkedinMutationVariables>(client, CallbackLinkedinDocument, variables)(),
      options
    );
export const CreateInvoiceDocument = `
    mutation CreateInvoice($quoteId: String!, $startDate: String!, $endDate: String!, $workedDays: Float!, $comment: String, $latestInvoice: Boolean, $serviceProviderNumber: String, $isFinancedByFactoring: Boolean) {
  createInvoice(
    quoteId: $quoteId
    startDate: $startDate
    endDate: $endDate
    workedDays: $workedDays
    comment: $comment
    latestInvoice: $latestInvoice
    serviceProviderNumber: $serviceProviderNumber
    isFinancedByFactoring: $isFinancedByFactoring
  ) {
    ...FullInvoice
  }
}
    ${FullInvoiceFragmentDoc}`;
export const useCreateInvoiceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateInvoiceMutation, TError, CreateInvoiceMutationVariables, TContext>
    ) => 
    useMutation<CreateInvoiceMutation, TError, CreateInvoiceMutationVariables, TContext>(
      (variables?: CreateInvoiceMutationVariables) => fetcher<CreateInvoiceMutation, CreateInvoiceMutationVariables>(client, CreateInvoiceDocument, variables)(),
      options
    );
export const OptInCompanyPoolDocument = `
    mutation OptInCompanyPool($companyId: String!) {
  optInCompanyPool(companyId: $companyId) {
    ...FullServiceProvider
  }
}
    ${FullServiceProviderFragmentDoc}`;
export const useOptInCompanyPoolMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<OptInCompanyPoolMutation, TError, OptInCompanyPoolMutationVariables, TContext>
    ) => 
    useMutation<OptInCompanyPoolMutation, TError, OptInCompanyPoolMutationVariables, TContext>(
      (variables?: OptInCompanyPoolMutationVariables) => fetcher<OptInCompanyPoolMutation, OptInCompanyPoolMutationVariables>(client, OptInCompanyPoolDocument, variables)(),
      options
    );
export const RemoveAttachmentDocument = `
    mutation RemoveAttachment($id: String!) {
  removeAttachment(id: $id) {
    url
    filename
    type
    payload
    name
    critical
    id
  }
}
    `;
export const useRemoveAttachmentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<RemoveAttachmentMutation, TError, RemoveAttachmentMutationVariables, TContext>
    ) => 
    useMutation<RemoveAttachmentMutation, TError, RemoveAttachmentMutationVariables, TContext>(
      (variables?: RemoveAttachmentMutationVariables) => fetcher<RemoveAttachmentMutation, RemoveAttachmentMutationVariables>(client, RemoveAttachmentDocument, variables)(),
      options
    );
export const UpdateQuoteContentDocument = `
    mutation UpdateQuoteContent($quoteId: String, $payload: JSON) {
  updateQuoteContent(quoteId: $quoteId, payload: $payload) {
    ...FullQuote
  }
}
    ${FullQuoteFragmentDoc}`;
export const useUpdateQuoteContentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateQuoteContentMutation, TError, UpdateQuoteContentMutationVariables, TContext>
    ) => 
    useMutation<UpdateQuoteContentMutation, TError, UpdateQuoteContentMutationVariables, TContext>(
      (variables?: UpdateQuoteContentMutationVariables) => fetcher<UpdateQuoteContentMutation, UpdateQuoteContentMutationVariables>(client, UpdateQuoteContentDocument, variables)(),
      options
    );
export const UpdateServiceProviderInviteDocument = `
    mutation UpdateServiceProviderInvite {
  updateServiceProviderInvite {
    ...FullServiceProvider
  }
}
    ${FullServiceProviderFragmentDoc}`;
export const useUpdateServiceProviderInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateServiceProviderInviteMutation, TError, UpdateServiceProviderInviteMutationVariables, TContext>
    ) => 
    useMutation<UpdateServiceProviderInviteMutation, TError, UpdateServiceProviderInviteMutationVariables, TContext>(
      (variables?: UpdateServiceProviderInviteMutationVariables) => fetcher<UpdateServiceProviderInviteMutation, UpdateServiceProviderInviteMutationVariables>(client, UpdateServiceProviderInviteDocument, variables)(),
      options
    );
export const UpdateServiceProviderProfileDocument = `
    mutation UpdateServiceProviderProfile($email: String, $firstName: String, $lastName: String, $dateOfBirth: String, $nationality: String, $profiles: [AlgoliaInput], $phone: PhoneInput, $portfolioLink: String, $selfPresentation: String, $averageDailyRate: Int, $seniority: String, $heardAboutUs: HeardAboutUsInput, $administrativeProfile: ServiceProviderAdministrativeProfileInput, $hasLegalStatus: Boolean, $linkedinLink: String, $linkGeneralConditionOfSale: String, $weeklyRythm: [Int], $duration: [String], $format: String, $curentSituation: String, $deliverables: [DeliverableInput], $languages: [String], $expertises: [MissionRequirementsExpertisesInput], $sensitivities: [MissionRequirementsSensitivityInput], $unavailableUntilDate: String) {
  updateServiceProviderProfile(
    email: $email
    firstName: $firstName
    lastName: $lastName
    dateOfBirth: $dateOfBirth
    nationality: $nationality
    profiles: $profiles
    phone: $phone
    portfolioLink: $portfolioLink
    selfPresentation: $selfPresentation
    averageDailyRate: $averageDailyRate
    seniority: $seniority
    heardAboutUs: $heardAboutUs
    administrativeProfile: $administrativeProfile
    hasLegalStatus: $hasLegalStatus
    linkedinLink: $linkedinLink
    linkGeneralConditionOfSale: $linkGeneralConditionOfSale
    weeklyRythm: $weeklyRythm
    duration: $duration
    format: $format
    curentSituation: $curentSituation
    deliverables: $deliverables
    languages: $languages
    expertises: $expertises
    sensitivities: $sensitivities
    unavailableUntilDate: $unavailableUntilDate
  ) {
    ...FullServiceProvider
  }
}
    ${FullServiceProviderFragmentDoc}`;
export const useUpdateServiceProviderProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateServiceProviderProfileMutation, TError, UpdateServiceProviderProfileMutationVariables, TContext>
    ) => 
    useMutation<UpdateServiceProviderProfileMutation, TError, UpdateServiceProviderProfileMutationVariables, TContext>(
      (variables?: UpdateServiceProviderProfileMutationVariables) => fetcher<UpdateServiceProviderProfileMutation, UpdateServiceProviderProfileMutationVariables>(client, UpdateServiceProviderProfileDocument, variables)(),
      options
    );
export const UploadAttachmentDocument = `
    mutation UploadAttachment($name: String!, $filename: String!, $type: String!, $payload: JSON) {
  uploadAttachment(
    name: $name
    filename: $filename
    type: $type
    payload: $payload
  ) {
    url
    filename
    type
    payload
    name
    critical
    id
  }
}
    `;
export const useUploadAttachmentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UploadAttachmentMutation, TError, UploadAttachmentMutationVariables, TContext>
    ) => 
    useMutation<UploadAttachmentMutation, TError, UploadAttachmentMutationVariables, TContext>(
      (variables?: UploadAttachmentMutationVariables) => fetcher<UploadAttachmentMutation, UploadAttachmentMutationVariables>(client, UploadAttachmentDocument, variables)(),
      options
    );
export const GetAttachmentDocument = `
    query GetAttachment($getAttachmentId: String!) {
  getAttachment(id: $getAttachmentId) {
    id
    critical
    name
    type
    filename
    payload
    url
  }
}
    `;
export const useGetAttachmentQuery = <
      TData = GetAttachmentQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetAttachmentQueryVariables, 
      options?: UseQueryOptions<GetAttachmentQuery, TError, TData>
    ) => 
    useQuery<GetAttachmentQuery, TError, TData>(
      ['GetAttachment', variables],
      fetcher<GetAttachmentQuery, GetAttachmentQueryVariables>(client, GetAttachmentDocument, variables),
      options
    );
export const GetBriefDocument = `
    query GetBrief($getBriefId: String!) {
  getBrief(id: $getBriefId) {
    ...FullBrief
    quotes {
      ...FullQuote
      invoices {
        ...FullInvoice
      }
    }
  }
}
    ${FullBriefFragmentDoc}
${FullQuoteFragmentDoc}
${FullInvoiceFragmentDoc}`;
export const useGetBriefQuery = <
      TData = GetBriefQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetBriefQueryVariables, 
      options?: UseQueryOptions<GetBriefQuery, TError, TData>
    ) => 
    useQuery<GetBriefQuery, TError, TData>(
      ['GetBrief', variables],
      fetcher<GetBriefQuery, GetBriefQueryVariables>(client, GetBriefDocument, variables),
      options
    );
export const GetConfigurationDocument = `
    query GetConfiguration {
  getConfiguration {
    defaultCommissionRate
    defaultMaxFileSize
    briefMaxFileSize
    allowedImages
    allowedDocuments
    countries {
      countryLabel
      countryValue
      identityDocuments
      companyDocuments
      legalForms
    }
  }
}
    `;
export const useGetConfigurationQuery = <
      TData = GetConfigurationQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetConfigurationQueryVariables, 
      options?: UseQueryOptions<GetConfigurationQuery, TError, TData>
    ) => 
    useQuery<GetConfigurationQuery, TError, TData>(
      ['GetConfiguration', variables],
      fetcher<GetConfigurationQuery, GetConfigurationQueryVariables>(client, GetConfigurationDocument, variables),
      options
    );
export const GetExpertisesDocument = `
    query GetExpertises($profiles: String, $profile: String) {
  getExpertises(profiles: $profiles, profile: $profile) {
    code
    text
  }
}
    `;
export const useGetExpertisesQuery = <
      TData = GetExpertisesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetExpertisesQueryVariables, 
      options?: UseQueryOptions<GetExpertisesQuery, TError, TData>
    ) => 
    useQuery<GetExpertisesQuery, TError, TData>(
      ['GetExpertises', variables],
      fetcher<GetExpertisesQuery, GetExpertisesQueryVariables>(client, GetExpertisesDocument, variables),
      options
    );
export const GetInvoiceDocument = `
    query GetInvoice($id: String!) {
  getInvoice(id: $id) {
    ...FullInvoice
    brief {
      ...FullBrief
    }
    quote {
      ...FullQuote
    }
  }
}
    ${FullInvoiceFragmentDoc}
${FullBriefFragmentDoc}
${FullQuoteFragmentDoc}`;
export const useGetInvoiceQuery = <
      TData = GetInvoiceQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetInvoiceQueryVariables, 
      options?: UseQueryOptions<GetInvoiceQuery, TError, TData>
    ) => 
    useQuery<GetInvoiceQuery, TError, TData>(
      ['GetInvoice', variables],
      fetcher<GetInvoiceQuery, GetInvoiceQueryVariables>(client, GetInvoiceDocument, variables),
      options
    );
export const GetProfilesAndDeliverablesDocument = `
    query GetProfilesAndDeliverables($text: String) {
  getProfiles(text: $text) {
    id
    externalId
    code: externalId
    reference
    links
    text
    category
    type
    expertises
    ids
    customSearch
    createdAt
  }
  getDeliverables(text: $text) {
    id
    externalId
    code: externalId
    reference
    links
    text
    category
    type
    expertises
    ids
    customSearch
    createdAt
  }
}
    `;
export const useGetProfilesAndDeliverablesQuery = <
      TData = GetProfilesAndDeliverablesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetProfilesAndDeliverablesQueryVariables, 
      options?: UseQueryOptions<GetProfilesAndDeliverablesQuery, TError, TData>
    ) => 
    useQuery<GetProfilesAndDeliverablesQuery, TError, TData>(
      ['GetProfilesAndDeliverables', variables],
      fetcher<GetProfilesAndDeliverablesQuery, GetProfilesAndDeliverablesQueryVariables>(client, GetProfilesAndDeliverablesDocument, variables),
      options
    );
export const GetQuoteDocument = `
    query GetQuote($id: String!) {
  getQuote(id: $id) {
    ...FullQuote
    brief {
      ...FullBrief
    }
    invoices {
      ...FullInvoice
    }
  }
}
    ${FullQuoteFragmentDoc}
${FullBriefFragmentDoc}
${FullInvoiceFragmentDoc}`;
export const useGetQuoteQuery = <
      TData = GetQuoteQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetQuoteQueryVariables, 
      options?: UseQueryOptions<GetQuoteQuery, TError, TData>
    ) => 
    useQuery<GetQuoteQuery, TError, TData>(
      ['GetQuote', variables],
      fetcher<GetQuoteQuery, GetQuoteQueryVariables>(client, GetQuoteDocument, variables),
      options
    );
export const GetSensitivitiesDocument = `
    query GetSensitivities {
  getSensitivities {
    code
    text
  }
}
    `;
export const useGetSensitivitiesQuery = <
      TData = GetSensitivitiesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetSensitivitiesQueryVariables, 
      options?: UseQueryOptions<GetSensitivitiesQuery, TError, TData>
    ) => 
    useQuery<GetSensitivitiesQuery, TError, TData>(
      ['GetSensitivities', variables],
      fetcher<GetSensitivitiesQuery, GetSensitivitiesQueryVariables>(client, GetSensitivitiesDocument, variables),
      options
    );
export const GetServiceProviderDocument = `
    query GetServiceProvider {
  getServiceProvider {
    ...FullServiceProvider
  }
}
    ${FullServiceProviderFragmentDoc}`;
export const useGetServiceProviderQuery = <
      TData = GetServiceProviderQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetServiceProviderQueryVariables, 
      options?: UseQueryOptions<GetServiceProviderQuery, TError, TData>
    ) => 
    useQuery<GetServiceProviderQuery, TError, TData>(
      ['GetServiceProvider', variables],
      fetcher<GetServiceProviderQuery, GetServiceProviderQueryVariables>(client, GetServiceProviderDocument, variables),
      options
    );
export const GetSessionDocument = `
    query GetSession {
  getSession {
    isConnected
    isServiceProvider
    isCompany
    serviceProviderId
    employeeId
    isCognito
    companyId
    cognitoId
    identity {
      id
      companyId
      profileId
      providerId
      providerType
      type
      accessToken
      serviceProvider {
        ...FullServiceProvider
      }
    }
  }
}
    ${FullServiceProviderFragmentDoc}`;
export const useGetSessionQuery = <
      TData = GetSessionQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetSessionQueryVariables, 
      options?: UseQueryOptions<GetSessionQuery, TError, TData>
    ) => 
    useQuery<GetSessionQuery, TError, TData>(
      ['GetSession', variables],
      fetcher<GetSessionQuery, GetSessionQueryVariables>(client, GetSessionDocument, variables),
      options
    );
export const RemoveServiceProviderDocument = `
    mutation RemoveServiceProvider {
  removeServiceProvider {
    id
  }
}
    `;
export const useRemoveServiceProviderMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<RemoveServiceProviderMutation, TError, RemoveServiceProviderMutationVariables, TContext>
    ) => 
    useMutation<RemoveServiceProviderMutation, TError, RemoveServiceProviderMutationVariables, TContext>(
      (variables?: RemoveServiceProviderMutationVariables) => fetcher<RemoveServiceProviderMutation, RemoveServiceProviderMutationVariables>(client, RemoveServiceProviderDocument, variables)(),
      options
    );
export const SearchBriefsDocument = `
    query SearchBriefs($searchBriefsExcludeStatus: [EnumBriefStatus], $searchBriefsIncludeStatus: [EnumBriefStatus]) {
  searchBriefs(
    excludeStatus: $searchBriefsExcludeStatus
    includeStatus: $searchBriefsIncludeStatus
  ) {
    ...FullBrief
    company {
      ...FullCompany
    }
    employee {
      name
      email
      phone {
        number
        code
      }
      firstName
      lastName
    }
    quotes {
      ...FullQuote
      invoices {
        ...FullInvoice
      }
    }
  }
}
    ${FullBriefFragmentDoc}
${FullCompanyFragmentDoc}
${FullQuoteFragmentDoc}
${FullInvoiceFragmentDoc}`;
export const useSearchBriefsQuery = <
      TData = SearchBriefsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: SearchBriefsQueryVariables, 
      options?: UseQueryOptions<SearchBriefsQuery, TError, TData>
    ) => 
    useQuery<SearchBriefsQuery, TError, TData>(
      ['SearchBriefs', variables],
      fetcher<SearchBriefsQuery, SearchBriefsQueryVariables>(client, SearchBriefsDocument, variables),
      options
    );
export const SearchInvoicesDocument = `
    query SearchInvoices($missionId: String, $briefId: String, $status: [EnumInvoiceStatus]) {
  searchInvoices(missionId: $missionId, briefId: $briefId, status: $status) {
    ...FullInvoice
    brief {
      ...FullBrief
    }
    quote {
      ...FullQuote
    }
  }
}
    ${FullInvoiceFragmentDoc}
${FullBriefFragmentDoc}
${FullQuoteFragmentDoc}`;
export const useSearchInvoicesQuery = <
      TData = SearchInvoicesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: SearchInvoicesQueryVariables, 
      options?: UseQueryOptions<SearchInvoicesQuery, TError, TData>
    ) => 
    useQuery<SearchInvoicesQuery, TError, TData>(
      ['SearchInvoices', variables],
      fetcher<SearchInvoicesQuery, SearchInvoicesQueryVariables>(client, SearchInvoicesDocument, variables),
      options
    );
export const SearchQuotesDocument = `
    query SearchQuotes($briefId: String, $status: [EnumQuoteStatus]) {
  searchQuotes(briefId: $briefId, status: $status) {
    ...FullQuote
    brief {
      ...FullBrief
    }
    invoices {
      ...FullInvoice
    }
  }
}
    ${FullQuoteFragmentDoc}
${FullBriefFragmentDoc}
${FullInvoiceFragmentDoc}`;
export const useSearchQuotesQuery = <
      TData = SearchQuotesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: SearchQuotesQueryVariables, 
      options?: UseQueryOptions<SearchQuotesQuery, TError, TData>
    ) => 
    useQuery<SearchQuotesQuery, TError, TData>(
      ['SearchQuotes', variables],
      fetcher<SearchQuotesQuery, SearchQuotesQueryVariables>(client, SearchQuotesDocument, variables),
      options
    );