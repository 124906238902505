import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import React from "react";

import styles from "./styles";

const CustomSwitch = ({
  checked,
  setChecked,
  switchSize,
  name,
  disabled = false,
  ...props
}) => {
  const classes = styles();

  const toggleChecked = () => {
    setChecked(!checked);
  };

  return (
    <FormGroup {...props}>
      <FormControlLabel
        control={
          <Switch
            name={name}
            classes={{
              root: classes[`${switchSize}Root`],
              switchBase: classes[`${switchSize}SwitchBase`],
              thumb: classes[`${switchSize}Thumb`],
              track: classes[`${switchSize}Track`],
              checked: classes.checked,
            }}
            disabled={disabled}
            checked={checked}
            onChange={toggleChecked}
          />
        }
      />
    </FormGroup>
  );
};

CustomSwitch.propTypes = {
  checked: PropTypes.bool,
  switchSize: PropTypes.oneOf(["small", "large"]),
  setChecked: PropTypes.func,
  name: PropTypes.string,
};

CustomSwitch.defaultProps = {
  checked: false,
  switchSize: "small",
  setChecked: () => {},
  name: undefined,
};

export default CustomSwitch;
