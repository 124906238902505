import { useGetSessionQuery } from "../../generates";
import { client } from "../../libs/graphqlRequest";

export default function useSessionLinkedinMutation(options) {
  return useGetSessionQuery(
    client,
    {},
    {
      ...options,
      select: (data) => data?.getSession,
    }
  );
}
