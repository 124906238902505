
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";


import { CloseIcon } from "../../../assets/icons/CloseIcon";
import CustomCheckbox from "../../Inputs/CheckBox";

import styles from "./styles";

const Tag = ({
  title,
  isPrimaryColor,
  isWithInput,
  placeholder,
  isWithCheckbox,
  tagType,
  maxPriority,
  onCheckChange,
  checkedArray,
  isGrey,
  size,
  isBig,
  checked,
  isDisabled,
  isWithRemove,
  onRemove,
  ...props
}) => {
  const classes = styles();

  const [value, setValue] = useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  let content = (
    <div className={size === "small" ? classes.small : classes.contentBox}>
      {title}
    </div>
  );

  if (isWithInput) {
    content = (
      <div className={classes.contentBox}>
        <input
          id="tagInput"
          placeholder={placeholder}
          value={value}
          autoFocus
          onChange={handleChange}
        />
      </div>
    );
  }

  const disabled =
    checkedArray?.length > maxPriority - 1 &&
    checkedArray?.indexOf(title) === -1;

  if (isWithCheckbox || isWithRemove) {
    content = (
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.checkboxTagContainer}
      >
        <div className={classes.checkboxTagLabelContainer}>
          <div className={classes.checkboxTagLabel}>{title}</div>
        </div>
        {isWithRemove && (
          <CloseIcon
            viewBox="0 0 12 12"
            className={classes.closeIcon}
            onClick={() => !(isDisabled || disabled) && onRemove && onRemove()}
            color="#000"
          />
        )}
        {isWithCheckbox && (
          <span
            className={clsx(classes.tag, classes.checkboxTagContent, {
              [classes.checkboxTagClickable]:
                !(isDisabled || disabled) && onCheckChange,
            })}
            onClick={() =>
              !(isDisabled || disabled) && onCheckChange && onCheckChange()
            }
          >
            {tagType}

            <CustomCheckbox
              shape="rounded"
              // onChange={onCheckChange} // Not necessary here since the whole span is checkable
              className={classes.checkbox}
              disabled={isDisabled || disabled}
              // checked={checked} // <=== Note: fonctionne
              checked={
                checkedArray?.indexOf(title) === undefined
                  ? checked
                  : checkedArray?.indexOf(title) !== -1
              }
            />
          </span>
        )}
      </Grid>
    );
  }

  return (
    <Box
      m={0.5}
      className={clsx(
        classes.tag,
        isPrimaryColor && classes.primaryColor,
        isWithInput && classes.withInput,
        isGrey ? classes.isGrey : null,
        isBig ? classes.isBig : null
      )}
    >
      {content}
    </Box>
  );
};

Tag.propTypes = {
  title: PropTypes.string.isRequired,
  isGrey: PropTypes.bool,
  isBig: PropTypes.bool,
  isPrimaryColor: PropTypes.bool,
  isWithInput: PropTypes.bool,
  placeholder: PropTypes.string,
  isWithCheckbox: PropTypes.bool,
  /**
   * Defines the type of tag you want to use. Either 'Prioritaire' or null
   */
  tagType: PropTypes.string,
  disabled: PropTypes.bool,
};

Tag.defaultProps = {
  isGrey: false,
  isBig: false,
  isPrimaryColor: false,
  isWithInput: false,
  placeholder: null,
  isWithCheckbox: false,
  tagType: null,
  disabled: false,
};

export default Tag;
