import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  tag: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.medium,
    borderRadius: 50,
    cursor: "default",
    fontFamily: "Basier Regular",
    fontSize: 14,
    maxWidth: "100%",
  },
  primaryColor: {
    background: theme.palette.primary.main,
  },
  contentBox: {
    margin: ".5rem 1rem",
  },
  small: {
    margin: ".2rem .5rem",
  },
  smallTagContentBox: {
    margin: ".2rem 1rem",
  },
  withInput: {
    background: theme.palette.secondary.light,
    "& input": {
      border: "none",
      boxShadow: "none",
      background: "transparent",
      "&:focus": {
        outline: "none",
      },
    },
  },
  checkboxTagContainer: {
    color: theme.palette.secondary.medium,
    padding: "2px 2px 2px 10px",
    display: "flex",
  },
  checkboxTagLabelContainer: {
    padding: "2px 0",
    flex: 1,
    minWidth: 0,
  },
  checkboxTagLabel: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  checkboxTagContent: {
    paddingLeft: "15px",
    marginLeft: "10px",
    flexShrink: 0,
  },
  checkbox: {
    padding: 0,
    marginLeft: 5,
    marginBottom: 0,
  },
  isGrey: {
    background: theme.palette.secondary.light,
  },
  isBig: {
    padding: 7,
  },
  checkboxTagClickable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeIcon: {
    width: 12,
    height: 12,
    display: "flex",
    marginLeft: 5,
    marginRight: 5,
    cursor: "pointer",
  },
}));
