import { fade } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  icon: {
    backgroundBlendMode: "hue",
    backgroundImage: "linear-gradient(to bottom, #000000, #000000)",
  },
  avatarLarge: {
    width: "clamp(75px, 10vw, 123px) !important",
    height: "clamp(75px, 10vw, 122px) !important",
    backgroundColor: theme.palette.secondary.black,
  },
  avatarSmall: {
    width: "50px",
    height: "50px",
    backgroundColor: theme.palette.secondary.black,
  },
  mission: {
    width: "55px",
    height: "55px",
    backgroundColor: theme.palette.secondary.black,
  },
  partner: {
    width: 94,
    height: 94,
    background: `${fade(theme.palette.secondary.medium, 0.3)}`,
  },
}));
