import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.secondary.modalGreen,
    borderRadius: 15,
    padding: "10px 10px",
    position: "relative",
    cursor: "pointer",
  },
  TitleMissions: {
    color: "#fff",
    marginBottom: 40,
  },
  actionContainer: {
    [theme.breakpoints.up("md")]: {
      width: "236px",
    },
  },
  gridLeft: {
    borderRadius: "15px 0 0 15px",
    width: "28%",
    backgroundColor: `${theme.palette.secondary.mid}`,
    position: "relative",
    "@media (max-width:960px)": {
      flexDirection: "column",
      width: "100%",
      borderRadius: "15px 15px 0 0",
    },
  },
  statusTitleBase: {
    textTransform: "uppercase",
    fontSize: 14,
    fontFamily: "Basier Regular",
    color: theme.palette.primary.main,
  },
  gridCenter: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    width: "60%",
    backgroundColor: theme.palette.secondary.modalGreen,
    cursor: "pointer",
    "@media (max-width:960px)": {
      width: "100%",
      borderRadius: 16,
      padding: "1rem",
    },
  },
  gridCenterFinished: {
    backgroundColor: theme.palette.secondary.dark,
  },
  gridLeftFinished: {
    backgroundColor: "#111711",
  },
  gridRight: {
    width: "12%",
    backgroundColor: `${theme.palette.primary.main}`,
    "&:hover": {
      cursor: "pointer",
    },
    "@media (max-width:960px)": {
      width: "100%",
      borderRadius: "0 0 15px 15px",
    },
  },
  withoutButton: {
    backgroundColor: theme.palette.secondary.dark,
    "&:hover": {
      cursor: "initial",
    },
  },
  rightRed: {
    backgroundColor: theme.palette.primary.danger,
  },
  bloc: {
    "@media (min-width:960px)": {
      width: "100%",
    },
  },
  blocAvatar: {},
  typo: {
    width: "100%",
  },
  blocTypoUp: {},
  blocTypoDown: {
    "@media (min-width:960px)": {
      width: "100%",
    },
  },
  responsiveName: {},
  icon: {},
  statusTitle: {
    fontSize: 14,
    fontFamily: "Basier Regular",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  tileDetailContainer: {
    [theme.breakpoints.up("md")]: {
      flex: "1",
    },
  },
  detailRow: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flex: "1 1 0",
      width: "auto",
    },
  },
  statusTitleRed: {
    color: theme.palette.primary.danger,
  },
  button: {
    margin: 0,
    textAlign: "center",
    height: 45,
    padding: "0 0.8rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  buttonIcon: {
    width: 50,
    height: 50,
    padding: 0,
    marginLeft: 5,
    "&:hover span svg g g": {
      fill: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  },
  statusContainer: {},
  titleContainer: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "@media (max-width:960px)": {},
  },
  title: {
    fontSize: 22,
    fontFamily: "Basier Medium",
  },
  description: {
    padding: "0 20px 15px",
    "@media (max-width:960px)": {
      padding: "0 20px 20px 20px",
    },
  },
  menuIcon: {
    position: "absolute",
    top: 6,
    left: 10,
  },
  outsideContainer: {
    marginTop: 8,
  },
  outsideTypo: {
    marginLeft: 10,
  },
}));
