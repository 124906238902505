import Button from "@material-ui/core/Button";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import CustomLoader from "../Loader";

import styles from "./styles";

const CustomButton = ({
  loading,
  title,
  theme,
  rippleDisabled,
  type,
  handleClick,
  className,
  disabled,
  children,
  ...props
}) => {
  const classes = styles();

  return (
    <Button
      type={type}
      onClick={handleClick}
      disableRipple={rippleDisabled}
      className={clsx(classes.button, className, classes[theme])}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <div style={{ paddingTop: "4%" }}>
          <CustomLoader size={28} />
        </div>
      ) : (
        title || children
      )}
    </Button>
  );
};

CustomButton.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  /**
   * Defines the button's standard + disabled colors
   */
  theme: PropTypes.oneOf([
    "primaryButton",
    "secondaryButton",
    "filledButton",
    "asLink",
    "disabledFilled",
    "disabledOutlined",
    "asLinkPrimary",
  ]),
  rippleDisabled: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit"]),
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CustomButton.defaultProps = {
  loading: false,
  theme: null,
  rippleDisabled: true,
  type: "button",
  handleClick: () => {},
  disabled: undefined,
};

export default CustomButton;
